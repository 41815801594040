import camepLogo from '../../assets/images/auth/c0.jpg'
import {
  Container,
  CssBaseline,
  Box,
   TextField,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import axios, {AxiosError} from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate()
  const [loginError, setLoginError] = useState(""); // State variable for login error

  const handleLogin = async () => {

  try {
      const user = {
          username: email,
          password: password
      };
      // Create the POST requuest
      const {data} = await axios.post(
          BASE_URL + '/token/',
          // 'http://localhost:8000/token/',
          user,
          {
              headers: {
                  'Content-Type': 'application/json'
              },
              withCredentials: true
          }
      );

      // Initialize the access & refresh token in localstorage.
      localStorage.clear();
      localStorage.setItem('access_token', data.access);
      localStorage.setItem('refresh_token', data.refresh);
      axios.defaults.headers.common['Authorization'] =
          `Bearer ${data['access']}`;
      // window.location.href = '/'


      navigate('/home');

  }  catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
          setLoginError("Il nome utente o la password non sono corrette."); // Set login error message
        } else {
          setLoginError("Il nome utente o la password non sono corrette.");
        }
      } else {
        setLoginError("An unknown error occurred.");
      }
    }
   };

  return (
    <>
      <Container maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            mt: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >


                            <img src={camepLogo} alt='cameplogo'/>


          {/*<Avatar sx={{ m: 1, bgcolor: "primary.light" }}>*/}
          {/*  <LockOutlined />*/}
          {/*</Avatar>*/}
          {/*<Typography variant="h5">Login</Typography>*/}
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Utente"
              name="email"
              autoFocus
              error={loginError !== ""}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={password}
              error={loginError !== ""}
              helperText={loginError}
              onChange={(e) => {
                setPassword(e.target.value);
                setLoginError("");
              }}
            />

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLogin}
            >
              Login
            </Button>
            {/*<Grid container justifyContent={"flex-end"}>*/}
            {/*  <Grid item>*/}
            {/*    <Link to="/register">Don't have an account? Register</Link>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Login;