import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

export function useLogoutToken() {
  return useMutation({
    mutationFn: async () => {
      const { data } = await axios.post(
         BASE_URL+'/token/',
        //'http://localhost:8000/token/',
        { refresh_token: localStorage.getItem('refresh_token') },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      );
      // Return the data or handle it as needed
      return data;
    },
  });
}

export const Logout = () => {
  const navigate = useNavigate();
  const { mutateAsync: logoutToken, isPending: isLogoutToken } = useLogoutToken();

  useEffect(() => {
    const performLogout = async () => {
      // Call logoutToken mutation
     // await logoutToken();
      // Clear local storage
      localStorage.clear();
      // Clear authorization header
      axios.defaults.headers.common['Authorization'] = null;
      // Navigate to home page
      navigate('/login');
    };

    // Call the function to perform logout
    performLogout();
  }, [logoutToken, navigate]);

  return (
    <div></div>
  );
};
