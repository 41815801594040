import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Sidebar from '../../components/Sidebar';
import Soci from '../soci/Soci';
import Login from "../auth/Login";

const Home = () => {
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (localStorage.getItem('access_token') === null) {
      navigate('/login');
    } else {
      navigate('/app');
      //setIsAuth(true);
    }
  }, []);

  return (
     <Box sx={{}}>
       {/*{isAuth ? <Sidebar open={open} /> : <Soci />}*/}
       {/*{isAuth ? <Sidebar open={open} /> : <Login />}*/}
     </Box>
  );
};

export default Home;
