import RicevuteAdd from "./RicevuteAdd";
import RicevuteEdit from "./RicevuteEdit";
import RicevuteJasper from "./RicevuteJasper";
import RicevuteExp from "./RicevuteExp";
import { useMemo, useState } from 'react';
import {
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  MRT_EditActionButtons,
  MaterialReactTable,
  MRT_EditCellTextField,
  createRow,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  useMaterialReactTable, MRT_SelectCheckbox,
} from 'material-react-table';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Dialog,
    DialogTitle, Grid,
    IconButton, TextField,
    Tooltip, Typography,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import AxiosInstance from '../../components/Axios';
import {useLocation, useNavigate} from "react-router-dom";
import {RicevuteApiResponse, Ricevuta} from "./types";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';



// interface RicevuteProps {
//    propcamep: string
// }

// const Ricevute: React.FC<RicevuteProps> = ({ propcamep }) => {

const Ricevute = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const  sateParams = location.state;
  let parcamep: string;
  let partiposocio: string;

  // console.log('LOCATION',location);

  if (location.state !== undefined && location.state !== null) {
   const  stateParams = location.state;
   parcamep = stateParams.statecamep;
   partiposocio = stateParams.statetiposocio;
  } else {
    parcamep = '0';
    partiposocio = 'TESS';
  }



  const [validationErrors, setValidationErrors] = useState< Record<string, string | undefined>>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([],);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({pageIndex: 0, pageSize: 15, });


  const columns = useMemo<MRT_ColumnDef<Ricevuta>[]>(
    () => [

        //
        // {
        // accessorKey: 'id',
        // header: 'Id',
        // size: 20,
        // enableEditing: false,
        // },

         {
        accessorKey: 'progressivo',
        header: 'Prog',
        enableEditing: true,
        size: 20,
       },
       {
        accessorKey: 'cognome',
        header: 'Cognome',
        enableEditing: true,
        size: 30,
       },
        {
        accessorKey: 'nome',
        header: 'Nome',
        enableEditing: true,
        size: 30,
       },
      {
        accessorKey: 'camep',
        header: 'Camep',
        size: 50,
        enableEditing: true,
      },
      {
        accessorKey: 'anno',
        header: 'Anno',
        size: 50,
        enableEditing: true,
      },
     {
        accessorKey: 'dataricevuta',
        header: 'DataRicevuta',
        enableEditing: true,
        size: 30,
       // filterVariant: 'date',
        Cell: ({ cell }) => {
            const value = cell.getValue();
            if (typeof value === 'string' || typeof value === 'number') {
                const date = new Date(value);
                return date.toLocaleDateString('it-IT'); // Change 'it-IT' to your desired locale
            } else {
                // Handle unexpected value types
                return "Nessuna Data";
            }
            }
     },


     {
        accessorKey: 'tipopagamento',
        header: 'TipoPag',
        enableEditing: true,
        size: 50,
     },
     {
        accessorKey: 'iscrizioneasi',
        header: 'IscrizAsi',
        enableEditing: true,
        size: 20,
    },
      {
        accessorKey: 'iscrizioneclub',
        header: 'IscrizClub',
        enableEditing: true,
        size: 20,
    },
    {
        accessorKey: 'contributoauto',
        header: 'ContAuto',
        enableEditing: true,
        size: 20,
    },
    {
        accessorKey: 'contributomoto',
        header: 'ContMoto',
        enableEditing: true,
        size: 20,
    },
    {
        accessorKey: 'contributoomol',
        header: 'ContOmol',
        enableEditing: true,
        size: 20,
    },
    {
        accessorKey: 'contributodup',
        header: 'ContDup',
        enableEditing: true,
        size: 20,
    },
    {
        accessorKey: 'spesesegreteria',
        header: 'SpeseSegreteria',
        enableEditing: true,
        size: 20,
    },

      {
         accessorKey: 'raccomandata',
         header: 'SpesePostali',
         enableEditing: true,
         size: 20,
     },
    //  {
    //     accessorKey: 'tipocontributo',
    //     header: 'TipoContributo',
    //     enableEditing: true,
    //     size: 20,
    // },

      {
        accessorKey: 'nuovo',
        header: 'Nuovo',
        enableEditing: true,
        size: 20,
        Cell: ({cell}) => (
                cell.getValue<boolean>() && (
                    <Box>
                        <CheckBoxOutlinedIcon />
                    </Box>
                )
            ),
        },
      {
        accessorKey: 'rinnovo',
        header: 'Rinnovo',
        enableEditing: true,
        size: 10,
         Cell: ({cell}) => (
                cell.getValue<boolean>() && (
                    <Box>
                        <CheckBoxOutlinedIcon />
                    </Box>
                )
            ),
     },
      {
        accessorKey: 'onorario',
        header: 'Onorario',
        enableEditing: true,
        size: 10,
         Cell: ({cell}) => (
                cell.getValue<boolean>() && (
                    <Box>
                        <CheckBoxOutlinedIcon />
                    </Box>
                )
            ),
    },
     {
        accessorKey: 'datainvioemail',
        header: 'DataInvio',
        enableEditing: true,
        size: 50,
    },


    ],
    [validationErrors],
  );

  //call CREATE hook
  const { mutateAsync: createRicevuta, isPending: isCreatingRicevuta } = useCreateRicevuta();
  //call READ hook
  const {  data: { results = [], count } = {} ,isError: isLoadingRicevuteError, isFetching: isFetchingRicevute, isLoading: isLoadingRicevute, } = useGetRicevute(pagination,columnFilters,globalFilter,sorting);
  //call UPDATE hook
  const { mutateAsync: updateRicevuta, isPending: isUpdatingRicevuta } = useUpdateRicevuta();
  //call DELETE hook
  const { mutateAsync: deleteRicevuta, isPending: isDeletingRicevuta } = useDeleteRicevuta();
  //new call Edit hook
  const [isDialogAddOpen, setIsDialogAddOpen] = useState(false);
  const [isDialogEditOpen, setIsDialogEditOpen] = useState(false);
  const [currentRowId, setCurrentRowId] = useState('0');

  const [isDialogRicevuteExpOpen, setIsDialogRicevuteExpOpen] = useState(false);

  const [tiposocio, setTiposocio] = useState('TESS');
  const [isDialogPrintOpen, setIsDialogPrintOpen] = useState(false);




  //CREATE action
  const handleCreateRicevuta: MRT_TableOptions<Ricevuta>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateRicevuta(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createRicevuta(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveRicevuta: MRT_TableOptions<Ricevuta>['onEditingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateRicevuta(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateRicevuta(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row: MRT_Row<Ricevuta>) => {
    if (window.confirm('ATTENZIONE !!! CANCELLAZIONE DELLA RICEVUTA " '+row.original.camep+' '+row.original.camep+' "')) {
      deleteRicevuta(row.original.id ? row.original.id : 0 );
    }

  };

  //NEW PRINT action
   const openPrintModal = (row: MRT_Row<Ricevuta>) => {
       setIsDialogPrintOpen(true);
       setCurrentRowId(row.id);
   };

  const openRicevuteExpModal = () => {
        setIsDialogRicevuteExpOpen(true);
  };



  //NEW MOD action
   const openEditModal = (row: MRT_Row<Ricevuta>) => {
       setIsDialogEditOpen(true);
       setCurrentRowId(row.id);
   };




  //NEW ADD action hook and dialog
  const openAddModalTess = () => {
        setIsDialogAddOpen(true);
        setTiposocio('TESS');
  };

  const openAddModalComm = () => {
        setIsDialogAddOpen(true);
        setTiposocio('COMM');
  };







  const table = useMaterialReactTable({
    columns,
    data: results,
    initialState: { density: 'compact' },

    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
    manualSorting: true, //turn off built-in client-side sorting

    //columnFilterDisplayMode: 'popover',

    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,

    //getRowId: (row) => row.id.toString(),

    getRowId: (row) => (row.id ? row.id.toString() : ''),



    muiToolbarAlertBannerProps: isLoadingRicevuteError ? {
          color: 'error',
          children: 'Error loading data',
        } : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    // muiEditRowDialogProps: {
    //    fullWidth: true,
    //    maxWidth: 'md',
    //  },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    //onSortingChange: setSorting,
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateRicevuta,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveRicevuta,

     renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
         <>
        <DialogTitle variant="h3">Crea Nuova Ricevuta</DialogTitle>
        <DialogContent
           sx={{ width: '1000px'}}
        >
          {internalEditComponents} {/* or render custom edit components here */}

        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>

       </>
    ),


   renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Edit Ricevuta</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),


    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>

      {/*   <Tooltip title="Edit">*/}
      {/*     <IconButton onClick={() => table.setEditingRow(row)}>*/}
      {/*       <EditIcon />*/}
      {/*     </IconButton>*/}
      {/*   </Tooltip>*/}



        <Tooltip title="Print">
         {/*<IconButton onClick={() => navigate(`/app/ricevutejasper`)}>*/}
        <IconButton onClick={() => {openPrintModal(row)}}>
             <PrintIcon />
         </IconButton>
        </Tooltip>
      {isDialogPrintOpen && currentRowId === row.id && <RicevuteJasper id={(Number(currentRowId))} onClose={()  => setIsDialogPrintOpen(false)} />}




       <Tooltip title="Modifica">
         <IconButton onClick={() => {openEditModal(row)}}>
             <EditIcon />
         </IconButton>
        </Tooltip>

        {isDialogEditOpen && currentRowId === row.id && <RicevuteEdit id={(Number(currentRowId))} onClose={()  => setIsDialogEditOpen(false)} />}

    <Tooltip title="Rimuovi">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),





    renderTopToolbarCustomActions: ({ table }) => (
      <>

      {/*  <Button*/}
      {/*      variant="contained"*/}
      {/*      onClick={() => {*/}
      {/*        table.setCreatingRow(true); //simplest way to open the create row modal with no default values*/}
      {/*        //or you can pass in a row object to set default values with the `createRow` helper function*/}
      {/*        // table.setCreatingRow(*/}
      {/*        //   createRow(table, {*/}
      {/*        //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios*/}
      {/*        //   }),*/}
      {/*        // );*/}
      {/*      }}*/}
      {/*   >*/}
      {/*  Create New Ricevuta*/}
      {/*</Button>*/}


      <Button variant="contained" onClick={openAddModalTess}>NUOVA TESSERATI</Button>

      <Button variant="contained" onClick={openAddModalComm}>NUOVA COMMERCIANTI</Button>


       {isDialogAddOpen && <RicevuteAdd onClose={()  => {setIsDialogAddOpen(false),navigate(location.state, {});}} proptiposocio={tiposocio} propcamep={parcamep || ''} />}

       {parcamep !== '0' && <RicevuteAdd onClose={()  => {setIsDialogAddOpen(false); navigate(location.state, {});}} proptiposocio={partiposocio || ''} propcamep={parcamep || ''} />}

       {/*{isDialogAddOpen && <RicevuteAdd onClose={()  => {setIsDialogAddOpen(false),navigate("/app/ricevute", {});}} proptiposocio={tiposocio} propcamep={parcamep || ''} />}*/}

       {/*{parcamep !== '0' && <RicevuteAdd onClose={()  => {setIsDialogAddOpen(false); navigate("/app/ricevute", {});}} proptiposocio={partiposocio || ''} propcamep={parcamep || ''} />}*/}




        {/*<Button*/}
        {/*  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)*/}
        {/*  onClick={handleExportCSVData}*/}
        {/*  startIcon={<FileDownloadIcon />}*/}
        {/*>*/}
        {/*  EXPORT DATI CSV*/}
        {/*</Button>*/}

        {/*<Button onClick={handleExportExcel} startIcon={<FileDownloadIcon />}>*/}
        {/* EXPORT DATI EXCEL*/}
        {/*</Button>*/}

        {/*<Button onClick={handleExportPDF} startIcon={<FileDownloadIcon />}>*/}
        {/*    EXPORT DATI PDF*/}
        {/*</Button>*/}

       <Button onClick={openRicevuteExpModal} startIcon={<FileDownloadIcon />}>EXPORT DATI </Button>

       {isDialogRicevuteExpOpen && <RicevuteExp columnFilters={columnFilters} globalFilter={globalFilter} sorting={sorting} onClose={()  => setIsDialogRicevuteExpOpen(false)} />}





      </>
     ),

    //rowCount: meta?.totalRowCount ?? 0,
    rowCount: count ?? 0,

    state: {
      isLoading: isLoadingRicevute,
      isSaving: isCreatingRicevuta || isUpdatingRicevuta || isDeletingRicevuta,
      showAlertBanner: isLoadingRicevuteError,
      showProgressBars: isFetchingRicevute,

      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },
  });

  return    <Box sx={{mt: '60px' // , width: '1376px'
        }}><MaterialReactTable table={table} /> </Box>;
};


//CREATE hook (post new ricevuta to api)
function useCreateRicevuta() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (ricevuta: Ricevuta) => {
      //send api update request here
      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
      return AxiosInstance.post('ricevute/0/',ricevuta);
    },
    //client side optimistic update
    //  onMutate: (newRicevutaInfo: Ricevuta) => {
    //    queryClient.setQueryData(
    //      ['ricevute'],
    //      (prevRicevute: any) =>
    //        [
    //          ...prevRicevute,
    //          {
    //            ...newRicevutaInfo,
    //            id: (Math.random() + 1).toString(36).substring(7),
    //          },
    //        ] as Ricevuta[],
    //    );
    //   },
    //  onSettled: () => queryClient.invalidateQueries({ queryKey: ['ricevute'] }), //refetch ricevute after mutation, disabled for demo
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['fetchRicevute'] }), //refetch ricevute after mutation, disabled for demo
     });
}

//READ hook (get ricevute from api)
function useGetRicevute(pagination: MRT_PaginationState,columnFilters:  MRT_ColumnFiltersState,globalFilter: string,sorting: MRT_SortingState) {
  return useQuery<RicevuteApiResponse>({
    queryKey: ['fetchRicevute',
       columnFilters, //refetch when columnFilters changes
       globalFilter, //refetch when globalFilter changes
       pagination.pageIndex, //refetch when pagination.pageIndex changes
       pagination.pageSize, //refetch when pagination.pageSize changes
       sorting, //refetch when sorting changes
    ],
    queryFn: async () => {

      const myOffset = (pagination.pageIndex==0) ? "0": `${pagination.pageSize*(pagination.pageIndex)}`;

      //const fetchURL = new URL("ricevute/","/");
      //fetchURL.searchParams.set('limit',`${pagination.pageSize}`,);
      //fetchURL.searchParams.set('offset', myOffset);
      //fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      // fetchURL.searchParams.set('',columnFiltersParam(columnFilters));
      //fetchURL.searchParams.set('search', globalFilter ?? '');
      //fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));
      const myURL = '?limit='+`${pagination.pageSize}`+'&offset='+myOffset+'&search='+(globalFilter ?? '')+columnFiltersParam(columnFilters)+'&ordering='+sortingParam(sorting);
      //console.log(myURL);
      const response = await AxiosInstance.get('ricevutedto/'+myURL);
      const json = (response.data) as RicevuteApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}



//mp conversione  filter parameters per django rest api
function columnFiltersParam(columnFilters: MRT_ColumnFiltersState) {
  var loopData = ''
  var i;
  for (i = 0; i < columnFilters.length; i++) {
    loopData += "&"
    loopData += `${columnFilters[i].id}`+"="+`${columnFilters[i].value}`
    //if (i < columnFilters.length-1)
    //  loopData += "&"
  }
  //console.log(loopData);
  //const columnFiltersParam = `${encodeURIComponent(loopData)}`;
  return loopData
}


function sortingParam(sorting: MRT_SortingState) {
  let ordering = '';
  for (let i = 0; i < sorting.length; i++) {
    const { id, desc } = sorting[i];
    ordering += desc ? `${id},` : `-${id},`;
  }
  // Remove the trailing comma
  ordering = ordering.slice(0, -1);
  return ordering;
}



//UPDATE hook (put ricevuta in api)
function useUpdateRicevuta() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (ricevuta: Ricevuta) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newRicevutaInfo: Ricevuta) => {
      queryClient.setQueryData(
        ['ricevute'],
        (prevRicevute: any) =>
          prevRicevute?.map((prevRicevuta: Ricevuta) =>
            prevRicevuta.id === newRicevutaInfo.id ? newRicevutaInfo : prevRicevuta,
          ),
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['ricevute'] }), //refetch ricevute after mutation, disabled for demo
  });
}

//DELETE hook (delete ricevuta in api)
function useDeleteRicevuta() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      //send api update resquest here
      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      // return Promise.resolve();
      const response = await AxiosInstance.delete('ricevute/'+id+'/');
     return  response
    },
    //client side optimistic update
    // onMutate: (id: string) => {
    //   queryClient.setQueryData(
    //     ['ricevute'],
    //     (prevRicevute: any) =>
    //       prevRicevute?.filter((ricevuta: Ricevuta) => ricevuta.id !== id),
    // );
    //},
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['fetchRicevute'] }), //refetch ricevute after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

// const RicevuteWithProviders = () => (
//   //Put this with your other react-query providers near root of your app
//   <QueryClientProvider client={queryClient}>
//     <Ricevute />
//   </QueryClientProvider>
// );

export default Ricevute;

const validateRequired = (value: string) => !!value.length;
const validateRequiredNum = (value: number) => !!value.toString().length;
const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

function validateRicevuta(ricevuta: Ricevuta) {
  return {
  //  camep: !validateRequiredNum(ricevuta.camep)  ? 'Camep is Required' : '',
  //   tipo: !validateRequired(ricevuta.tipo) ? 'Tipo is Required' : '',

  };
}
