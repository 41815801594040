import React, { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
import {Dialog, DialogContent, Button, Grid, TextField, Typography, CircularProgress} from "@mui/material";
import AxiosInstance from "../../components/Axios";
import AxiosInstanceUpload from "../../components/AxiosUpload";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { EmailForm } from "../ricevute/types";
import Alert from "@mui/material/Alert";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {SocioForm} from "../soci/types";
import {ReportDateForm} from "./types";
import * as Yup from "yup";
import {annoValidator, valutaValidator} from "../../components/YupValidators";
import {useCreateRicevuta, useGetTipoPag} from "../ricevute/Services";
import {
    jasperNuoviRinnoviSociBlob,
    jasperNuoviSociBlob,
    useSendEmail,
    useUploadReport,
    validationSchema
} from "./Services";



interface ReportNuoviSociExcelProps {
  onClose: () => void;
  formato: String;
}

const ReportNuoviSoci: React.FC<ReportNuoviSociExcelProps> = (props) => {

 const today = format(new Date(), 'yyyy-MM-dd');



  const [blob, setBlob] = useState<Blob | null>(null);

  const [datesConfirmed, setDatesConfirmed] = useState(false);
  const [emailTo, setEmailTo] = useState('');


   const { register,control,setValue,getValues, handleSubmit,reset, formState: {  isValid, errors }} = useForm({ resolver: yupResolver(validationSchema)})

   const { mutateAsync: uploadReport, isPending: isUploadingRicevuta } = useUploadReport();

   const { mutateAsync: sendEmail, isPending: isSendingEmail, isSuccess: isSuccessSendEmail } = useSendEmail();




          const handleDownload = () => {
              if (blob) {
                  let myFileName = "ReportNuoviSoci."+props.formato;
                  myFileName = myFileName.replace(/\//g, "-");

                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = myFileName;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
              }
          };

          const handleEmail = async () => {
              if (blob) {
                  const formData = new FormData();
                  let myFileName = "ReportNuoviSoci."+props.formato;
                  myFileName = myFileName.replace(/\//g, "-");
                  formData.append('file', blob, myFileName);

                  await uploadReport(formData);

                  const emailForm: EmailForm = {
                      email: (emailTo || ''),
                      subject: 'Report Nuovi Soci',
                      attachment: myFileName,
                      tipo: 'report',
                  };

                  await sendEmail(emailForm);
              }
          };
          const handleClose = () => {
              props.onClose();
          };



          const onSubmit: SubmitHandler<ReportDateForm> = async (data) => {
              if (data.fromDate && data.toDate) {
                  setDatesConfirmed(true);

            const myBlob = await jasperNuoviSociBlob(data.fromDate, data.toDate, props.formato);

            if (myBlob) {

                    setBlob(myBlob);
                }


              } else {
                  setDatesConfirmed(false);
              }
          };


          return (
              <Dialog onClose={props.onClose} open fullWidth={true} maxWidth={"md"}>
                  <DialogContent>
                      {!datesConfirmed && (
                          <form onSubmit={handleSubmit(onSubmit)}>
                              <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                      <TextField
                                          id="fromDate"
                                          label="DataInizio"
                                          type="date"
                                          margin="dense"
                                          fullWidth
                                          defaultValue={today}
                                          {...register('fromDate')}
                                          error={!!errors.fromDate}
                                      />
                                      <Typography variant="caption" color="error">
                                          {errors.fromDate?.message}
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <TextField
                                          id="toDate"
                                          label="Data Fine"
                                          type="date"
                                          margin="dense"
                                          fullWidth
                                          defaultValue={today}
                                          {...register('toDate')}
                                          error={!!errors.toDate}
                                      />
                                      <Typography variant="caption" color="error">
                                          {errors.toDate?.message}
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <TextField
                                          id="emailTo"
                                          label="Email per invio Report"
                                          type="email"

                                          // onChange={(e) => setEmailTo(e.target.value)}
                                          fullWidth
                                          {...register('emailTo')}
                                         onChange={(e) => setEmailTo(e.target.value)}
                                      />
                                  </Grid>
                                  <Grid item xs={12} container alignItems="flex-end" justifyContent="flex-end"
                                        spacing={1}>
                                      <Grid item>
                                          <Button variant="contained" onClick={handleClose}
                                                  color="error">CHIUDI</Button>
                                      </Grid>
                                      <Grid item> </Grid>
                                      <Grid item>
                                          {/*<Button disabled={!fromDate || !toDate} color="primary"   type="submit"  variant="contained">*/}
                                          <Button disabled={!isValid} color="primary" type="submit" variant="contained">

                                              CONFERMA DATE
                                          </Button>
                                      </Grid>
                                  </Grid>
                              </Grid>
                          </form>
                      )}

                      <Grid container spacing={2}>
                          <Grid item>
                              {datesConfirmed && (
                                  <>
                                      <Button variant="contained" onClick={handleClose} color="error">CHIUDI</Button>
                                      <Button variant="contained" onClick={handleDownload} color="primary"
                                              style={{marginLeft: '10px'}}>Download {props.formato}</Button>
                                      <Button disabled={!emailTo} variant="contained" onClick={handleEmail}
                                              color="primary" style={{marginLeft: '10px'}}>Invia Email</Button>
                                      {isSuccessSendEmail &&
                                          <Alert variant="filled" severity="success">Invio email avvenuta correttamente
                                              a {emailTo} !!</Alert>}

                                  </>
                              )}
                          </Grid>
                      </Grid>

               <div>
                      {props.formato == 'pdf' && datesConfirmed && blob && (
                        <iframe src={window.URL.createObjectURL(blob)} width="100%" height="800"></iframe>
                      )}
                    </div>
                  </DialogContent>
              </Dialog>
          );

  };
export default ReportNuoviSoci;
