import React, { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
import { Dialog, DialogContent, Button, Grid } from "@mui/material";
import AxiosInstance from "../../components/Axios";
import AxiosInstanceUpload from "../../components/AxiosUpload";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {EmailForm, Ricevuta} from "./types";
import {useGetRicevuta, useUpdateRicevuta} from "./Services";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import {format} from "date-fns";
import {useGetSocioByRicevuta} from "../soci/Services";



function useUploadRicevuta() {
  return useMutation({
    mutationFn: async (formData: FormData) => {
      return AxiosInstanceUpload.post('uploadricevuta/',formData);
    },
    // onSuccess: () => queryClient.invalidateQueries({ queryKey: ['fetchRicevute'] }),


     });
}


function useSendEmail() {
  return useMutation({
    mutationFn: async (emailForm: EmailForm) => {
      return AxiosInstance.post('sendemail/',emailForm);
    },
    // onSuccess: () => queryClient.invalidateQueries({ queryKey: ['fetchRicevute'] }),

     });
}




interface RicevuteJasperProps {
  onClose: () => void;
  id: number;
}




const RicevuteJasper: React.FC<RicevuteJasperProps> = (props) => {


  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  const [fileName, setFileName] = useState<string>('ricevuta.pdf');

  const [datainvioemail, setDatainvioemail] = useState(Date);

  const { mutateAsync: uploadRicevuta, isPending: isUploadingRicevuta } = useUploadRicevuta();

  const { mutateAsync: sendEmail, isPending: isSendingEmail, isSuccess: isSuccessSendEmail } = useSendEmail();

  const {  data:  ricevuta,  isError: isLoadingRicevutaError, isFetching: isFetchingRicevuta, isLoading: isLoadingRicevuta, } = useGetRicevuta(props.id);

  const { mutateAsync: updateRicevuta, isPending: isUpdatingRicevuta } = useUpdateRicevuta();

  const {  data:  socio,  isError: isLoadingSocioError, isFetching: isFetchingSocio, isLoading: isLoadingSocio, } = useGetSocioByRicevuta(props.id);


  const today = format(new Date(), 'yyyy-MM-dd HH:mm:ss');


  useEffect(() => {
    const fetchData = async () => {
      try {
        const username = 'jasperadmin';
        const password = 'Tibco2024@';
        const base64encodedData = Buffer.from(`${username}:${password}`).toString('base64');

        const response = await fetch(BASE_URL_JASPERSERVER+'/rest_v2/reports/reports/ric.pdf?ricid=' + props.id, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
            'Authorization': `Basic ${base64encodedData}`,
          },
        });

        const blob = await response.blob();
        setPdfBlob(blob);
      } catch (error) {
        console.error('Error generating report:', error);
      }
    };

    fetchData();
  }, [props.id]);

  const handleClose = () => {
    props.onClose();
  };

  const handleDownload = () => {
    if (pdfBlob) {

      const myProgRic = (ricevuta ? ricevuta.progressivo : "")
      let myFileName = "RicevutaCamep"+myProgRic+".pdf";
      myFileName = myFileName.replace(/\//g, "-");

      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = myFileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
 const handleEmail = async () => {
    if (pdfBlob && socio) {


       const formData = new FormData();

       const myProgRic = (ricevuta ? ricevuta.progressivo : "")

       let myFileName = "RicevutaCamep"+myProgRic+".pdf";
       myFileName = myFileName.replace(/\//g, "-");

       //await setFileName(myFileName);

       formData.append('file', pdfBlob, myFileName);

       await uploadRicevuta(formData);

       //  console.log(socio)

       const emailForm: EmailForm = {
           //email: 'michael.pinchi@bluesystem.it',
           email:  (socio?.mail || ''),
           subject: 'Ricevuta Camep numero ' + myProgRic,
           // message : '',  //gestito da backend
           // message: '<h7>Gentile Socio,' +
           //     '<br>' +
           //     'in allegato rimettiamo copia della ricevuta.' +
           //     '<br>' +
           //     '<br>' +
           //     '<br>' +
           //     'Cordiali saluti' +
           //     '<br>' +
           //     'La Segreteria del C.a.m.e.p.\n</h7>'+
           //     '<img src="/opt/to/footer/logoemailsmall.png" alt="Footer Image" style="display: block; margin: 0 auto; max-width: 100%;">',
           //
           attachment: myFileName,
           tipo: 'ricevuta',
       };


         await sendEmail(emailForm);

         //
         // const updatedRicevuta = {
         //                  ...ricevuta,
         //        datainvioemail: new Date(today),
         //    };
         //
         //
         //
         // await updateRicevuta(updatedRicevuta)



    }
  };

const handleUpload = async () => {
  if (pdfBlob) {
    const formData = new FormData();
    formData.append('file', pdfBlob, fileName);

    await uploadRicevuta(formData);

        // try {
        //
        //   const response = await fetch('http://localhost:8000/api/uploadricevuta/', {
        //     method: 'POST',
        //     body: formData,
        //   });
        //
        //   if (response.ok) {
        //     console.log('File uploaded successfully.');
        //     // Optionally, do something after successful upload
        //   } else {
        //     console.error('Upload failed:', response.statusText);
        //   }
        // } catch (error) {
        //   console.error('Error uploading file:', error);
        // }
  }
};

  return (
    <Dialog onClose={props.onClose} open fullWidth={true} maxWidth={"md"}>
      <DialogContent>
        <Grid item sm={2}>
          <Button variant="contained" onClick={handleClose} color="error">CHIUDI</Button>
          <Button variant="contained" onClick={handleDownload} color="primary" style={{ marginLeft: '10px' }}>Download PDF</Button>
          <Button variant="contained" onClick={handleEmail} color="primary" style={{ marginLeft: '10px' }}>Invia Email PDF</Button>
          {isSuccessSendEmail &&  <Alert variant="filled"  severity="success">Invio email avvenuta correttamente a {(socio?.mail||'')} !!</Alert>}
          {/*<Button variant="contained" onClick={handleUpload} color="primary" style={{ marginLeft: '10px' }}>Upload PDF</Button>*/}

        </Grid>
        <div>
          {pdfBlob && (
            <iframe src={window.URL.createObjectURL(pdfBlob)} width="100%" height="800"></iframe>
          )}
        </div>
        {/* Render ErrorAlert here if necessary */}

      </DialogContent>
    </Dialog>
  );
};

export default RicevuteJasper;
