import axios from 'axios';

const AxiosInstance = axios.create({
    baseURL: BASE_URL_API,
    timeout: 10000,
    headers: {
        "Content-Type": "application/json",
        accept: "application/json"
    }
});

let refresh = false; // Move the declaration here

// Add a request interceptor to include JWT token in the headers
AxiosInstance.interceptors.request.use(
    config => {
        // Retrieve JWT token from local storage
        const token = localStorage.getItem('access_token');

        // If token exists, add it to the request headers
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Add a response interceptor for token refreshing
AxiosInstance.interceptors.response.use(
    resp => resp,
    async error => {
        if (error.response && error.response.status === 401) {
            try {
                // Perform token refresh
                if (!refresh) {
                    refresh = true;

                    const response = await axios.post(BASE_URL + '/token/refresh/', {
                        refresh: localStorage.getItem('refresh_token')
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true
                    });

                    if (response.status === 200) {
                        // Update access token and refresh token in local storage
                        localStorage.setItem('access_token', response.data.access);
                        localStorage.setItem('refresh_token', response.data.refresh);

                        // Update authorization header for subsequent requests
                        AxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;

                        // Retry the original request
                        return AxiosInstance(error.config);
                    }
                }
            } catch (refreshError) {
                console.error("Error refreshing token:", refreshError);
            } finally {
                refresh = false;
            }
        }

        // If token refresh fails or the error is not related to token expiration, return the error
        return Promise.reject(error);
    }
);

export default AxiosInstance;
