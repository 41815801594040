import SociAdd from "./SociAdd";
import SociRic from "./SociRic";
import SociEdit from "./SociEdit";
import { useMemo, useState } from 'react';
import {
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  MRT_EditActionButtons,
  MaterialReactTable,
  MRT_EditCellTextField,
  createRow,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  useMaterialReactTable, MRT_SelectCheckbox,
} from 'material-react-table';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Dialog,
    DialogTitle, Grid,
    IconButton, TextField,
    Tooltip, Typography,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReceiptIcon from  '@mui/icons-material/Receipt';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AxiosInstance from '../../components/Axios';
import {useNavigate} from "react-router-dom";
import {SociDTOApiResponse, Socio, SocioDTO} from "./types";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SociExp from "./SociExp";


const Soci = () => {
  const [validationErrors, setValidationErrors] = useState< Record<string, string | undefined>>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([],);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({pageIndex: 0, pageSize: 15, });



  const navigate = useNavigate();

  const columns = useMemo<MRT_ColumnDef<SocioDTO>[]>(
    () => [
        {
                accessorKey: 'anno',
                header: 'Anno',
                size: 10,
                enableEditing: true,
        },
        {
            accessorKey: 'whatsapp',
            header: 'WApp',
            enableEditing: true,
            filterVariant: 'checkbox',
            size: 5,
            Cell: ({cell}) => (
                cell.getValue<boolean>() && (
                    <Box>
                        <WhatsAppIcon style={{color: 'green'}}/>
                    </  Box>
                )
            ),
        },
    //   {
    //     accessorKey: 'pretel',
    //     header: 'Pref.',
    //     enableEditing: true,
    //     size: 10,
    // },
     {
        accessorKey: 'cell',
        header: 'Cellulare',
        enableEditing: true,
        size: 10,
    },

      {
        accessorKey: 'camep',
        header: 'Camep',
        size: 10,
        enableEditing: true,
      },
      {
        accessorKey: 'asi',
        header: 'Asi',
        size: 10,
        enableEditing: true,
      },

      {
        accessorKey: 'cognome',
        header: 'Cognome',
        enableEditing: true,
        size: 50,
    },
      {
        accessorKey: 'nome',
        header: 'Nome',
        enableEditing: true,
        size: 50,
    },
    {
        accessorKey: 'mail',
        header: 'Email',
        enableEditing: true,
        size: 50,
    },
    {
        accessorKey: 'gruppo',
        header: 'Gruppo',
        enableEditing: true,
        size: 50,
     },

     {
        accessorKey: 'datanascita',
        header: 'DataNascita',
        enableEditing: true,
        size: 20,
        //filterVariant: 'date-range',
         Cell: ({ cell }) => {
            const value = cell.getValue();
            if (typeof value === 'string' || typeof value === 'number') {
                const date = new Date(value);
                return date.toLocaleDateString('it-IT'); // Change 'it-IT' to your desired locale
            } else {
                // Handle unexpected value types
                return "Nessuna Data";
            }
            }
    },
    {
        accessorKey: 'sesso',
        header: 'Sesso',
        enableEditing: true,
        size: 50,
    },

     {
        accessorKey: 'indirizzo',
        header: 'Indirizzo',
        enableEditing: true,
        size: 50,
    },
     {
        accessorKey: 'cap',
        header: 'Cap',
        enableEditing: true,
        size: 50,
    },
      {
        accessorKey: 'comune',
        header: 'Comune',
        enableEditing: true,
        size: 50,
    },
    {
        accessorKey: 'provincia',
        header: 'Provincia',
        enableEditing: true,
        size: 30,
    },
    {
        accessorKey: 'tel',
        header: 'Telefono',
        enableEditing: true,
        size: 30,
    },

    {
        accessorKey: 'cf',
        header: 'CodFisc',
        enableEditing: true,
        size: 20,
    },

    {
        accessorKey: 'comunenascita',
        header: 'ComuneNascita',
        enableEditing: true,
        size: 20,
    },
    {
        accessorKey: 'provincianascita',
        header: 'ProvinciaNascita',
        enableEditing: true,
        size: 20,
    },
    {
        accessorKey: 'statonascita',
        header: 'StatoNascita',
        enableEditing: true,
        size: 20,
    },

     {
            accessorKey: 'id',
            header: 'Id',
            size: 20,
            enableEditing: false,
      },
      {
            accessorKey: 'tiposocio',
            header: 'TipoSocio',
            size: 20,
            enableEditing: false,
      },


    ],
    [validationErrors],
  );

  //call CREATE hook
  const { mutateAsync: createSocio, isPending: isCreatingSocio } = useCreateSocio();
  //call READ hook
  const {  data: { results = [], count } = {} ,isError: isLoadingSociError, isFetching: isFetchingSoci, isLoading: isLoadingSoci, } = useGetSoci(pagination,columnFilters,globalFilter,sorting);
  //call UPDATE hook
  const { mutateAsync: updateSocio, isPending: isUpdatingSocio } = useUpdateSocio();
  //call DELETE hook
  const { mutateAsync: deleteSocio, isPending: isDeletingSocio } = useDeleteSocio();
  //new call Edit hook
  const [isDialogEditOpen, setIsDialogEditOpen] = useState(false);
  const [currentRowId, setCurrentRowId] = useState('0');
  //new call Add hook
  const [isDialogAddOpen, setIsDialogAddOpen] = useState(false);
  const [isDialogSociRicOpen, setIsDialogSociRicOpen] = useState(false);
  const [param, setParam] = useState('0');
  const [isDialogSociExpOpen, setIsDialogSociExpOpen] = useState(false);

  // HANDLE EXPORTS



// const handleExportPDF = () => {
//   const doc = new jsPDF('landscape');
//
//   // Define the table headers and their widths
//   const headers = columns.map((c) => c.header);
//   const columnWidths = columns.map((c) => c.size || 10); // Default width: 40
//
//   // Convert data to rows using generateExcelData function
//   const rows = generateExcelData();
//  //let xPos = 5;
//   // Set initial y position for the table
//   let yPos = 10; // Starting y position
//
//   // Draw table headers
//   doc.setFontSize(10);
//   doc.setTextColor(0);
//   let pos = 10;
//   //for (let i = 0; i < headers.length; i++) {
//   for (let i = 0; i < 13; i++) {
//     //doc.setFillColor(211, 211, 211); // Gray background for header
//     //doc.rect(10 + i * columnWidths[i], yPos, columnWidths[i], 10, "F");
//     //   doc.text(headers[i], 15 + i * columnWidths[i], yPos + 10, { align: "center" });
//     doc.text(headers[i], pos,yPos, { align: "center" });
//     pos = pos + 20
//   }
//    //https://artskydj.github.io/jsPDF/docs/jsPDF.html
//   // // Draw table rows
//
//   rows.forEach((row, rowIndex) => {
//     pos=10
//     yPos += 5; // Move to the next row
//     Object.values(row).forEach((cellValue, cellIndex) => {
//       const stringValue = cellValue !== null ? cellValue.toString() : ''; // Convert cell value to string
//       // doc.rect(10 + cellIndex * columnWidths[cellIndex], yPos, columnWidths[cellIndex], 15); // Border for each cell
//       // doc.text(stringValue, 15 + cellIndex * columnWidths[cellIndex], yPos + 10, { align: "center" });
//        doc.text(stringValue, pos, yPos , { align: "center" });
//        pos = pos + 20
//     });
//   });
//
//   // Save the PDF
//   doc.save("soci.pdf");
// };











  //CREATE action
  const handleCreateSocio: MRT_TableOptions<Socio>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateSocio(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createSocio(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveSocio: MRT_TableOptions<Socio>['onEditingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateSocio(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateSocio(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row: MRT_Row<Socio>) => {
    if (window.confirm('ATTENZIONE !!! CANCELLAZIONE DEL SOCIO " '+row.original.cognome+' '+row.original.cognome+' "')) {
      deleteSocio(row.original.id ? row.original.id : 0 );
    }
  };



  //NEW MOD action
   const openEditModal = (row: MRT_Row<Socio>) => {
       setIsDialogEditOpen(true);
       setCurrentRowId(row.id);
   };

   const openSociRicModal = (row: MRT_Row<Socio>) => {
       setIsDialogSociRicOpen(true);
       setCurrentRowId(row.id);
   };


  //NEW ADD action hook and dialog
  const openAddModal = () => {
        setIsDialogAddOpen(true);
  };

  const openSociExpModal = () => {
        setIsDialogSociExpOpen(true);
  };


 const openRicevutaModal = (row: MRT_Row<Socio>) => {
        setCurrentRowId(row.id);
        const stateParams = {statecamep: row.original.camep, statetiposocio: row.original.tiposocio};
        navigate("/app/ricevute", { state: stateParams });
  };



  const table = useMaterialReactTable({
    columns,
    data: results,
    initialState: { density: 'compact' },

    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
    manualSorting: true, //turn off built-in client-side sorting

    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,

    //getRowId: (row) => row.id.toString(),

    getRowId: (row) => (row.id ? row.id.toString() : ''),



    muiToolbarAlertBannerProps: isLoadingSociError ? {
          color: 'error',
          children: 'Error loading data',
        } : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    // muiEditRowDialogProps: {
    //    fullWidth: true,
    //    maxWidth: 'md',
    //  },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateSocio,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveSocio,

     renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
         <>
        <DialogTitle variant="h3">Crea Nuovo Socio</DialogTitle>
        <DialogContent
           sx={{ width: '1000px'}}
        >
          {internalEditComponents} {/* or render custom edit components here */}

        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>

       </>
    ),


   renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Edit Socio</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),

    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        {/*<Tooltip title="Edit">*/}
        {/*  <IconButton onClick={() => table.setEditingRow(row)}>*/}
        {/*    <EditIcon />*/}
        {/*  </IconButton>*/}
        {/*</Tooltip>*/}

       {/*<Tooltip title="Ricevuta">*/}
       {/*<IconButton onClick={() => {openRicevutaModal(row)}}>*/}
       {/*    <ReceiptIcon />*/}
       {/*</IconButton>*/}
       {/*</Tooltip>*/}

      <Tooltip title="Ricevute">
       <IconButton onClick={() => {openSociRicModal(row)}}>
           <ReceiptIcon />
       </IconButton>
       </Tooltip>



      {isDialogSociRicOpen && currentRowId === row.id && <SociRic id={(Number(currentRowId))} onClose={()  => setIsDialogSociRicOpen(false) } />}





        <Tooltip title="Modifica">
        <IconButton onClick={() => {openEditModal(row)}}>
           <EditIcon />
       </IconButton>
       </Tooltip>
        {isDialogEditOpen && currentRowId === row.id && <SociEdit id={(Number(currentRowId))} onClose={()  => setIsDialogEditOpen(false) } />}


       <Tooltip title="Rimuovi">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),


    renderTopToolbarCustomActions: ({ table }) => (
      <>

      {/*  <Button*/}
      {/*      variant="contained"*/}
      {/*      onClick={() => {*/}
      {/*        table.setCreatingRow(true); //simplest way to open the create row modal with no default values*/}
      {/*        //or you can pass in a row object to set default values with the `createRow` helper function*/}
      {/*        // table.setCreatingRow(*/}
      {/*        //   createRow(table, {*/}
      {/*        //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios*/}
      {/*        //   }),*/}
      {/*        // );*/}
      {/*      }}*/}
      {/*   >*/}
      {/*  Create New Socio*/}
      {/*</Button>*/}

      <Button variant="contained" onClick={openAddModal}>Nuovo Socio</Button>

        {isDialogAddOpen && (
              <SociAdd
                onClose={(parcamep: string, partiposocio: string) => {
                                        setIsDialogAddOpen(false);
                                        //console.log('SOCI backparam', param);

                                        // Open Ricevute component if param is '9999'
                                        if (parcamep !== '0') {
                                          // console.log('SOCI backparam', parcamep);
                                           const stateParams = {
                                                                statecamep: parcamep,
                                                                statetiposocio: partiposocio,
                                                                };
                                           navigate("/app/ricevute", { state: stateParams });
                                          // Render the Ricevute component with the received param
                                          // setParam(param);
                                        }
                                      }}
                                    />
                                  )}
        {/*{param === '9999' && <Ricevute propcamep={param} />}*/}


        {/*<Button*/}
        {/*  disabled={table.getPrePaginationRowModel().rows.length === 0}*/}
        {/*  //export all rows, including from the next page, (still respects filtering and sorting)*/}
        {/*  onClick={() =>*/}
        {/*    handleExportCSVRows(table.getPrePaginationRowModel().rows)*/}
        {/*  }*/}
        {/*  startIcon={<FileDownloadIcon />}*/}
        {/*>*/}
        {/*  Export All Rows*/}
        {/*</Button>*/}
        {/*<Button*/}
        {/*  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)*/}
        {/*  onClick={handleExportCSVData}*/}
        {/*  startIcon={<FileDownloadIcon />}*/}
        {/*>*/}
        {/*  EXPORT DATI CSV*/}
        {/*</Button>*/}

        {/*<Button onClick={handleExportExcel} startIcon={<FileDownloadIcon />}>*/}
        {/* EXPORT DATI  EXCEL*/}
        {/*</Button>*/}

          <Button onClick={openSociExpModal} startIcon={<FileDownloadIcon />}>EXPORT DATI </Button>

       {isDialogSociExpOpen && <SociExp columnFilters={columnFilters} globalFilter={globalFilter} sorting={sorting} onClose={()  => setIsDialogSociExpOpen(false)} />}





      </>

      ),





    //rowCount: meta?.totalRowCount ?? 0,
    rowCount: count ?? 0,

    state: {
      isLoading: isLoadingSoci,
      isSaving: isCreatingSocio || isUpdatingSocio || isDeletingSocio,
      showAlertBanner: isLoadingSociError,
      showProgressBars: isFetchingSoci,

      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },
  });

  return    <Box sx={{mt: '60px' // , width: '1376px'
        }}><MaterialReactTable table={table} /> </Box>;
};






//CREATE hook (post new socio to api)
function useCreateSocio() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (socio: Socio) => {
      //send api update request here
      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
      return AxiosInstance.post('soci/0/',socio);
    },
    //client side optimistic update
    //  onMutate: (newSocioInfo: Socio) => {
    //    queryClient.setQueryData(
    //      ['soci'],
    //      (prevSoci: any) =>
    //        [
    //          ...prevSoci,
    //          {
    //            ...newSocioInfo,
    //            id: (Math.random() + 1).toString(36).substring(7),
    //          },
    //        ] as Socio[],
    //    );
    //   },
    //  onSettled: () => queryClient.invalidateQueries({ queryKey: ['soci'] }), //refetch soci after mutation, disabled for demo
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['fetchSoci'] }), //refetch soci after mutation, disabled for demo
     });
}

//READ hook (get soci from api)
function useGetSoci(pagination: MRT_PaginationState,columnFilters:  MRT_ColumnFiltersState,globalFilter: string,sorting: MRT_SortingState) {
  return useQuery<SociDTOApiResponse>({
    queryKey: ['fetchSociDTO',
       columnFilters, //refetch when columnFilters changes
       globalFilter, //refetch when globalFilter changes
       pagination.pageIndex, //refetch when pagination.pageIndex changes
       pagination.pageSize, //refetch when pagination.pageSize changes
       sorting, //refetch when sorting changes
    ],
    queryFn: async () => {

      const myOffset = (pagination.pageIndex==0) ? "0": `${pagination.pageSize*(pagination.pageIndex)}`;

      //const fetchURL = new URL("soci/","/");
      //fetchURL.searchParams.set('limit',`${pagination.pageSize}`,);
      //fetchURL.searchParams.set('offset', myOffset);
      //fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      // fetchURL.searchParams.set('',columnFiltersParam(columnFilters));
      //fetchURL.searchParams.set('search', globalFilter ?? '');
      //fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));
      //console.log("SORTING: ",sorting)
      // console.log("COLUMNFILTERS: ",columnFilters)
      const myURL = '?limit='+`${pagination.pageSize}`+'&offset='+myOffset+'&search='+(globalFilter ?? '')+columnFiltersParam(columnFilters)+'&ordering='+sortingParam(sorting);
      //console.log(myURL);
      const response = await AxiosInstance.get('socidto/'+myURL);
      const json = (response.data) as SociDTOApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

//mp conversione  filter + sorting parameters per django rest api
function columnFiltersParam(columnFilters: MRT_ColumnFiltersState) {
  var loopData = ''
  var i;
  for (i = 0; i < columnFilters.length; i++) {
    loopData += "&"
    loopData += `${columnFilters[i].id}`+"="+`${columnFilters[i].value}`
    //if (i < columnFilters.length-1)
    //  loopData += "&"
  }
  //console.log(loopData);
  //const columnFiltersParam = `${encodeURIComponent(loopData)}`;
  return loopData
}


function sortingParam(sorting: MRT_SortingState) {
  let ordering = '';
  for (let i = 0; i < sorting.length; i++) {
    const { id, desc } = sorting[i];
    ordering += desc ? `${id},` : `-${id},`;
  }
  // Remove the trailing comma
  ordering = ordering.slice(0, -1);
  return ordering;
}


// function columnFiltersParam(columnFilters: MRT_ColumnFiltersState) {
//     let loopData = '';
//     for (let i = 0; i < columnFilters.length; i++) {
//         if (columnFilters[i].id === 'whatsapp' && columnFilters[i].value === 'S') {
//             columnFilters[i].value = true;
//         } else {
//             columnFilters[i].value = false;
//         }
//         loopData += "&";
//         loopData += `${columnFilters[i].id}=${columnFilters[i].value}`;
//     }
//     return loopData;
// }





//UPDATE hook (put socio in api)
function useUpdateSocio() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (socio: Socio) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newSocioInfo: Socio) => {
      queryClient.setQueryData(
        ['soci'],
        (prevSoci: any) =>
          prevSoci?.map((prevSocio: Socio) =>
            prevSocio.id === newSocioInfo.id ? newSocioInfo : prevSocio,
          ),
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['soci'] }), //refetch soci after mutation, disabled for demo
  });
}

//DELETE hook (delete socio in api)
function useDeleteSocio() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      //send api update resquest here
      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      // return Promise.resolve();
      const response = await AxiosInstance.delete('soci/'+id+'/');
     return  response
    },
    //client side optimistic update
    // onMutate: (id: string) => {
    //   queryClient.setQueryData(
    //     ['soci'],
    //     (prevSoci: any) =>
    //       prevSoci?.filter((socio: Socio) => socio.id !== id),
    // );
    //},
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['fetchSociDTO'] }), //refetch soci after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

// const SociWithProviders = () => (
//   //Put this with your other react-query providers near root of your app
//   <QueryClientProvider client={queryClient}>
//     <Soci />
//   </QueryClientProvider>
// );

export default Soci;

const validateRequired = (value: string) => !!value.length;
const validateRequiredNum = (value: number) => !!value.toString().length;
const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

function validateSocio(socio: Socio) {
  return {
  //  camep: !validateRequiredNum(socio.camep)  ? 'Camep is Required' : '',
  //   tipo: !validateRequired(socio.tipo) ? 'Tipo is Required' : '',

  };
}
