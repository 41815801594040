
import React, { useState, useEffect } from 'react';
import { AppBar, Avatar, Box, Drawer, IconButton, List, ListItem, ListItemIcon, Popover, Toolbar, Tooltip, Typography } from '@mui/material';
import {
    FaChartBar,
    FaAddressBook,
    FaBars,
    FaBriefcase,
    FaBuilding,
    FaCar,
    FaChartLine,
    FaCog,
    FaDiceD6,
    FaHandshake,
    FaDollarSign,
    FaIndustry,
    FaSignOutAlt,
    FaTachometerAlt,
    FaUserFriends,
    FaUsers, FaDatabase
} from "react-icons/fa";
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { fetchData } from './FetchData';
import { ProfileUrl } from '../services/ApiUrls';
import { Header1 } from './FetchData';
import Veicoli from '../pages/veicoli/Veicoli';
import logo from '../assets/images/auth/img_logo.png';
import { StyledListItemButton, StyledListItemText } from '../styles/CssStyled';
// import MyContext, { MyContextData } from '../context/Context';
import MyContext from '../context/Context';
import Soci from "../pages/soci/Soci";
import Ricevute from "../pages/ricevute/Ricevute";
import RicevutePdf from "../pages/ricevute/RicevutePdf";
import RicevutePivot from "../pages/ricevute/RicevutePivot";
import Contributi from "../pages/contributi/Contributi";
import Iscrizioni from "../pages/iscrizioni/Iscrizioni";
import Reports from "../pages/reports/Reports";
import Gruppi from "../pages/gruppi/Gruppi";



// declare global {
//     interface Window {
//         drawer: any;
//     }
// }

export default function Sidebar(props: any) {
    const navigate = useNavigate()
    const location = useLocation()
    const [screen, setScreen] = useState('soci')
    const [drawerWidth, setDrawerWidth] = useState(200)
    const [headerWidth, setHeaderWidth] = useState(drawerWidth)
    const [userDetail, setUserDetail] = useState('')


     useEffect(() => {
        const currentPath = location.pathname.split('/')[2];
        if (currentPath) {
            setScreen(currentPath);
        }
    }, [location.pathname]);



     // useEffect(() => {
     //     toggleScreen()
     // }, [navigate])

  //   const [isAuth, setIsAuth] = useState(false);
  //
  // useEffect(() => {
  //   if (localStorage.getItem('access_token') !== null) {
  //     setIsAuth(true);
  //   }
  // }, [isAuth]);




    useEffect(() => {
    // navigate('/soci')
    // console.log("Inside useEffect");
    // console.log("location:", localStorage);

    if (localStorage.getItem('access_token') ) {
        navigate('/app/soci')
      //  setScreen('soci') vedi sotto
        toggleScreen()
    }
    if (!localStorage.getItem('access_token')) {
        navigate('/login')
    }
    }, [])

    const toggleScreen = () => {
         // console.log("Inside toggleScreen");
         // console.log("Current location:", location.pathname);
         // console.log(location.pathname.split('/'), 'll')
        if (location.pathname.split('/')[1] === '' || location.pathname.split('/')[1] === undefined || location.pathname.split('/')[2] === 'soci') {
            setScreen('soci')
        } else if (location.pathname.split('/')[2] === 'ricevute') {
            setScreen('ricevute')
        } else if (location.pathname.split('/')[2] === 'veicoli') {
            setScreen('veicoli')
        } else if (location.pathname.split('/')[2] === 'reports') {
            setScreen('reports')
        } else if (location.pathname.split('/')[2] === 'contributi') {
            setScreen('contributi')
        } else if (location.pathname.split('/')[2] === 'iscrizioni') {
            setScreen('iscrizioni')
        } else if (location.pathname.split('/')[2] === 'gruppi') {
            setScreen('gruppi')
        // } else if (location.pathname.split('/')[2] === 'ricevuteopendia') {
        //     setScreen('ricevuteopendia')
        }
    }

    // useEffect(() => {
    //     userProfile()
    // }, [])

    const userProfile = () => {
        fetchData(`${ProfileUrl}/`, 'GET', null as any, Header1)
            .then((res: any) => {
                // console.log(res, 'user')
                if (res?.user_obj) {
                    setUserDetail(res?.user_obj)
                }
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    const navList = ['soci', 'ricevute','veicoli','reports', 'contributi', 'iscrizioni','gruppi']
    const navIcons = (text: any, screen: any): React.ReactNode => {
        switch (text) {
            case 'soci':
                return screen === 'soci' ? <FaUsers fill='#3e79f7' /> : <FaUsers />
            case 'ricevute':
                return screen === 'ricevute' ? <FaDollarSign fill='#3e79f7' /> : <FaDollarSign />
            case 'veicoli':
                return screen === 'veicoli' ? <FaCar fill='#3e79f7' /> : <FaCar />
            case 'reports':
                return screen === 'reports' ? <FaChartBar fill='#3e79f7' /> : <FaChartBar />
            case 'contributi':
                return screen === 'contributi' ? <FaDatabase fill='#3e79f7' /> : <FaDatabase />
            case 'iscrizioni':
                return screen === 'iscrizioni' ? <FaDatabase fill='#3e79f7' /> : <FaDatabase />
            case 'gruppi':
                return screen === 'gruppi' ? <FaDatabase fill='#3e79f7' /> : <FaDatabase />

            default: return <FaDiceD6 fill='#3e79f7' />
        }
    }


    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        userProfile();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // console.log(screen, 'sidebar');
    const context = { drawerWidth: drawerWidth, screen: screen }
    return (
        <>
            <Box>
                <AppBar position="fixed"
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        height: '50px',
                        backgroundColor: 'white',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        // boxShadow: 'none',
                        // borderBottom: `0.5px solid #0000001f`
                        boxShadow: '1px'
                    }}
                >
                    <Box>
                        <Toolbar>
                            {/*{drawerWidth === 60 ? <img src={logo} width={'40px'} style={{ transform: 'rotate(270deg)', marginLeft: '-15px', marginRight: '10px' }} /> : <img src={logo} width={'100px'} style={{ marginLeft: '-5px', marginRight: '30px' }} />}*/}
                            <IconButton sx={{ ml: '-10px' }} onClick={() => setDrawerWidth(drawerWidth === 60 ? 200 : 60)}>
                                <FaBars style={{ height: '20px' }} />
                            </IconButton>
                            <Typography sx={{ fontWeight: 'bold', color: 'black', ml: '20px', textTransform: 'capitalize', fontSize: '20px', mt: '5px' }}>
                                {screen}
                            </Typography>
                        </Toolbar>
                    </Box>
                    <Box style={{
                        marginRight: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        {/* <IconButton onClick={userProfile} sx={{ mr: 2 }}><FaCog /></IconButton> */}
                        <IconButton onClick={handleClick} sx={{ mr: 3 }}>
                            <Avatar
                                // src='hj'
                                sx={{ height: '27px', width: '27px' }}
                            />
                        </IconButton>
                        <Popover
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                        >
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <StyledListItemButton onClick={() => {
                                        localStorage.clear()
                                        navigate('/logout')
                                    }}>
                                        <ListItemIcon > <FaSignOutAlt fill='#3e79f7' /></ListItemIcon>
                                        <StyledListItemText primary={'Logout'} sx={{ ml: '-20px', color: '#3e79f7' }} />
                                    </StyledListItemButton>
                                </ListItem>
                            </List>
                            {/* <Tooltip title='logout' sx={{ ml: '15px' }}>
                                <IconButton
                                    >
                                </IconButton>
                            </Tooltip> */}
                        </Popover>
                    </Box>
                </AppBar>

                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' }
                    }}
                >
                    <Box>
                        <List sx={{ pt: '65px' }}>
                            {navList.map((text, index) => (
                                <ListItem key={text} disablePadding  >
                                    <StyledListItemButton
                                        sx={{ pt: '6px', pb: '6px' }}
                                        onClick={() => {
                                            navigate(`/app/${text}`)
                                            setScreen(text)
                                        }}
                                        selected={screen === text}
                                    >
                                        <ListItemIcon sx={{ ml: '5px' }}>
                                            {navIcons(text, screen)}
                                        </ListItemIcon>
                                        <StyledListItemText primary={text} sx={{ ml: -2, textTransform: 'capitalize' }} />
                                    </StyledListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                </Drawer>
                <MyContext.Provider value={context}>
                   {/*<Box sx={{ width: drawerWidth === 60 ? '1380px' : '1240px', ml: drawerWidth === 60 ? '60px' : '200px', overflowX: 'hidden' }}>*/}
                    <Box sx={{ width: 'auto', ml: drawerWidth === 60 ? '60px' : '200px', overflowX: 'hidden' }}>
                        <Routes>
                            <Route index element={<Soci />} />
                            <Route path='soci' element={<Soci />} />
                            <Route path='ricevute' element={<Ricevute />} />
                            <Route path='veicoli' element={<Veicoli />} />
                            <Route path='reports' element={<Reports />} />
                            <Route path='ricevutepdf' element={<RicevutePdf />} />
                            <Route path='ricevutepivot' element={<RicevutePivot />} />
                            <Route path='contributi' element={<Contributi />} />
                            <Route path='iscrizioni' element={<Iscrizioni />} />
                            <Route path='gruppi' element={<Gruppi />} />
                        </Routes>
                    </Box>
                   {/*</Box>*/}
                </MyContext.Provider>

            </Box >
        </>

    )
}

