/* eslint-disable react/jsx-wrap-multilines */
import React, {useEffect, useRef, useState} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Button,
    TextField,
    CircularProgress, DialogTitle, DialogActions, Dialog, DialogContent
} from '@mui/material';
import {Box, Grid, ListItemText, MenuItem, Typography} from "@mui/material";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
    SocioForm,
} from "./types";
import CodiceFiscale from "codice-fiscale-js";
import {
    useCreateSocio,
    useGetCamepProg,
    useGetComuniByProvincia,
    useGetProvince,
    useGetStati,
    useGetTipoGruppo
} from "./Services";
import {RicevutaForm} from "../ricevute/types";



interface SociAddProps {
  onClose: (parcamep: string, partiposocio: string) => void;
}

// const SociAdd  = ()  => {
const SociAdd: React.FC<SociAddProps> = ({ onClose }) => {

   const validationSchema = Yup.object().shape({
                camep: Yup.number(),
                asi : Yup.number().transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value),
                gruppo :Yup.string(),
                nome :Yup.string().required('Nome o Ragione Sociale obbligatorio'),
                cognome :Yup.string(),
                sesso :Yup.string(),
                indirizzo :Yup.string().required('Indirizzo è obbligatorio'),
                cap :Yup.string().required('Cap è obbligatorio'),
                comune :Yup.string().required('Comune è obbligatorio'),
                provincia :Yup.string().required('Provincia è obbligatorio'),
                tel :Yup.string(),
              //  fax :Yup.string(),
                cell :Yup.string().required('Cellulare è obbligatorio'),
                pretel :Yup.string(),
                mail :Yup.string().required('Email è obbligatoria').email('Email non è valida'),
                comunenascita :Yup.string(),
                provincianascita :Yup.string(),
                statonascita :Yup.string(),
                cf :Yup.string(),
                datanascita: Yup.date()
                        .nullable()
                        .transform((value, originalValue) => {
                            if (originalValue === "") {
                                return undefined; // Convert empty string to null
                            }
                            return value;
                        })
                        .typeError("Data di nascita deve essere una data valida"),
                whatsapp :Yup.boolean(),
                tiposocio :Yup.string().required('Tipo Socio è obbligatorio'),
               });

  const [provincia, setProvincia] = useState('Perugia');
  const [provincianascita, setProvincianascita] = useState('Perugia');

  const [tipoSocio, setTiposocio] = useState('');

  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [sesso, setSesso] = useState('');
  const [comunenascita, setComunenascita] = useState('');
  const [statonascita, setStatonascita] = useState('ITALIA');
  const [datanascita, setDatanascita] = useState('');
  const [cf, setCf] = useState('');

  const [camep, setCamep] = useState(0);

  const { mutateAsync: createSocio, isPending: isCreatingSocio } = useCreateSocio();

  const { setValue, register,control, handleSubmit, formState: { errors },reset,watch} = useForm({ resolver: yupResolver(validationSchema)})

  const {  data: camepProgApiResponse,refetch: refetchCamepProg  } = useGetCamepProg();


  const { data: comuniApiResponse, refetch: refetchComuni } = useGetComuniByProvincia(provincia);
  const { data: comuniNascitaApiResponse, refetch: refetchComuninascita } = useGetComuniByProvincia(provincianascita);


  const {  data: tipoGruppoApiResponse } = useGetTipoGruppo();

  const {  data: provinceApiResponse } = useGetProvince();
  const {  data: provinceNascitaApiResponse } = useGetProvince();

  const {  data: statiPretelApiResponse } = useGetStati('pretel');
  const { data: statiDesStatoApiResponse } = useGetStati('desstato');

  useEffect(() => {
        if (provincia) {
             refetchComuni();
    	}

	}, [provincia]);

   useEffect(() => {
         if (provincianascita) {
              // console.log('refetchComuninascita()');
               refetchComuninascita();
               //reset();
     	}
	 }, [provincianascita]);





const handleCf = () => {

       //   console.log(nome,cognome,sesso,statonascita,datanascita,provincianascita,comunenascita);
    if (nome && cognome && sesso  && statonascita && datanascita && provincianascita && comunenascita) {

      //  console.log(nome,cognome,sesso,statonascita,datanascita,provincianascita,comunenascita);
        try {
                    const myCF = new CodiceFiscale({
                        name: nome,
                        surname: cognome,
                        gender: (sesso=='M' ? "M" : "F"),
                        day: parseInt(datanascita.substring(8, 10)),
                        month: parseInt(datanascita.substring(5, 7)),
                        year: parseInt(datanascita.substring(0, 4)),
                        birthplace: (statonascita == 'ITALIA' ? comunenascita : statonascita),
                        birthplaceProvincia: (statonascita == 'ITALIA' ? '' : 'EE')
                    });

                    if (myCF && myCF.cf) {
                        setValue('cf', myCF.cf);
                        setCf(myCF.cf);
                    } else {
                        console.error('CodiceFiscale generation failed: No CF value obtained.');
                    }
                } catch (error) {
                    // Handle the error gracefully
                    console.error('An error occurred during CF generation:', error);
                    // You might want to log the error or inform the user in some way without crashing the application
                }
      }
  }
    const handleProvincia = (prov: string) => {
        // console.log('handleProvincia ', prov);
        setProvincia(prov);
      };

    const handleProvincianascita = (prov: string) => {
        // console.log('handleProvincianascita ', prov);
        setProvincianascita(prov);
    };


    const handleStatonascita = (stat: string) => {
        setStatonascita(stat);
    };


  const clickedButtonRef = useRef<string | null>(null);

  const handleButtonClick = (buttonId: string) => {
        clickedButtonRef.current = buttonId;
      };


 const onSubmit: SubmitHandler<SocioForm> = async (data) => {

        await refetchCamepProg();

        if (camepProgApiResponse) {

            const camepProg = camepProgApiResponse.results[0].camepprog;

            if (camepProg !== undefined) {
                data.camep = camepProg;
            } else {
                console.error("camepProg is undefined");
            }
        }



     // if (!data.datanascita) {
     //      console.log("DATA",data.datanascita);
     //      // delete data.datanascita;
     //      data.datanascita = undefined; // Or any other default value you want to set
     //  }



      if (clickedButtonRef.current === 'save') {
           await createSocio(data);
           onClose('0',String(data.tiposocio));
       } else if (clickedButtonRef.current === 'ricevuta') {
           await createSocio(data);
           onClose(String(data.camep),String(data.tiposocio));
       }
   };

    const handleClose = () => {
            onClose('0','');
          };

  return (

 // <Box sx={{mt: '100px' , ml:10, width: '75%'  }}>
    <Dialog onClose={onClose} open fullWidth={ true } maxWidth={"lg"}>
      <DialogTitle>
        {/*{" "}*/}
      <Typography variant="h5">Nuovo Socio</Typography>
      </DialogTitle>
      {/*<DialogContent>*/}
      {/*</DialogContent>*/}
      <DialogContent>
       {/*{tipoGruppoApiResponse && provinceApiResponse && comuniApiResponse && provinceNascitaApiResponse && comuniNascitaApiResponse ? (*/}
        <form onSubmit={handleSubmit(onSubmit)} >
        {/*<form onSubmit={(e) => onSubmit(data, e.nativeEvent.submitter.id)}>*/}
         <DialogContent>
          <Grid container direction="row"  spacing={1}>
            <Grid container direction="column"  sm={0.3}  spacing={1} ></Grid>
            <Grid container direction="column"  sm={3.5}  spacing={1}>

                      {/*<input*/}
                      {/*type="hidden"*/}
                      {/*  required*/}
                        {/*id="camep"*/}
                        {/*name="camep"*/}
                        {/* label="Camep"*/}
                        {/*fullWidth*/}
                        {/*margin="dense"*/}
                        {/*{...register('camep')}*/}
                       {/*error={!!errors.camep}*/}
                      {/*/>*/}
                      {/*<Typography variant="inherit" color="textSecondary">*/}
                      {/*  {errors.camep?.message}*/}
                      {/*</Typography>*/}

                    <Grid item >
                      <TextField
                        //  required
                          id="asi"
                          label="Asi"
                          fullWidth
                          margin="dense"
                          {...register('asi')}
                          error={!!errors.asi}
                          >
                       </TextField>
                      <Typography variant="inherit" color="textSecondary">
                        {errors.asi?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                          select
                          id="gruppo"
                          label="Gruppo"
                          fullWidth
                          margin="dense"
                        // helperText="Seleziona"
                          {...register('gruppo')}
                          error={!!errors.gruppo}
                          >
                       {tipoGruppoApiResponse?.results.map(({id,tipo}) => {
                                  return (
                                    <MenuItem key={id} value={tipo} dense={true}>
                                     {tipo}
                                    </MenuItem>
                                  )

                        })}
                        </TextField>
                      <Typography variant="inherit" color="textSecondary">
                        {errors.gruppo?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                        // required
                        id="nome"
                        label="Nome / Ragione Sociale"
                        fullWidth
                        margin="dense"
                        {...register('nome')}
                        onChange={(e) => setNome(e.target.value)}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.nome?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                     //   required
                        id="cognome"
                        label="Cognome"
                       fullWidth
                        margin="dense"
                        {...register('cognome')}
                        // error={!!errors.anno}
                         onChange={(e) => setCognome(e.target.value)}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.cognome?.message}
                      </Typography>
                        </Grid>
                    <Grid item >
                    <TextField
                          select
                          id="sesso"
                          label="Sesso"
                          margin="dense"
                          fullWidth
                  //        helperText="Seleziona"
                          {...register('sesso')}
                          error={!!errors.sesso}
                          onChange={(e) => setSesso(e.target.value)}
                          >
                                    <MenuItem key={'F'} value={'F'}> F</MenuItem>
                                    <MenuItem key={'M'} value={'M'}> M</MenuItem>


                        </TextField>
                      {/*<Typography variant="inherit" color="textSecondary">*/}
                      {/*  {errors.sesso?.message}*/}
                      {/*</Typography>*/}

                    </Grid>
                    <Grid item >
                       <TextField
                      //  required
                        id="mail"
                        label="Email*"
                        fullWidth
                        margin="dense"
                        {...register('mail')}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.mail?.message}
                      </Typography>
                    </Grid>
              </Grid>
            <Grid container direction="column"  sm={0.4}  spacing={1} ></Grid>
            <Grid container direction="column"  sm={3.5}  spacing={1} >
                   <Grid item >
                       <TextField
                     //   required
                        id="indirizzo"
                        label="Indirizzo*"
                        fullWidth
                        margin="dense"
                        {...register('indirizzo')}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.indirizzo?.message}
                      </Typography>
                   </Grid>
                   <Grid item >
                      <TextField
                     //   required
                        id="cap"
                        label="Cap*"
                        fullWidth
                        margin="dense"
                        {...register('cap')}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.cap?.message}
                      </Typography>
                  </Grid>
                   <Grid item >
                    <TextField
                          select
                          id="provincia"
                          label="Provincia*"
                          margin="dense"
                          fullWidth
                          value={provincia}
                  //        helperText="Seleziona"
                          {...register('provincia')}
                          error={!!errors.provincia}
                           onChange={(event) => {
                               // console.log('PROVINCIA onChange  triggered');
                               handleProvincia(event.target.value)}}

                          >
                           {provinceApiResponse?.results.map(({siglaauto,desprovincia}) => {
                                  return (
                                    <MenuItem key={siglaauto} value={siglaauto} dense={true}>
                                     {desprovincia}
                                    </MenuItem>
                                  )

                        })}
                        </TextField>
                      <Typography variant="inherit" color="textSecondary">
                        {errors.provincia?.message}
                      </Typography>

                    </Grid>
                   <Grid item >
                    <TextField
                          select
                          id="comune"
                          label="Comune*"
                          margin="dense"
                       //   value={comune}
                          fullWidth
                  //        helperText="Seleziona"
                          {...register('comune')}
                          error={!!errors.comune}
                          >
                           {comuniApiResponse?.results.map(({descomune}) => {
                                  return (
                                    <MenuItem key={descomune} value={descomune}>
                                     {descomune}
                                    </MenuItem>
                                  )

                        })}
                        </TextField>
                      <Typography variant="inherit" color="textSecondary">
                        {errors.comune?.message}
                      </Typography>

                    </Grid>
                   <Grid item >
                       <TextField
                    //    required
                        id="tel"
                        label="Telefono"
                        fullWidth
                        margin="dense"
                        {...register('tel')}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.tel?.message}
                      </Typography>
                    </Grid>
                  <Grid container direction="row"  spacing={1} alignItems="center">
                        <Grid item sm={0.3}></Grid>
                        <Grid item sm={4}  >
                            <TextField
                              select
                              id="pretel"
                              label="Pref.Int."

                              fullWidth
                              margin="dense"
                              defaultValue="+39"
                              {...register('pretel')}
                              error={!!errors.pretel}
                              >
                               {statiPretelApiResponse?.results.map(({id,pretel}) => {
                                          return (
                                            <MenuItem key={id} value={pretel} dense={true}>
                                             {pretel}
                                            </MenuItem>
                                          )

                                })}
                                </TextField>
                              <Typography variant="inherit" color="textSecondary">
                                {errors.gruppo?.message}
                              </Typography>

                         </Grid>
                         <Grid item sm={7.7}>
                           <TextField
                        //    required
                            id="cell"
                            label="Cellulare*"
                            fullWidth
                            margin="dense"
                            {...register('cell')}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.cell?.message}
                          </Typography>
                          </Grid>

                    </Grid>
                 </Grid>
            <Grid container direction="column"  sm={0.4}  spacing={1} ></Grid>
            <Grid container direction="column"  sm={3.5}  spacing={1} >
               <Grid item >
                   <TextField
                          select
                          id="provincianascita"
                          label="Provincia di nascita"
                          margin="dense"
                          fullWidth
                          value={provincianascita}
                  //        helperText="Seleziona"
                          {...register('provincianascita')}
                          error={!!errors.provincianascita}
                           onChange={(event) => {
                               // console.log('PROVINCIA NASCITA onChange  triggered');
                               handleProvincianascita(event.target.value)}}

                          >
                                {provinceApiResponse?.results.map(({siglaauto,desprovincia}) => {
                                  return (
                                    <MenuItem key={siglaauto} value={siglaauto} dense={true}>
                                     {desprovincia}
                                    </MenuItem>
                                  )


                        })}
                        </TextField>
                      <Typography variant="inherit" color="textSecondary">
                        {errors.provincianascita?.message}
                      </Typography>

                    </Grid>
               <Grid item >
                    <TextField
                          select
                          id="comunenascita"
                          label="Comune di nascita"
                          margin="dense"
                    //      value={comunenascita}
                          fullWidth
                  //        helperText="Seleziona"
                          {...register('comunenascita')}
                          error={!!errors.comunenascita}
                          onChange={(e) => setComunenascita(e.target.value)}
                          >
                           {comuniNascitaApiResponse?.results.map(({descomune}) => {
                                  return (
                                    <MenuItem key={descomune} value={descomune}>
                                     {descomune}
                                    </MenuItem>
                                  )

                        })}
                        </TextField>
                      <Typography variant="inherit" color="textSecondary">
                        {errors.comunenascita?.message}
                      </Typography>

                    </Grid>
                <Grid item >
                    <TextField
                          select
                          id="statonascita"
                          label="Stato di nascita"
                          margin="dense"
                          defaultValue = "ITALIA"
                    //      value={statonascita}
                          fullWidth
                  //        helperText="Seleziona"
                          {...register('statonascita')}
                          error={!!errors.statonascita}
                          onChange={(event) => {handleStatonascita(event.target.value)}}

                          >
                         {statiDesStatoApiResponse?.results.map(({id,desstato}) => {
                                  return (
                                    <MenuItem key={id} value={desstato} dense={true}>
                                     {desstato}
                                    </MenuItem>
                                  )

                        })}
                        </TextField>
                      <Typography variant="inherit" color="textSecondary">
                        {errors.statonascita?.message}
                      </Typography>

                    </Grid>
                  <Grid item >
                       <TextField
                       // required
                       id="datanascita"
                       type="date"
                        label="Data di nascita"
                        fullWidth
                        margin="dense"
                        {...register('datanascita')}
                        onChange={(e) => setDatanascita(e.target.value)}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.datanascita?.message}
                      </Typography>
                   </Grid>
                {/*<DialogContent>*/}
                   <Grid container direction="row"  spacing={1} alignItems="center">
                           <Grid item sm={0.3}></Grid>
                           <Grid item sm={9}>
                            <TextField
                              // required
                              id="cf"
                              label="Codice Fiscale"
                              fullWidth
                              margin="dense"
                              {...register('cf')}
                            />
                            <Typography variant="inherit" color="textSecondary">
                              {errors.cf?.message}
                            </Typography>
                            </Grid>
                            <Grid item sm={1}  >
                            <Button variant="contained" onClick={() => handleCf()}>
                              CF
                            </Button>
                            </Grid>
                  </Grid>
                {/*</DialogContent>*/}
                 <Grid item >
                       <TextField
                        type="checkbox"
                   //     required
                        id="whatsapp"
                        label="Whatsapp"
                        fullWidth
                        margin="dense"
                        {...register('whatsapp')}
                      />
                        <Typography variant="inherit" color="textSecondary">
                            {errors.whatsapp?.message}
                         </Typography>
                 </Grid>
                <Grid item >
                 <TextField
                          select
                          id="tiposocio"
                          label="Tipo Socio*"
                          margin="dense"
                          fullWidth
                  //        helperText="Seleziona"
                          {...register('tiposocio')}
                          error={!!errors.tiposocio}
                          onChange={(e) => setTiposocio(e.target.value)}
                          >
                                    <MenuItem key={'TESS'} value={'TESS'}> Tesserato</MenuItem>
                                    <MenuItem key={'COMM'} value={'COMM'}> Commerciante</MenuItem>
                        </TextField>
                      <Typography variant="inherit" color="textSecondary">
                        {errors.tiposocio?.message}
                      </Typography>
                    </Grid>
              </Grid>
           </Grid>
         </DialogContent>
         <DialogContent>
             <Grid container direction="row"  spacing={1}>
             <Grid item sm={6.5}/>
             <Grid item sm={1}>
                    <Button color="primary" type="reset"  variant="outlined">
                      CANCELLA
                    </Button>
                 </Grid>
             <Grid item sm={0.3}></Grid>
             <Grid item sm={1}>
                    <Button variant="contained" onClick={handleClose} color="error" >CHIUDI</Button>
             </Grid>
             <Grid item sm={0.1}></Grid>
             <Grid item sm={1}>
                    <Button  id="save" type="submit"  color="primary"  variant="contained" onClick={() => handleButtonClick('save')}
                      disabled={isCreatingSocio}
                      startIcon={
                        isCreatingSocio ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : null
                      }
                    >
                    SALVA
                    </Button>
                 </Grid>
             <Grid item sm={0.1}></Grid>
             <Grid item sm={1}>
                    <Button id="ricevuta" type="submit"  color="primary"   variant="contained"  onClick={() => handleButtonClick('ricevuta')}
                       disabled={isCreatingSocio}
                       startIcon={
                         isCreatingSocio ? (
                           <CircularProgress color="inherit" size={25} />
                         ) : null
                       }
                    >
                    RICEVUTA
                    </Button>
                 </Grid>
             </Grid>
         </DialogContent>
        </form>
        {/*) : (*/}
        {/*  <CircularProgress />*/}
        {/*)}*/}
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>

 // </Box>
  );
};


export default SociAdd;