import {Box} from "@mui/material";
import React, {useState} from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import {useQuery} from "@tanstack/react-query";
import {RicevuteApiResponse} from "./types";
import AxiosInstance from "../../components/Axios";



const PlotlyRenderers = createPlotlyRenderers(Plot);

function useGetRicevute() {
  return useQuery<RicevuteApiResponse>({
    queryKey: ['fetchRicevute'],
    queryFn: async () => {
const myURL = '?limit=&offset=0'
      //console.log(myURL);
      const response = await AxiosInstance.get('ricevute/'+myURL);
      const json = (response.data) as RicevuteApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}




//const data = [['attribute', 'attribute2'], ['value1', 'value2']];

// const data = [
//     {
//         attr1: 'value1_attr1',
//         attr2: 'value1_attr2',
//         //...
//     },
//     {
//         attr1: 'value2_attr1',
//         attr2: 'value2_attr2',
//         //...
//     },
//     //...
// ];

const RicevutePivot = () => {
    const {  data: { results = [], count } = {}} = useGetRicevute();


     const [state, setState] = useState({});

    const dataArray: string[][] = results.map((result) =>
    Object.values(result).map((value) => String(value))
  );


return (
   <Box sx={{mt: '100px' , ml:30, width: '75%'  }}>
     <PivotTableUI
        data={dataArray}
        onChange={s => setState(s)}
        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
        {...state}
     />
  </Box>

);
}

export default  RicevutePivot;