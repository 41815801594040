import React, { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
import {
    Dialog,
    DialogContent,
    Button,
    Grid,
    TextField,
    Typography,
    CircularProgress,
    MenuItem,
    Checkbox, ListItemText, FormControl, InputLabel, Select
} from "@mui/material";
import AxiosInstance from "../../components/Axios";
import AxiosInstanceUpload from "../../components/AxiosUpload";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { EmailForm } from "../ricevute/types";
import Alert from "@mui/material/Alert";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {SocioForm} from "../soci/types";
import {ReportDateForm, ReportDateTipoPagForm} from "./types";
import * as Yup from "yup";
import {useCreateRicevuta, useGetTipoPag} from "../ricevute/Services";
import {jasperIncassiBlob,  useSendEmail, useUploadReport} from "./Services";



interface ReportIncassiProps {
  onClose: () => void;
  formato: String;
}

const ReportIncassi: React.FC<ReportIncassiProps> = (props) => {

 const today = format(new Date(), 'yyyy-MM-dd');


const validationSchema = Yup.object().shape({
                      fromDate: Yup.date().required('Selezione Data Inizio'),
                      toDate: Yup.date().test('is-after-or-equal', 'La data di fine deve essere successiva alla data di inizio', function (value) {
                                         const { fromDate } = this.parent; // Access fromDate from parent object
                                         if (!fromDate || !value) {
                                         return true; // Skip validation if fromDate or toDate is not set
                                      }
                                      return fromDate <= value; // Validate if toDate is after or equal to fromDate
                                    }),
                      emailTo: Yup.string().email('Email non è valida'),

                    });





  const [blob, setBlob] = useState<Blob | null>(null);

  const [datesConfirmed, setDatesConfirmed] = useState(false);
  const [emailTo, setEmailTo] = useState('');

 const {  data: tipoPagApiResponse ,isError: isLoadinTipoPagError, isFetching: isFetchingTipoPag, isLoading: isLoadingTipoPag, } = useGetTipoPag();


   const { register,control,setValue,getValues, handleSubmit,reset, formState: {  isValid, errors }} = useForm({ resolver: yupResolver(validationSchema)})

   const { mutateAsync: uploadReport, isPending: isUploadingRicevuta } = useUploadReport();

   const { mutateAsync: sendEmail, isPending: isSendingEmail, isSuccess: isSuccessSendEmail } = useSendEmail();

   // const [tipopagamento, setTipopagamento] = useState<string[]>(tipoPagApiResponse?.results.map(({ tipo }) => tipo) || []);


   const [tipopagamento, setTipopagamento] = useState<string[]>([]);



useEffect(() => {
  // Check if data is available and not in error state
  if (tipoPagApiResponse && !isLoadinTipoPagError) {
    // Extract tipo from results and update tipopagamento
    const mytipopagamento = tipoPagApiResponse.results.map(({ tipo }) => tipo);
    setTipopagamento(mytipopagamento);
  }
}, [tipoPagApiResponse, isLoadinTipoPagError]);






  const handleDownload = () => {
    if (blob) {
      let myFileName = "ReportIncassiCamep."+props.formato;
      myFileName = myFileName.replace(/\//g, "-");

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = myFileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleEmail = async () => {
    if (blob) {
      const formData = new FormData();
      let myFileName = "ReportIncassiCamep."+props.formato;
      myFileName = myFileName.replace(/\//g, "-");
      formData.append('file', blob, myFileName);

      await uploadReport(formData);

      const emailForm: EmailForm = {
        email: (emailTo || ''),
        subject: 'Report Incassi',
        attachment: myFileName,
        tipo: 'report',
      };

      await sendEmail(emailForm);
    }
  };
  const handleClose = () => {
    props.onClose();
  };


const onSubmit: SubmitHandler<ReportDateTipoPagForm> = async (data) => {
  if (data.fromDate && data.toDate && tipopagamento) {
    // Ensure tipopagamento is not undefined before proceeding
    const formattedTipopagamento = tipopagamento.map(value => `'${value}'`).join(',');
    setDatesConfirmed(true);
    // await jasper(data.fromDate, data.toDate, formattedTipopagamento);

    const myBlob = await jasperIncassiBlob(data.fromDate, data.toDate, formattedTipopagamento, props.formato);

    setBlob(myBlob);

  } else {
    setDatesConfirmed(false);
  }
};


  return (
    <Dialog onClose={props.onClose} open fullWidth={true} maxWidth={"md"}>
      <DialogContent>
        {!datesConfirmed && (
        <form onSubmit={handleSubmit(onSubmit)} >
          <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                id="fromDate"
                label="DataInizio"
                type="date"
                margin="dense"
                fullWidth
                defaultValue={today}
                {...register('fromDate')}
                error={!!errors.fromDate}
                      />
              <Typography variant="caption" color="error">
                  {errors.fromDate?.message}
             </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="toDate"
                label="Data Fine"
                type="date"
                margin="dense"
                fullWidth
                defaultValue={today}
                {...register('toDate')}
                error={!!errors.toDate}
                      />
              <Typography variant="caption" color="error">
                  {errors.toDate?.message}
             </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="emailTo"
                label="Email per invio Report"
                type="email"
                fullWidth
                //onChange={(e) => setEmailTo(e.target.value)}
               margin="dense"
                 {...register('emailTo')}
                 onChange={(e) => setEmailTo(e.target.value)}
              />
            </Grid>

                 <Grid item xs={6}>
                     <TextField
                              id="tipopagamento"
                              select
                              margin="dense"
                              fullWidth
                              label="Tipo Pagamento"
                              value={tipopagamento}
                              onChange={(event) => setTipopagamento(event.target.value as unknown as string[])} // Cast event.target.value to unknown, then to string[]
                              SelectProps={{
                                multiple: true,
                                renderValue: (selected) => (selected as string[]).join(', '),
                              }}
                            >
                              {tipoPagApiResponse?.results.map(({ tipo }) => (
                                <MenuItem key={tipo} value={tipo}>
                                  <Checkbox checked={tipopagamento.includes(tipo)} />
                                  <ListItemText primary={tipo} />
                                </MenuItem>
                              ))}
                            </TextField>
                    </Grid>

            <Grid item xs={12} container alignItems="flex-end" justifyContent="flex-end" spacing={1}>
              <Grid item>
                <Button variant="contained" onClick={handleClose} color="error">CHIUDI</Button>
              </Grid>
              <Grid item>  </Grid>
              <Grid item>
                {/*<Button disabled={!fromDate || !toDate} color="primary"   type="submit"  variant="contained">*/}
               <Button disabled={!isValid} color="primary"   type="submit"  variant="contained">

                    CONFERMA DATE
               </Button>
              </Grid>
            </Grid>
          </Grid>
         </form>
        )}

        <Grid container spacing={2}>
          <Grid item>
            {datesConfirmed && (
              <>
                <Button variant="contained" onClick={handleClose} color="error">CHIUDI</Button>
                <Button variant="contained" onClick={handleDownload} color="primary" style={{ marginLeft: '10px' }}>Download {props.formato}</Button>
                <Button disabled={!emailTo} variant="contained" onClick={handleEmail} color="primary" style={{ marginLeft: '10px' }}>Invia Email {props.formato}</Button>
                {isSuccessSendEmail &&  <Alert variant="filled"  severity="success">Invio email avvenuta correttamente a {emailTo} !!</Alert>}

              </>
            )}
          </Grid>
        </Grid>

        <div>
          {props.formato == 'pdf' && datesConfirmed && blob && (
            <iframe src={window.URL.createObjectURL(blob)} width="100%" height="800"></iframe>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ReportIncassi;
