import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
  AnnoBySocioApiResponse,
  CamepProgApiResponse,
  ComuniApiResponse,
  ProvinceApiResponse,
  SociApiResponse, SociDTOApiResponse,
  Socio,
  SocioApiResponse, SocioForm, StatiApiResponse,
  TipoGruppoApiResponse
} from "./types";
import AxiosInstance from "../../components/Axios";
import {Ricevuta, RicevuteApiResponse} from "../ricevute/types";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {MRT_ColumnFiltersState, MRT_SortingState} from "material-react-table";
import {VeicoliApiResponse} from "../veicoli/types";


const moment = require('moment')
export function useGetComuniByProvincia(siglaauto: string) {
  return useQuery<ComuniApiResponse>({
    queryKey: ['fetchComuniByProv'+siglaauto],
    queryFn: async () => {
      const response = await AxiosInstance.get('comunibyprov/'+siglaauto+'/?limit=1000&offset=0');
      const json = (response.data) as ComuniApiResponse;
     // console.log('axios 1 FETCH ',prov)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}


export function useGetProvince() {
  return useQuery<ProvinceApiResponse>({
    queryKey: ['fetchProvince'],
    queryFn: async () => {
      const response = await AxiosInstance.get('province/?limit=1000&offset=0');
      const json = (response.data) as ProvinceApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}


export function useGetStati(order: string) {
  // console.log('useGetStati called with order:', order);
  return useQuery<StatiApiResponse>({
    queryKey: ['fetchStati',order],
    queryFn: async () => {
      const response = await AxiosInstance.get('stati/?limit=1000&offset=0&ordering='+order);
      const json = (response.data) as StatiApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}


export function useGetTipoGruppo() {
  return useQuery<TipoGruppoApiResponse>({
    queryKey: ['fetchTipoGruppo'],
    queryFn: async () => {
      const response = await AxiosInstance.get('tipogruppo/?limit=100&offset=0');
      const json = (response.data) as TipoGruppoApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}


export function useUpdateSocio() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (socio: Socio) => {
      if (socio.datanascita) {
        socio.datanascita = moment(socio.datanascita).format('YYYY-MM-DD');
      }

        return AxiosInstance.patch('soci/'+socio.id+'/',socio);
    },
    // //client side optimistic update
    // onMutate: (newSocioInfo: Socio) => {
    //   queryClient.setQueryData(
    //     ['soci'],
    //     (prevSoci: any) =>
    //       prevSoci?.map((prevSocio: Socio) =>
    //         prevSocio.id === newSocioInfo.id ? newSocioInfo : prevSocio,
    //       ),
    //   );
    // },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['fetchSociDTO'] }), //refetch soci after mutation, disabled for demo
  });
}


export function useGetSocio(id: number = 0) {
  return useQuery<Socio>({
    queryKey: ['fetchSocio'],
    queryFn: async () => {
      const response = await AxiosInstance.get('soci/'+id+'/');
      const json = (response.data.data.socio) as Socio;
     //console.log(json)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetSocioByRicevuta(idric: number) {
  return useQuery<Socio>({
    queryKey: ['useGetSocioByRicevuta'],
    queryFn: async () => {
      const response = await AxiosInstance.get('sociobyricevuta/'+idric+'/');
      const json = (response.data.data.socio) as Socio;
     //console.log(json)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

export function useCreateSocio() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (socioForm: SocioForm) => {
      if (socioForm.datanascita) {
        socioForm.datanascita = moment(socioForm.datanascita).format('YYYY-MM-DD');
      }

      return AxiosInstance.post('soci/0/',socioForm);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['fetchSociDTO'] }), //refetch soci after mutation, disabled for demo
     });
}


export function useGetCamepProg() {
  return useQuery<CamepProgApiResponse>({
    queryKey: ['fetchCamepProg'],
    queryFn: async () => {
      const response = await AxiosInstance.get('camepprog/');
      const json = (response.data) as CamepProgApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetRicevuteBySocio(camep: number) {
  return useQuery<RicevuteApiResponse>({
    queryKey: ['fetchRicevuteBySocio'+camep],
    queryFn: async () => {
      const response = await AxiosInstance.get('ricevutebysocio/'+camep+'/?limit=1000&offset=0');
      const json = (response.data) as RicevuteApiResponse;
   //  console.log('CAMEP',camep)
   //  console.log(json)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetAnnoBySocio(camep: number) {
  return useQuery<AnnoBySocioApiResponse>({
    queryKey: ['fetchAnnoBySocio'+camep],
    queryFn: async () => {
      const response = await AxiosInstance.get('annobysocio/'+camep+'/?limit=1000&offset=0');
      const json = (response.data) as AnnoBySocioApiResponse;
      return json;
    },
    refetchOnWindowFocus: false,
  });
}





export function RicevuteTable({ ricevute }: { ricevute: Ricevuta[] }) {
  return (
   <TableContainer component={Paper} style={{ maxHeight: '300px', overflowY: 'auto' }}>
      <Table aria-label="ricevute table">
        <TableHead>
          <TableRow>
            <TableCell>Progressivo</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Nuovo</TableCell>
            <TableCell>Rinn.</TableCell>
            <TableCell>Onor.</TableCell>
            <TableCell>Asi</TableCell>
            <TableCell>Club</TableCell>
            <TableCell>Auto</TableCell>
            <TableCell>Moto</TableCell>
            <TableCell>Omol</TableCell>
            <TableCell>Dup</TableCell>
            <TableCell>Segr</TableCell>
            <TableCell>Post</TableCell>
            {/* Add more table cells for other fields */}
          </TableRow>
        </TableHead>
        <TableBody>
          {ricevute.map((ricevuta) => (
            <TableRow key={ricevuta.id}>
              <TableCell>{ricevuta.progressivo}</TableCell>
              <TableCell>{ricevuta.dataricevuta ? new Date(ricevuta?.dataricevuta).toLocaleDateString() : ''}</TableCell>
              <TableCell>{ricevuta.nuovo ? 'Si' : 'No'}</TableCell>
              <TableCell>{ricevuta.rinnovo ? 'Si' : 'No'}</TableCell>
              <TableCell>{ricevuta.onorario ? 'Si' : 'No'}</TableCell>
              <TableCell>{ricevuta.iscrizioneasi}</TableCell>
              <TableCell>{ricevuta.iscrizioneclub}</TableCell>
              <TableCell>{ricevuta.contributoauto}</TableCell>
              <TableCell>{ricevuta.contributomoto}</TableCell>
              <TableCell>{ricevuta.contributoomol}</TableCell>
              <TableCell>{ricevuta.contributodup}</TableCell>
              <TableCell>{ricevuta.spesesegreteria}</TableCell>
              <TableCell>{ricevuta.raccomandata}</TableCell>

              {/* Add more table cells for other fields */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};


export function useGetSociExp(columnFilters:  MRT_ColumnFiltersState,globalFilter: string,sorting: MRT_SortingState) {
  return useQuery<SociDTOApiResponse>({
    queryKey: ['fetchSociExp',
       columnFilters, //refetch when columnFilters changes
       globalFilter, //refetch when globalFilter changes
       sorting, //refetch when sorting changes
    ],
    queryFn: async () => {

      const myURL = '?limit=100000&offset=0'+'&search='+(globalFilter ?? '')+columnFiltersParam(columnFilters)+'&ordering='+sortingParam(sorting);
      // console.log('FROMEXPORTER',myURL);
      const response = await AxiosInstance.get('socidto/'+myURL);
      const json = (response.data) as SociDTOApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

//mp conversione  filter parameters per django rest api
function columnFiltersParam(columnFilters: MRT_ColumnFiltersState) {
  var loopData = ''
  var i;
  for (i = 0; i < columnFilters.length; i++) {
    loopData += "&"
    loopData += `${columnFilters[i].id}`+"="+`${columnFilters[i].value}`
    //if (i < columnFilters.length-1)
    //  loopData += "&"
  }
  //console.log(loopData);
  //const columnFiltersParam = `${encodeURIComponent(loopData)}`;
  return loopData
}

function sortingParam(sorting: MRT_SortingState) {
  let ordering = '';
  for (let i = 0; i < sorting.length; i++) {
    const { id, desc } = sorting[i];
    ordering += desc ? `${id},` : `-${id},`;
  }
  // Remove the trailing comma
  ordering = ordering.slice(0, -1);
  return ordering;
}