import * as Yup from "yup";

//let patternDueNumDecimali = /^\d+(\.\d{0,2})?$/;
let patternDueNumDecimali = /^\d+(\.\d{0,2})?$/;
// export const valutaValidator = Yup
//   .number()
//   .moreThan(-1)
//   .test(
//     "is-decimal",
//     "Inserire un numero con 2 cifre decimali",
//     (val: any) => {
//       if (val != undefined) {
//         return patternDueNumDecimali.test(val);
//       }
//       return true;
//     }
//   )
//   //.min(5, "minimum 5")
//   //.max(10, "maximum 10")
//   //.required("Is required");


export const valutaValidator = Yup.number().transform((value, originalValue) => {
    // Convert the value to a string and replace comma with dot as the decimal separator
    const cleanedValue = originalValue.toString().replace(',', '.');
    // Ensure it's a valid number
    const parsedValue = parseFloat(cleanedValue);
    // Check if it's a valid number
    return isNaN(parsedValue) ? undefined : parsedValue;
}).typeError('Deve essere un numero con virgola e due decimali')
.test(
     (val: any) => {
       if (val != undefined) {
         return patternDueNumDecimali.test(val);
       }
       return true;
     }
   );


export const annoValidator = Yup
  .number()
  .positive()
  .typeError('Anno YYYY')
  .min(1900, "Anno YYYY")
  .max(2100, "Anno YYYY")
  //.required("Is required");


export default valutaValidator;