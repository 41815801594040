/* eslint-disable react/jsx-wrap-multilines */
import React, {useEffect, useState} from 'react';
import {annoValidator, valutaValidator} from '../../components/YupValidators'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Button,
    TextField,
    CircularProgress, DialogTitle, DialogActions, Dialog, DialogContent, InputAdornment, Autocomplete
} from '@mui/material';
import {Box, Grid, ListItemText, MenuItem, Typography} from "@mui/material";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AxiosInstance from "../../components/Axios";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {SociListaDTOApiResponse, RicevutaForm, RicevuteProgApiResponse, TipoPagApiResponse,TipoContributoApiResponse,TipoIscrizioneApiResponse} from "./types";
import { format } from 'date-fns';
import {SocioForm} from "../soci/types";
import {
    useCreateRicevuta,
    useGetRicevuteProg, useGetSociListaDTO,
    useGetTipoContributo,
    useGetTipoIscrizione,
    useGetTipoPag
} from './Services';




//CREATE hook (post new ricevuta to api)


interface RicevuteAddProps {
  onClose: () => void;
  proptiposocio: string;
  propcamep: string
}

// const RicevuteAdd  = ()  => {
const RicevuteAdd: React.FC<RicevuteAddProps> = ({ onClose,proptiposocio,propcamep }) => {


  const validationSchema = Yup.object().shape({
                camep: Yup.number().required('Camep è obbligatorio').typeError('Camep è un numero'),
                socio  :Yup.string(),
                anno : annoValidator,
                nuovo  :Yup.boolean(),
                rinnovo :Yup.boolean(),
                onorario :Yup.boolean(),
                tipopagamento	:Yup.string().required('Tipo Pagamento è obbligatorio'),
              //  cc		        :Yup.boolean(),
                dataricevuta	:Yup.date(),
                iscrizioneasi	:valutaValidator,
                iscrizioneclub	:valutaValidator,
                contributoauto	:valutaValidator,
                contributomoto	:valutaValidator,
                contributoomol	:valutaValidator,
                contributodup	:valutaValidator,
                spesesegreteria	:valutaValidator,
                raccomandata	:valutaValidator,
                tipoiscrizione	:Yup.string(),
                tipocontributoauto	:Yup.string(),
                tipocontributomoto	:Yup.string(),
                tipocontributodup	:Yup.string(),
                tipocontributoomol	:Yup.string(),


               });




  const { mutateAsync: createRicevuta, isPending: isCreatingRicevuta } = useCreateRicevuta();

  const { register,control,setValue,getValues, handleSubmit,reset, formState: {  errors }} = useForm({ resolver: yupResolver(validationSchema)})



  const {  data: tipoPagApiResponse ,isError: isLoadinTipoPagError, isFetching: isFetchingTipoPag, isLoading: isLoadingTipoPag, } = useGetTipoPag();

  const {  data: tipoIscrizioneApiResponse  } = useGetTipoIscrizione();
  const {  data: tipoContributoApiResponse } = useGetTipoContributo();

  const {  data: sociListaDTOApiResponse } = useGetSociListaDTO();

  const {  data: ricevuteProgApiResponse, refetch: refetchRicevuteProg  } = useGetRicevuteProg();


 //data: { results = [], count } = {}

  const [spesetipocontributoauto, setSpesetipocontributoauto] = useState(0);
  const [spesetipocontributomoto, setSpesetipocontributomoto] = useState(0);
  const [spesetipocontributodup, setSpesetipocontributodup] = useState(0);
  const [spesetipocontributoomol, setSpesetipocontributoomol] = useState(0);


  const [iscrizioneasi, setIscrizioneasi] = useState(0);
  const [iscrizioneclub, setIscrizioneclub] = useState(0);

  const [contributoauto, setContributoauto] = useState(0);
  const [contributomoto, setContributomoto] = useState(0);
  const [contributoomol, setContributoomol] = useState(0);
  const [contributodup, setContributodup] = useState(0);
  const [spesesegreteria, setSpesesegreteria] = useState(0);
  const [roccomandata, setRaccomandata] = useState(10);

  const [camep, setCamep] = useState(Number(propcamep)||0);

// ATTENZIONE setValue dell'hook per agg campi hook form e useState per aggiornare stato react .....


  const today = format(new Date(), 'yyyy-MM-dd');
  const year = new Date().getFullYear();





  const handleSocio = (camep: string) => {
       // console.log('Value changed:', value);
       // console.log(sociRicApiResponse);
      if (camep) {
          const mycamep = Number(camep);
          setValue('camep', mycamep);
          setCamep(mycamep);
          }
     //   reset();
  };





  const handleIscrizione = (tipo: string) => {
      //console.log(tipoIscrizioneApiResponse);
      if (tipoIscrizioneApiResponse) {
          const myObject = tipoIscrizioneApiResponse.results.find(item => item.tipo === tipo);
          if (myObject) {
              setValue('iscrizioneasi', myObject.iscrizioneasi);
              setIscrizioneasi(myObject.iscrizioneasi);
              setValue('iscrizioneclub', myObject.iscrizioneclub);
              setIscrizioneclub(myObject.iscrizioneclub);
           //   setTipoIscrizione(myObject.tipo);
          }
          }
     //   reset();
  };


   const handleContributoAuto = (tipo: string, categoria: string) => {
     //console.log(tipoContributoApiResponse);
      if (tipoContributoApiResponse) {
          const myObject = tipoContributoApiResponse.results.find(item => item.tipo === tipo  && item.categoria === categoria );
          if (myObject) {
              setValue('contributoauto', myObject.contributoauto);
              setContributoauto(myObject.contributoauto);
              // setValue('contributoomol', myObject.contributoomol);
              // setContributoomol(myObject.contributoomol);
              // //setValue('spesesegreteria', myObject.spesesegreteria);
              // //setSpesesegreteria(myObject.spesesegreteria);
            setSpesetipocontributoauto(myObject.spesesegreteria);
            }

           }

  };

   const handleContributoMoto = (tipo: string,categoria: string) => {
  //   console.log(tipoContributoApiResponse+"  "+tipo);
      if (tipoContributoApiResponse) {
          const myObject = tipoContributoApiResponse.results.find(item => item.tipo === tipo && item.categoria === categoria);
          if (myObject) {
              setValue('contributomoto', myObject.contributomoto);
              setContributomoto(myObject.contributomoto);
              // setValue('contributoomol', myObject.contributoomol);
              // setContributoomol(myObject.contributoomol);
              // setValue('spesesegreteria', myObject.spesesegreteria);
              // setSpesesegreteria(myObject.spesesegreteria);
              setSpesetipocontributomoto(myObject.spesesegreteria);
          }
          }
     //   reset();
  };

  const handleContributoDup = (tipo: string, categoria: string) => {
     //console.log(tipoContributoApiResponse);
      if (tipoContributoApiResponse) {
          const myObject = tipoContributoApiResponse.results.find(item => item.tipo === tipo && item.categoria === categoria);
          if (myObject) {
              setValue('contributodup', myObject.contributodup);
              setContributodup(myObject.contributodup);
              // setValue('spesesegreteria', myObject.spesesegreteria);
              // setSpesesegreteria(myObject.spesesegreteria);

               setSpesetipocontributodup(myObject.spesesegreteria);

          }
          }
     //   reset();
  };

  const handleContributoOmol = (tipo: string,categoria: string) => {
     //console.log(tipoContributoApiResponse);
      if (tipoContributoApiResponse) {
          const myObject = tipoContributoApiResponse.results.find(item => item.tipo === tipo && item.categoria === categoria);
          if (myObject) {
              setValue('contributoomol', myObject.contributoomol);
              setContributoomol(myObject.contributoomol);
              // setValue('spesesegreteria',myObject.spesesegreteria+spesesegreteria);
              // setSpesesegreteria(myObject.spesesegreteria+spesesegreteria);

              setSpesetipocontributoomol(myObject.spesesegreteria);
          }
          }

  };

    useEffect(() => {
        // Update value when any of the state variables change

        // console.log('spesetipocontributoauto:', spesetipocontributoauto, typeof spesetipocontributoauto);
        // console.log('spesetipocontributomoto:', spesetipocontributomoto, typeof spesetipocontributomoto);
        // console.log('spesetipocontributodup:', spesetipocontributodup, typeof spesetipocontributodup);



        const newValue = parseFloat(spesetipocontributoauto.toString().replace('.',',')) +
                         parseFloat(spesetipocontributomoto.toString().replace('.',',')) +
                         parseFloat(spesetipocontributodup.toString().replace('.',',')) +
                         parseFloat(spesetipocontributoomol.toString().replace('.',','));
        // console.log('newValue:',newValue)

        setValue('spesesegreteria', newValue);
        setSpesesegreteria(newValue);
     }, [spesetipocontributoauto, spesetipocontributomoto, spesetipocontributodup, spesetipocontributoomol]);




const onSubmit: SubmitHandler<RicevutaForm> = async (data) => {

    await refetchRicevuteProg();

    //console.error(ricevuteProgApiResponse);

    if (ricevuteProgApiResponse && ricevuteProgApiResponse.results && ricevuteProgApiResponse.results.length > 0) {
        const ricevuteProg = ricevuteProgApiResponse.results[0].ricevuteprog;

        if (ricevuteProg !== undefined) {
            data.progressivo = ricevuteProg;
            await createRicevuta(data);
            onClose();
        } else {
            console.error("ricevuteprog is undefined");
        }
    } else {
        console.error("No results available in ricevuteProgApiResponse");
    }
};



    const handleClose = () => {
            onClose();
          };


  return (
 // <Box sx={{mt: '100px' , ml:10, width: '75%'  }}>
 // <Dialog onClose={onClose} open fullWidth maxWidth={"lg"}>
 <Dialog onClose={onClose} open fullWidth maxWidth={"xl"}>
      <DialogTitle>
        {/*{" "}*/}
      <Typography variant="h5">Nuova Ricevuta {(proptiposocio == 'COMM' ? 'Commercianti':'Tesserati') }</Typography>
      </DialogTitle>
      {/*<DialogContent>*/}
      {/*</DialogContent>*/}
      <DialogContent>
        <div style={{ marginTop: '15px' }}></div>
        <form onSubmit={handleSubmit(onSubmit)} >
          <Grid container direction="row"  spacing={1}>
              {/*<Grid container direction="column"  sm={0.3} spacing={1} ></Grid>*/}
              <Grid container direction="column"  xs={12} sm={12} md={3} lg={3} xl={3} spacing={1} style={{ margin: '0 8px' }} >
                    <Grid item >
                      {/*<TextField*/}
                      {/*   select*/}
                      {/*    //required*/}
                      {/*  id="socio"*/}
                      {/*  label="Socio*"*/}
                      {/*  fullWidth*/}
                      {/*  margin="dense"*/}
                      {/*  {...register('socio')}*/}
                      {/*   error={!!errors.socio}*/}
                      {/*  onChange={(event) => handleSocio(event.target.value)}*/}
                      {/*     >*/}
                      {/*        {sociListaDTOApiResponse?.results.map(({camep,label}) => {*/}
                      {/*            return (*/}
                      {/*              <MenuItem key={camep} value={camep}>*/}
                      {/*               {label}*/}
                      {/*              </MenuItem>*/}
                      {/*            )*/}
                      {/*        })}*/}
                      {/*</TextField>*/}
                      {/*<Typography variant="caption" color="error">*/}
                      {/*  {errors.socio?.message}*/}
                      {/*</Typography>*/}

                       <Autocomplete
                          disablePortal

                          // size={"small"}
                          id="socio"
                          value={sociListaDTOApiResponse?.results.find((item) => item.camep === camep) || null}
                          options={sociListaDTOApiResponse?.results ?? []}
                          // autoHighlight
                          //getOptionLabel={(option) => (<Typography sx={{fontSize: 18}}>
                          //                                      {option.label}
                          //                               </Typography>)}

                          // sx={{ width: 200 }}
                          // filterOptions={(options, { inputValue }) => {
                          //               return options.filter((option) =>
                          //                 option.label.toUpperCase().includes(inputValue.toUpperCase())
                          //               );
                          //             }}
                          onChange={(event,value) => {value !== null ? handleSocio(value.camep.toString()) : console.log("No value selected") }}
                          // renderInput={(params) => <TextField {...params}   margin="dense" label="Inserisci socio" />}
                          renderInput={(params) => <TextField {...params}  margin="dense" label="Seleziona Socio" />}

                        />

                    </Grid>
                    <Grid item >
                      <TextField
                        id="camep"
                        label="Camep*"
                        defaultValue={propcamep}
                        fullWidth
                        margin="dense"
                        {...register('camep')}
                        error={!!errors.camep}
                      />
                      <Typography variant="caption" color="error">
                        {errors.camep?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                          id="anno"
                          label="Anno"
                          defaultValue={year}
                          margin="dense"
                          fullWidth
                          {...register('anno')}
                          error={!!errors.anno}
                          >
                       </TextField>
                      <Typography variant="caption" color="error">
                        {errors.anno?.message}
                      </Typography>

                    </Grid>
                    <Grid item >
                      <TextField
                        type="checkbox"
                        id="nuovo"
                        label="Nuovo"
                        fullWidth
                        margin="dense"
                        {...register('nuovo')}
                        error={!!errors.nuovo}
                      />
                      <Typography variant="caption" color="error">
                        {errors.nuovo?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                        type="checkbox"
                        id="rinnovo"
                        label="Rinnovo"
                       fullWidth
                        margin="dense"
                        {...register('rinnovo')}
                          error={!!errors.rinnovo}
                      />
                      <Typography variant="caption" color="error">
                        {errors.rinnovo?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                       // required
                        type="checkbox"
                        id="onorario"
                        label="Onorario"
                        fullWidth
                        margin="dense"
                        {...register('onorario')}
                          error={!!errors.onorario}
                      />
                      <Typography variant="caption" color="error">
                        {errors.onorario?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                         select
                          //required
                        id="tipopagamento"
                        label="Tipo Pagamento*"
                        fullWidth
                        margin="dense"
                        {...register('tipopagamento')}
                         error={!!errors.tipopagamento}
                           >
                              {tipoPagApiResponse?.results.map(({tipo}) => {
                                  return (
                                    <MenuItem key={tipo} value={tipo}>
                                     {tipo}
                                    </MenuItem>
                                  )
                              })}
                      </TextField>
                      <Typography variant="caption" color="error">
                        {errors.tipopagamento?.message}
                      </Typography>
                      </Grid>
                    {/*<Grid item >*/}
                    {/*  <TextField*/}
                    {/*    type="checkbox"*/}
                    {/*   // required*/}
                    {/*    id="cc"*/}
                    {/*    label="C/C"*/}
                    {/*    fullWidth*/}
                    {/*    margin="dense"*/}
                    {/*    {...register('cc')}*/}
                    {/*    error={!!errors.cc}*/}
                    {/*  />*/}
                    {/*  <Typography variant="caption" color="error">*/}
                    {/*    {errors.cc?.message}*/}
                    {/*  </Typography>*/}
                    {/* </Grid>*/}
                    <Grid item >
                       <TextField
                        type="date"
                        id="dataricevuta"
                        label="Data Ricevuta"
                        fullWidth
                        margin="dense"
                        defaultValue={today}
                        {...register('dataricevuta')}
                           error={!!errors.dataricevuta}
                      />
                      <Typography variant="caption" color="error">
                        {errors.dataricevuta?.message}
                      </Typography>
                   </Grid>

              </Grid>
              {/*<Grid container direction="column"  sm={0.3}  spacing={1} ></Grid>*/}
              <Grid container direction="column"  xs={12} sm={12} md={4} lg={4} xl={4}  spacing={1} style={{ margin: '0 8px' }} >
                   <Grid item >
                      <TextField
                         select
                          //required
                        id="tipoiscrizione"
                        label="Tipo Iscrizione"
                        fullWidth
                        margin="dense"
                        {...register('tipoiscrizione')}
                        // error={!!errors.tipoiscrizione}
                          onChange={(event) => handleIscrizione(event.target.value)}
                           >
                        {/*<MenuItem value='none' dense={true}>*/}

                        {/*</MenuItem>*/}
                              {tipoIscrizioneApiResponse?.results.map(({id,tipo}) => {
                                  return (
                                    <MenuItem key={id} value={tipo} dense={true}>
                                     {tipo}
                                    </MenuItem>
                                  )
                              })}
                      </TextField>
                      <Typography variant="caption" color="error">
                      </Typography>
                      </Grid>
                   <Grid item >
                       <TextField
                       // type="number"
                      //  required
                        value={iscrizioneasi}
                        //defaultValue='0'
                        id="iscrizioneasi"
                        label="Iscrizione Asi"
                        fullWidth
                        margin="dense"
                        {...register('iscrizioneasi')}
                         error={!!errors.iscrizioneasi}
                      />
                      <Typography variant="caption" color="error">
                        {errors.iscrizioneasi?.message}
                      </Typography>
                  </Grid>
                   <Grid item >
                       <TextField
                        //defaultValue='0'
                        //required
                        value={iscrizioneclub}
                        id="iscrizioneclub"
                        label="Iscrizione Club"
                        fullWidth
                        margin="dense"
                        {...register('iscrizioneclub')}
                           // error={!!errors.iscrizioneclub}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.iscrizioneclub?.message}*/}
                      </Typography>
                 </Grid>
                   <Grid item >

                      <TextField
                      select
                      id="tipocontributoauto"
                      label="Tipo Contributo Auto"
                      fullWidth
                      margin="dense"
                      {...register('tipocontributoauto')}
                      onChange={(event) => handleContributoAuto(event.target.value,'AUTO'+proptiposocio)}

                    >
                      {(() => {
                        const filteredResults = tipoContributoApiResponse?.results.filter(({ categoria }) => {
                          return categoria === 'AUTO'+proptiposocio;
                        });

                        return filteredResults?.map(({ id, tipo }) => (
                          <MenuItem key={id} value={tipo} dense={true}>
                              {/*<Typography variant="caption">{tipo}</Typography>*/}
                              {tipo}
                          </MenuItem>
                        ));
                      })()}
                    </TextField>

                      <Typography variant="caption" color="error">
                       {/*{errors.tipopagamento?.message}*/}
                      </Typography>
                      </Grid>
                   <Grid item >
                        <TextField
                        value={contributoauto}
                        id="contributoauto"
                        label="Contributo Auto"
                        fullWidth
                        margin="dense"
                        {...register('contributoauto')}
                        // error={!!errors.contributoauto}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.contributoauto?.message}*/}
                      </Typography>
                    </Grid>
                   <Grid item >
                      <TextField
                         select
                          //required
                        id="tipocontributomoto"
                        label="Tipo Contributo Moto"
                        fullWidth
                        margin="dense"
                        {...register('tipocontributomoto')}
                         // error={!!errors.tipocontributo}
                         onChange={(event) => handleContributoMoto(event.target.value,'MOTO'+proptiposocio)}
                           >
                       {(() => {
                        const filteredResults = tipoContributoApiResponse?.results.filter(({ categoria }) => {
                          return categoria === 'MOTO'+proptiposocio;
                        });

                        return filteredResults?.map(({ id, tipo }) => (
                          <MenuItem key={id} value={tipo} dense={true}>
                            {tipo}
                          </MenuItem>
                        ));
                      })()}
                      </TextField>
                      <Typography variant="caption" color="error">
                       {/*{errors.tipopagamento?.message}*/}
                      </Typography>
                      </Grid>
                   <Grid item >
                       <TextField
                        value={contributomoto}
                        id="contributomoto"
                        label="Contributo Moto"
                        fullWidth
                        margin="dense"
                        {...register('contributomoto')}
                           // error={!!errors.contributomoto}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.contributomoto?.message}*/}
                      </Typography>
                    </Grid>
              </Grid>
              {/*<Grid container direction="column"  sm={0.4}  spacing={1} ></Grid>*/}
              <Grid container direction="column"  xs={12} sm={12} md={4} lg={4} xl={4}  spacing={1} style={{ margin: '0 8px' }} >
                   <Grid item >
                      <TextField
                         select
                          //required
                        id="tipocontributodup"
                        label="Tipo Contributo Dup"
                        fullWidth
                        margin="dense"
                        {...register('tipocontributodup')}
                         // error={!!errors.tipocontributo}
                         onChange={(event) => handleContributoDup(event.target.value,'DUP'+proptiposocio)}
                           >
                        {(() => {
                        const filteredResults = tipoContributoApiResponse?.results.filter(({ categoria }) => {
                          return categoria === 'DUP'+proptiposocio;
                        });

                        return filteredResults?.map(({ id, tipo }) => (
                          <MenuItem key={id} value={tipo} dense={true}>
                            {tipo}
                          </MenuItem>
                        ));
                      })()}
                      </TextField>
                      <Typography variant="caption" color="error">
                       {/*{errors.tipopagamento?.message}*/}
                      </Typography>
                      </Grid>
                   <Grid item >
                       <TextField
                        value={contributodup}
                        id="contributodup"
                        label="Contributo Dup"
                        fullWidth
                        margin="dense"
                        {...register('contributodup')}
                           // error={!!errors.contributodup}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.contributodup?.message}*/}
                      </Typography>
                    </Grid>
                   <Grid item >
                      <TextField
                         select
                          //required
                        id="tipocontributoomol"
                        label="Tipo Contributo Omol"
                        fullWidth
                        margin="dense"
                        {...register('tipocontributoomol')}
                         // error={!!errors.tipocontributo}
                         onChange={(event) => handleContributoOmol(event.target.value,'OMOL'+proptiposocio)}
                           >
                             {(() => {
                        const filteredResults = tipoContributoApiResponse?.results.filter(({ categoria }) => {
                          return categoria === 'OMOL'+proptiposocio;
                        });

                        return filteredResults?.map(({ id, tipo }) => (
                          <MenuItem key={id} value={tipo} dense={true}>
                            {tipo}
                          </MenuItem>
                        ));
                      })()}
                      </TextField>
                      <Typography variant="caption" color="error">
                       {/*{errors.tipopagamento?.message}*/}
                      </Typography>
                      </Grid>
                   <Grid item >
                       <TextField
                        value={contributoomol}
                        id="contributoomol"
                        label="Contributo Omol"
                        fullWidth
                        margin="dense"
                        {...register('contributoomol')}
                           // error={!!errors.contributoomol}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.contributoomol?.message}*/}
                      </Typography>
                    </Grid>
                    <Grid item >
                       <TextField
                        defaultValue='0'
                        id="raccomandata"
                        label="Contributo Spese Postali"
                        fullWidth
                        margin="dense"
                        {...register('raccomandata')}
                        error={!!errors.raccomandata}
                      />
                      <Typography variant="caption" color="error">
                       {errors.raccomandata?.message}
                      </Typography>
                    </Grid>
                      <Grid item >
                       <TextField
                        defaultValue='0'
                        id="spesesegreteria"
                        label="Spese Segreteria"
                        fullWidth
                        margin="dense"
                        {...register('spesesegreteria')}
                           // error={!!errors.spesesegreteria}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.spesesegreteria?.message}*/}
                      </Typography>
                    </Grid>


              </Grid>
          </Grid>
       <DialogContent>
      </DialogContent>
          <Grid container direction="row"  spacing={1}>
            {/*onClick={handleClose}*/}
                 <Grid item sm={8}/>
                 <Grid item sm={1}>
                    <Button color="primary" type="reset"  variant="outlined">
                      CANCELLA
                    </Button>
                 </Grid>
                 <Grid item sm={0.4}/>
                 <Grid item sm={1}>
                        <Button variant="contained" onClick={handleClose} color="error" >CHIUDI</Button>
                 </Grid>
                 <Grid item sm={0.2}/>
                 <Grid item sm={1}>
                    <Button  color="primary"   type="submit"  variant="contained"
                      disabled={isCreatingRicevuta}
                      startIcon={
                        isCreatingRicevuta ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : null
                      }
                    >
                    SALVA
                    </Button>
                 </Grid>

           </Grid>
        </form>
       </DialogContent>
       <DialogActions>
       </DialogActions>
    </Dialog>

 // </Box>
  );
};


// }
export default RicevuteAdd;