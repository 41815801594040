/* eslint-disable react/jsx-wrap-multilines */
import React, {useEffect, useState} from 'react';
import {
    Button, IconButton, Tooltip,
} from '@mui/material';
import {Box, Grid, ListItemText, MenuItem, Typography} from "@mui/material";
import { format } from 'date-fns';
import ArticleIcon from "@mui/icons-material/Article";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableRowsIcon from '@mui/icons-material/TableRows';

import ReportIncassi from "./ReportIncassi";

import ReportNuoviRinnoviSoci from "./ReportNuoviRinnoviSoci";
import ReportNuoviSoci from "./ReportNuoviSoci";




const Reports = () => {

  const [isDialogReportIncassiPDF, setIsDialogReportIncassiPDF] = useState(false);
  const [isDialogReportIncassiXLS, setIsDialogReportIncassiXLS] = useState(false);
  const [isDialogReportNuoviRinnoviSociPDF, setIsDialogReportNuoviRinnoviSociPDF] = useState(false);
  const [isDialogReportNuoviRinnoviSociXLS, setIsDialogReportNuoviRinnoviSociXLS] = useState(false);
  const [isDialogReportNuoviSociPDF, setIsDialogReportNuoviSociPDF] = useState(false);
  const [isDialogReportNuoviSociXLS, setIsDialogReportNuoviSociXLS] = useState(false);

  const today = format(new Date(), 'yyyy-MM-dd');
  const year = new Date().getFullYear();





  return (
  <Box sx={{ mt: '150px', ml: 10, width: '75%' }}>
  <Grid container direction="row" spacing={1}>
    {/* First Row */}
    <Grid container item direction="row" xs={12} spacing={1} sx={{ marginTop: '20px' }}>
      <Grid container item xs={3} justifyContent="center" spacing={1}>
        <Button
          onClick={() => {
            setIsDialogReportIncassiPDF(true);
          }}
          size="large"
          variant="contained"
          startIcon={<PictureAsPdfIcon />}
          sx={{ width: '200px', height: '100px' }} // Set fixed width for the button
        >
          Report Incassi PDF
        </Button>
        {isDialogReportIncassiPDF && <ReportIncassi formato="pdf" onClose={() => setIsDialogReportIncassiPDF(false)} />}
      </Grid>
      <Grid container item xs={3} justifyContent="center" spacing={1}>
        <Button
          onClick={() => {
            setIsDialogReportIncassiXLS(true);
          }}
          size="large"
          variant="contained"
          startIcon={<TableRowsIcon />}
          sx={{ width: '200px', height: '100px' }} // Set fixed width for the button
        >
          Report Incassi EXCEL
        </Button>
        {isDialogReportIncassiXLS && <ReportIncassi formato="xlsx" onClose={() => setIsDialogReportIncassiXLS(false)} />}
      </Grid>
      <Grid container item xs={3} justifyContent="center" spacing={1}>
        <Button
          onClick={() => {
            setIsDialogReportNuoviRinnoviSociPDF(true);
          }}
          size="large"
          variant="contained"
          startIcon={<PictureAsPdfIcon />}
          sx={{ width: '200px', height: '100px' }} // Set fixed width for the button
        >
          Report Nuovi e Rinnovi per Assemblea PDF
        </Button>
        {isDialogReportNuoviRinnoviSociPDF && <ReportNuoviRinnoviSoci formato="pdf" onClose={() => setIsDialogReportNuoviRinnoviSociPDF(false)} />}
      </Grid>
      <Grid container item xs={3} justifyContent="center" spacing={1}>
        <Button
          onClick={() => {
            setIsDialogReportNuoviRinnoviSociXLS(true);
          }}
          size="large"
          variant="contained"
          startIcon={<TableRowsIcon />}
          sx={{ width: '200px', height: '100px' }} // Set fixed width for the button
        >
          Report Nuovi e Rinnovi per Assemblea EXCEL
        </Button>
        {isDialogReportNuoviRinnoviSociXLS && <ReportNuoviRinnoviSoci formato="xlsx" onClose={() => setIsDialogReportNuoviRinnoviSociXLS(false)} />}
      </Grid>
    </Grid>

    {/* Second Row */}
    <Grid container item direction="row" xs={12} spacing={1} sx={{ marginTop: '20px' }}>
      <Grid container item xs={3} justifyContent="center" spacing={1}>
        <Button
          onClick={() => {
            setIsDialogReportNuoviSociPDF(true);
          }}
          size="large"
          variant="contained"
          startIcon={<TableRowsIcon />}
          sx={{ width: '200px', height: '100px' }} // Set fixed width for the button
        >
          Report Nuovi Soci PDF
        </Button>
        {isDialogReportNuoviSociPDF && <ReportNuoviSoci formato="pdf" onClose={() => setIsDialogReportNuoviSociPDF(false)} />}
      </Grid>
      <Grid container item xs={3} justifyContent="center" spacing={1}>
        <Button
          onClick={() => {
            setIsDialogReportNuoviSociXLS(true);
          }}
          size="large"
          variant="contained"
          startIcon={<TableRowsIcon />}
          sx={{ width: '200px', height: '100px' }} // Set fixed width for the button
        >
          Report Nuovi Soci Excel
        </Button>
        {isDialogReportNuoviSociXLS && <ReportNuoviSoci formato="xlsx" onClose={() => setIsDialogReportNuoviSociXLS(false)} />}
      </Grid>
    </Grid>
  </Grid>
</Box>

    );
};


// }
export default Reports;