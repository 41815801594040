import { usePDF, Document, Page } from '@react-pdf/renderer';
import {Box} from "@mui/material";

const MyDoc = (
   // <Box sx={{mt: '100px' , ml:200, width: '75%'  }}>
      <Document>
        <Page>
          // My document data TESSSSSSSSSSSSSSSSS
        </Page>
      </Document>
  // </Box>
);

const RicevutePdf = () => {
  const [instance, updateInstance] = usePDF({ document: MyDoc });

  if (instance.loading) return <div>Loading ...</div>;

  if (instance.error) return <div>Something went wrong: </div>;

return (
   <Box sx={{mt: '100px' , ml:30, width: '75%'  }}>
    <div>
    {instance.url && (
      <a href={instance.url} download="test.pdf">
        Download
      </a>
    )}
    </div>
  </Box>

);
}

export default  RicevutePdf;