/* eslint-disable react/jsx-wrap-multilines */
import React, {useEffect, useRef, useState} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Button,
    TextField,
    CircularProgress, DialogTitle, DialogContent, DialogActions, Dialog, Autocomplete
} from '@mui/material';
import {Box, Grid, ListItemText, MenuItem, Typography} from "@mui/material";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AxiosInstance from "../../components/Axios";
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {useParams} from "react-router-dom";
import {Ricevuta, RicevutaForm, TipoPagApiResponse} from "./types";
import {annoValidator, valutaValidator} from "../../components/YupValidators";
import {watch} from "fs";
import {useGetRicevuta, useGetSociListaDTO, useGetTipoPag, useUpdateRicevuta} from "./Services";




 interface RicevuteEditProps {
   onClose: () => void;
   id: number;
 }



// const RicevuteAdd  = ()  => {


const RicevuteEdit:  React.FC<RicevuteEditProps> = ({ onClose,id }) => {

    //const id = Number(useParams().id);

   const validationSchema = Yup.object().shape({
                 camep: Yup.number().required('Camep è obbligatorio').typeError('Camep è un numero'),
                 anno : annoValidator,
                 nuovo  :Yup.boolean(),
                 rinnovo :Yup.boolean(),
                 onorario :Yup.boolean(),
                 tipopagamento	:Yup.string().required('Tipo Pagamento è obbligatorio'),

                 dataricevuta	:Yup.date(),
                 iscrizioneasi	:valutaValidator,
                 iscrizioneclub	:valutaValidator,
                 contributoauto	:valutaValidator,
                 contributomoto	:valutaValidator,
                 contributoomol	:valutaValidator,
                 contributodup	:valutaValidator,
                 spesesegreteria	:valutaValidator,
                 raccomandata	:valutaValidator,
                 tipoiscrizione	:Yup.string(),
                 tipocontributoauto	:Yup.string(),
                 tipocontributomoto	:Yup.string(),
                 tipocontributodup	:Yup.string(),
                 tipocontributoomol	:Yup.string(),
                 progressivo  :Yup.string(),


               });



  //const { open, handleClose } = props;

 const {  data:  ricevuta,  isError: isLoadingRicevutaError, isFetching: isFetchingRicevuta, isLoading: isLoadingRicevuta, } = useGetRicevuta(id);

 const { mutateAsync: updateRicevuta, isPending: isUpdatingRicevuta } = useUpdateRicevuta();

 //const { watch, register,control, handleSubmit,reset, formState: { errors }} = useForm({resolver: yupResolver(validationSchema)});

 const { watch, register,control, setValue, handleSubmit,reset, formState: { errors }} = useForm({resolver: yupResolver(validationSchema)});

 //////////////////////   ATTENZIONE    console.log(errors);

  const {  data: sociListaDTOApiResponse } = useGetSociListaDTO();


 const {  data: tipoPagApiResponse ,isError: isLoadinTipoPagError, isFetching: isFetchingTipoPag, isLoading: isLoadingTipoPag, } = useGetTipoPag();


  const [tipopagamento, setTipopagamento] = useState(''); // Initialize with ricevuta?.tipopagamento if available

  const [camep, setCamep] = useState(0);
  const [socio, setSocio] = useState('');


// Verify that the dependency array of the useEffect is correct.
// In your case, [ricevuta] is the dependency, and the effect should only run when ricevuta changes.
  //const hasMounted = useRef(false);


   useEffect(() => {
       if (ricevuta) {
             reset(ricevuta);
             setTipopagamento(ricevuta?.tipopagamento || '')
             setCamep(ricevuta?.camep || 0)

          };
        return () =>
        {           //console.log('Before reset to NULL: RETURN', ricevuta);
                    //    reset()
                    //console.log('After reset to NULL: RETURN', ricevuta);
                        }
        }, [ricevuta]);


   const handleSocio = (camep: string) => {
       // console.log('Value changed:', value);
       // console.log(sociRicApiResponse);
      if (camep) {
          const mycamep = Number(camep);
          setValue('camep', mycamep);
          setCamep(mycamep);
          }
     //   reset();
  };




      const handleClose = () => {
                onClose();
          };


    const onSubmit: SubmitHandler<RicevutaForm> = async (data) => {

       await updateRicevuta(data);
       onClose();
   };







  return (


 // <Box sx={{mt: '100px' , ml:10, width: '75%'  }}>
    <Dialog onClose={onClose} open fullWidth maxWidth={"xl"}>
    {/*<Dialog onClose={onClose} open fullWidth={ true } maxWidth={"lg"}>*/}
      <DialogTitle>
        {/*{" "}*/}
      <Typography variant="h5">Modifica Ricevuta</Typography>
      </DialogTitle>
      {/*<DialogContent>*/}
      {/*</DialogContent>*/}
      <DialogContent>
        <div style={{ marginTop: '15px' }}></div>
        <form onSubmit={handleSubmit(onSubmit)} >

          <Grid container direction="row"  spacing={1}>
              {/*<Grid container direction="column"  sm={0.3} spacing={1} ></Grid>*/}
              <Grid container direction="column"  xs={12} sm={3} md={3} lg={3} xl={3} spacing={1} style={{ margin: '0 8px' }}>
                         <Grid item >
                           <TextField
                            id="progressivo"
                            label="Progressivo"
                            fullWidth
                            margin="dense"
                            {...register('progressivo')}
                               error={!!errors.progressivo}
                          />
                          <Typography variant="caption" color="error">
                            {/*{errors.progressivo?.message}*/}
                          </Typography>
                         </Grid>
                         <Grid item>

                                <Autocomplete
                                  disablePortal
                                  id="socio"
                                  value={sociListaDTOApiResponse?.results.find((item) => item.camep === camep) || null}
                                  // value={sociListaDTOApiResponse?.results.find((item) => item.label === socio) || null}
                                  options={sociListaDTOApiResponse?.results ?? []}
                                  onChange={(event,value) => {value !== null ? handleSocio(value.camep.toString()) : console.log("No value selected") }}
                                  // renderInput={(params) => <TextField {...params}   margin="dense" label="Inserisci socio" />}
                                  // renderInput={(params) => <TextField {...params} value={socio} margin="dense" label="Seleziona Socio" />}
                                  renderInput={(params) => (<TextField {...params} margin="dense" label="Seleziona Socio"  />)}
                                />



                         </Grid>
                         <Grid item >
                      <TextField
                        //required
                        id="camep"
                        label="Camep*"
                        fullWidth
                        margin="dense"
                        {...register('camep')}
                        // error={!!errors.camep}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.camep?.message}*/}
                      </Typography>
                    </Grid>
                         <Grid item >
                              <TextField
                                  id="anno"
                                  label="Anno"
                                  margin="dense"
                                  fullWidth
                                  {...register('anno')}
                                  error={!!errors.anno}
                                  >
                               </TextField>
                              <Typography variant="caption" color="error">
                                {/*{errors.anno?.message}*/}
                              </Typography>
                         </Grid>
                         <Grid item >
                          <TextField
                            type="checkbox"
                            id="nuovo"
                            label="Nuovo"
                            fullWidth
                            margin="dense"
                            {...register('nuovo')}
                            error={!!errors.nuovo}
                          />
                          <Typography variant="caption" color="error">
                            {/*{errors.nuovo?.message}*/}
                          </Typography>
                       </Grid>
                         <Grid item >
                          <TextField
                            type="checkbox"
                            id="rinnovo"
                            label="Rinnovo"
                           fullWidth
                            margin="dense"
                            {...register('rinnovo')}
                              error={!!errors.rinnovo}
                          />
                          <Typography variant="caption" color="error">
                            {/*{errors.rinnovo?.message}*/}
                          </Typography>
                        </Grid>
                         <Grid item >
                      <TextField
                       // required
                        type="checkbox"
                        id="onorario"
                        label="Onorario"
                        fullWidth
                        margin="dense"
                        {...register('onorario')}
                          error={!!errors.onorario}
                      />
                      <Typography variant="caption" color="error">
                       {/*{errors.onorario?.message}*/}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                         select
                          //required
                        id="tipopagamento"
                        label="Tipo Pagamento*"
                        fullWidth
                        value={tipopagamento}
                        //margin="dense"
                        {...register('tipopagamento')}
                          onChange={(event) => setTipopagamento(event.target.value)}
                         error={!!errors.tipopagamento}
                           >
                              {tipoPagApiResponse?.results.map(({tipo}) => {
                                  return (
                                    <MenuItem key={tipo} value={tipo}>
                                     {tipo}
                                    </MenuItem>
                                  )
                              })}
                      </TextField>
                      <Typography variant="caption" color="error">
                        {/*{errors.tipopagamento?.message}*/}
                      </Typography>
                      </Grid>
                    <Grid item >
                       <TextField
                        type="date"
                        id="dataricevuta"
                        label="Data Ricevuta"
                        fullWidth
                        margin="dense"
                        {...register('dataricevuta')}
                           error={!!errors.dataricevuta}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.dataricevuta?.message}*/}
                      </Typography>
                   </Grid>

              </Grid>
              {/*<Grid container direction="column"  sm={0.3}  spacing={1} ></Grid>*/}
              <Grid container direction="column"  xs={12} sm={4} md={4} lg={4} xl={4} spacing={1} style={{ margin: '0 8px' }} >
                   <Grid item >
                      <TextField
                        id="tipoiscrizione"
                        label="Tipo Iscrizione"
                        fullWidth
                        margin="dense"
                        {...register('tipoiscrizione')}
                        error={!!errors.tipoiscrizione}
                           >
                      </TextField>
                      <Typography variant="caption" color="error">
                          {/*{errors.tipoiscrizione?.message}*/}
                      </Typography>
                      </Grid>
                   <Grid item >
                       <TextField
                       id="iscrizioneasi"
                        label="Iscrizione Asi"
                        fullWidth
                        margin="dense"
                        {...register('iscrizioneasi')}
                          error={!!errors.iscrizioneasi}
                      />
                      <Typography variant="caption" color="error">
                        {errors.iscrizioneasi?.message}
                      </Typography>
                  </Grid>
                   <Grid item >
                       <TextField
                        id="iscrizioneclub"
                        label="Iscrizione Club"
                        fullWidth
                        margin="dense"
                        {...register('iscrizioneclub')}
                           error={!!errors.iscrizioneclub}
                      />
                      <Typography variant="caption" color="error">
                        {errors.iscrizioneclub?.message}
                      </Typography>
                 </Grid>
                   <Grid item >
                      <TextField
                      id="tipocontributoauto"
                      label="Tipo Contributo Auto"
                      fullWidth
                      margin="dense"
                      {...register('tipocontributoauto')}
                        >
                     </TextField>
                       <Typography variant="caption" color="error">
                       {/*{errors.tipopagamento?.message}*/}
                      </Typography>
                      </Grid>
                   <Grid item >
                        <TextField

                        id="contributoauto"
                        label="Contributo Auto"
                        fullWidth
                        margin="dense"
                        {...register('contributoauto')}
                        error={!!errors.contributoauto}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.contributoauto?.message}*/}
                      </Typography>
                    </Grid>
                   <Grid item >
                      <TextField

                        id="tipocontributomoto"
                        label="Tipo Contributo Moto"
                        fullWidth
                        margin="dense"
                        {...register('tipocontributomoto')}
                         error={!!errors.tipocontributomoto}
                           >
                        </TextField>
                      <Typography variant="caption" color="error">
                       {/*{errors.tipopagamento?.message}*/}
                      </Typography>
                      </Grid>
                   <Grid item >
                       <TextField
                        id="contributomoto"
                        label="Contributo Moto"
                        fullWidth
                        margin="dense"
                        {...register('contributomoto')}
                           error={!!errors.contributomoto}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.contributomoto?.message}*/}
                      </Typography>
                    </Grid>
              </Grid>
              {/*<Grid container direction="column"  sm={0.4}  spacing={1} ></Grid>*/}
              <Grid container direction="column"  xs={12} sm={4} md={4} lg={4} xl={4} spacing={1} style={{ margin: '0 8px' }} >
                   <Grid item >
                      <TextField

                          //required
                        id="tipocontributodup"
                        label="Tipo Contributo Dup"
                        fullWidth
                        margin="dense"
                        {...register('tipocontributodup')}
                         error={!!errors.tipocontributodup}
                           >
                      </TextField>
                      <Typography variant="caption" color="error">
                       {/*{errors.tipopagamento?.message}*/}
                      </Typography>
                      </Grid>
                   <Grid item >
                       <TextField
                        id="contributodup"
                        label="Contributo Dup"
                        fullWidth
                        margin="dense"
                        {...register('contributodup')}
                           error={!!errors.contributodup}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.contributodup?.message}*/}
                      </Typography>
                    </Grid>
                   <Grid item >
                      <TextField

                          //required
                        id="tipocontributoomol"
                        label="Tipo Contributo Omol"
                        fullWidth
                        margin="dense"
                        {...register('tipocontributoomol')}
                         error={!!errors.tipocontributodup}
                           >
                      </TextField>
                      <Typography variant="caption" color="error">
                       {/*{errors.tipopagamento?.message}*/}
                      </Typography>
                   </Grid>

                   <Grid item >
                       <TextField
                        id="contributoomol"
                        label="Contributo Omol"
                        fullWidth
                        margin="dense"
                        {...register('contributoomol')}
                           error={!!errors.contributoomol}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.contributoomol?.message}*/}
                      </Typography>
                    </Grid>
                    <Grid item >
                       <TextField
                        id="raccomandata"
                        label="Contributo Spese Postali"
                        fullWidth
                        margin="dense"
                        {...register('raccomandata')}
                           error={!!errors.raccomandata}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.raccomandata?.message}*/}
                      </Typography>
                    </Grid>
                      <Grid item >
                       <TextField
                         id="spesesegreteria"
                        label="Spese Segreteria"
                        fullWidth
                        margin="dense"
                        {...register('spesesegreteria')}
                        error={!!errors.spesesegreteria}
                      />
                      <Typography variant="caption" color="error">
                        {/*{errors.spesesegreteria?.message}*/}
                      </Typography>
                    </Grid>


              </Grid>
          </Grid>
          <DialogContent>
      </DialogContent>
          <Grid container direction="row"  spacing={1}>
            {/*onClick={handleClose}*/}
                 <Grid item sm={8}/>
                 <Grid item sm={1}>
                    <Button color="primary" type="reset"  variant="outlined">
                      CANCELLA
                    </Button>
                 </Grid>
                 <Grid item sm={0.4}/>
                 <Grid item sm={1}>
                        <Button variant="contained" onClick={handleClose} color="error" >CHIUDI</Button>
                 </Grid>
                 <Grid item sm={0.2}/>
                 <Grid item sm={1}>
                    <Button  color="primary"   type="submit"  variant="contained"
                      disabled={isUpdatingRicevuta}
                      startIcon={
                        isUpdatingRicevuta ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : null
                      }
                    >
                    SALVA
                    </Button>
                 </Grid>

           </Grid>
        </form>
       </DialogContent>
       <DialogActions>
       </DialogActions>
    </Dialog>
 // </Box>
  );
};


// }
export default RicevuteEdit;