/* eslint-disable react/jsx-wrap-multilines */
import React, {useEffect, useRef, useState} from 'react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Button,
    TextField,
    CircularProgress,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary
} from '@mui/material';
import {Box, Grid, ListItemText, MenuItem, Typography} from "@mui/material";
import {useGetAnnoBySocio, useGetSocio} from "./Services";
import {RicevuteTable, useGetRicevuteBySocio} from "./Services";
import {MRT_Row} from "material-react-table";
import {Socio} from "./types";
import {useNavigate} from "react-router-dom";




interface SociRicProps {
  onClose: () => void;
  id: number;
}

const SociRic:  React.FC<SociRicProps> = ({ onClose, id }) => {

    //const id = Number(useParams().id);


  //const { open, handleClose } = props;


  const {  data:  socio,  isError: isLoadingSocioError, isFetching: isFetchingSocio, isLoading: isLoadingSocio, } = useGetSocio(id);

  const {  data: ricevuteApiResponse, refetch: refetchRicevuteApiResponse} = useGetRicevuteBySocio(socio?.camep || 0);

  const {  data: annoBySocioApiResponse, refetch: refetchAnnoBySocioApiResponse} = useGetAnnoBySocio(socio?.camep || 0);

  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [cf, setCf] = useState('');
  const [tipoSocio, setTiposocio] = useState('');
  const [camep, setCamep] = useState(0);
  const [dataNascita, setDataNascita] = useState('');
  const [comuneNascita, setComuneNascita] = useState('');


  const navigate = useNavigate();


  useEffect(() => {
       if (socio) {
             setNome(socio?.nome || '')
             setCognome(socio?.cognome || '')
             setCf(socio?.cf || '')
             setTiposocio(socio?.tiposocio || '')
             setCamep(socio?.camep || 0)
             setComuneNascita(socio?.comunenascita || '')
           //  setDataNascita(socio?.datanascita || defaultDate);
              setDataNascita(socio?.datanascita ? new Date(socio?.datanascita).toLocaleDateString() : '');

            };
        return () =>
        {
                  //    reset()
                        }
  }, [socio]);

   const handleRic = () => {
        const stateParams = {statecamep: camep, statetiposocio: tipoSocio};
        navigate("/app/ricevute", { state: stateParams });
  };


      const handleClose = () => {
            onClose();
          };


  return (


 // <Box sx={{mt: '100px' , ml:10, width: '75%'  }}>
  <Dialog onClose={onClose} open fullWidth={ true } maxWidth={"lg"}>
      <DialogTitle>
        {/*{" "}*/}
      {/*<Typography variant="h5">Lista Ricevute Socio:</Typography>*/}
      {/*<Typography variant="h5"><b>{nome} {cognome}</b> nato il: {new Date(dataNascita).toLocaleDateString()} a {comuneNascita} ({cf})  ULTIMO RINNOVO: <b>{annoSocio}</b></Typography>*/}
   <Typography variant="h5">
    {/*<b>{nome} {cognome}</b> ({camep}) nato il: {dataNascita ? {dataNascita} {comuneNascita} ({cf})  :''}  ULTIMO RINNOVO: <b>{annoBySocioApiResponse ? annoBySocioApiResponse.results[0].annosocio : ''}</b>*/}
<b>{nome} {cognome}</b> {dataNascita ? ' nato il: ' +dataNascita + ' a: ' + comuneNascita + ' (' + cf + ')' : ''} ULTIMO RINNOVO: <b>{annoBySocioApiResponse ? annoBySocioApiResponse.results[0].annosocio : ''}</b>
</Typography>

      </DialogTitle>
      <DialogContent>
             <Grid container direction="row" spacing={1}>
                       <Grid item sm={1}/>
                       <Grid item sm={10}>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                <RicevuteTable ricevute={ricevuteApiResponse?.results||[]} />
                                </div>
                        </Grid>
             </Grid>
       </DialogContent>
       <DialogContent>
             <Grid container direction="row" spacing={1}>
                     <Grid item sm={8}/>
                          {/*<Grid item sm={1}>*/}
                          {/*    <Button color="primary" type="reset" variant="outlined">*/}
                          {/*        CANCELLA*/}
                          {/*    </Button>*/}
                          {/*</Grid>*/}
                          <Grid item sm={0.3}></Grid>
                          <Grid item sm={1}>
                              <Button variant="contained" onClick={handleClose} color="error">CHIUDI</Button>
                          </Grid>
                           <Grid item sm={2}>
                               <Button variant="contained" onClick={handleRic}>NUOVA RICEVUTA</Button>
                           </Grid>
                          <Grid item sm={0.1}></Grid>
             </Grid>
       </DialogContent>
  </Dialog>

 // </Box>
  );
};


// }
export default SociRic;