//import { authApi } from './authApi';

import {VeicoloForm, VeicoliApiResponse, TipoVeicoloApiResponse, Veicolo} from './types';
import AxiosInstance from "../../components/Axios";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState} from "material-react-table";


const moment = require('moment')



export function useCreateVeicolo() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (veicoloForm: VeicoloForm) => {
      veicoloForm.datais = moment(veicoloForm.datais).format('YYYY-MM-DD')
      veicoloForm.dataom = moment(veicoloForm.dataom).format('YYYY-MM-DD')
      return AxiosInstance.post('veicoli/0/',veicoloForm);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['fetchVeicoli'] }), //refetch veicoli after mutation, disabled for demo
     });
}



export function useGetTipoVeicolo() {
  return useQuery<TipoVeicoloApiResponse>({
    queryKey: ['fetchTipoVeicolo'],
    queryFn: async () => {
      const response = await AxiosInstance.get('tipoveicolo/?limit=100&offset=0');
      const json = (response.data) as TipoVeicoloApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}


export function useGetVeicolo(id: number = 0) {
  return useQuery<Veicolo>({
    queryKey: ['fetchVeicolo'],
    queryFn: async () => {
      const response = await AxiosInstance.get('veicoli/'+id+'/');
      const json = (response.data.data.veicolo) as Veicolo;
     //console.log(json)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}



//UPDATE hook (put veicolo in api)
export function useUpdateVeicolo() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (veicolo: Veicolo) => {
       veicolo.datais = moment(veicolo.datais).format('YYYY-MM-DD')
       veicolo.dataom = moment(veicolo.dataom).format('YYYY-MM-DD')
        return AxiosInstance.patch('veicoli/'+veicolo.id+'/',veicolo);
    },
    // //client side optimistic update
    // onMutate: (newVeicoloInfo: Veicolo) => {
    //   queryClient.setQueryData(
    //     ['veicoli'],
    //     (prevVeicoli: any) =>
    //       prevVeicoli?.map((prevVeicolo: Veicolo) =>
    //         prevVeicolo.id === newVeicoloInfo.id ? newVeicoloInfo : prevVeicolo,
    //       ),
    //   );
    // },
       onSuccess: () => queryClient.invalidateQueries({ queryKey: ['fetchVeicoli'] }), //refetch veicoli after mutation, disabled for demo
  });
}

export function useGetVeicoliExp(columnFilters:  MRT_ColumnFiltersState,globalFilter: string,sorting: MRT_SortingState) {
  return useQuery<VeicoliApiResponse>({
    queryKey: ['fetchVeicoliNew',
       columnFilters, //refetch when columnFilters changes
       globalFilter, //refetch when globalFilter changes
       sorting, //refetch when sorting changes
    ],
    queryFn: async () => {

      const myURL = '?limit=100000&offset=0'+'&search='+(globalFilter ?? '')+columnFiltersParam(columnFilters)+'&ordering='+sortingParam(sorting);
      // console.log('FROMEXPORTER',myURL);
      const response = await AxiosInstance.get('veicoli/'+myURL);
      const json = (response.data) as VeicoliApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

//mp conversione  filter parameters per django rest api
function columnFiltersParam(columnFilters: MRT_ColumnFiltersState) {
  var loopData = ''
  var i;
  for (i = 0; i < columnFilters.length; i++) {
    loopData += "&"
    loopData += `${columnFilters[i].id}`+"="+`${columnFilters[i].value}`
    //if (i < columnFilters.length-1)
    //  loopData += "&"
  }
  //console.log(loopData);
  //const columnFiltersParam = `${encodeURIComponent(loopData)}`;
  return loopData
}

function sortingParam(sorting: MRT_SortingState) {
  let ordering = '';
  for (let i = 0; i < sorting.length; i++) {
    const { id, desc } = sorting[i];
    ordering += desc ? `${id},` : `-${id},`;
  }
  // Remove the trailing comma
  ordering = ordering.slice(0, -1);
  return ordering;
}


