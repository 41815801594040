import axios from 'axios'

//const baseUrl = 'http://localhost:8000/api/'
//const baseUrl = ''

const AxiosInstanceUpload = axios.create({
    baseURL: BASE_URL_API,
    timeout: 10000,
    headers: {
       'Content-Type': 'multipart/form-data',

    }
})

export default AxiosInstanceUpload