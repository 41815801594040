/* eslint-disable react/jsx-wrap-multilines */
import React, {useEffect, useState} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Button,
    TextField,
    CircularProgress, DialogTitle, DialogContent, DialogActions, Dialog
} from '@mui/material';
import {Box, Grid, ListItemText, MenuItem, Typography} from "@mui/material";
import {TipoVeicoloApiResponse, VeicoliApiResponse, Veicolo, VeicoloApiResponse, VeicoloForm} from "./types"
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AxiosInstance from "../../components/Axios";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {DateField,DatePicker,DesktopDatePicker} from "@mui/x-date-pickers";
import {useParams} from "react-router-dom";



function useGetTipoVeicolo() {
  return useQuery<TipoVeicoloApiResponse>({
    queryKey: ['fetchTipoVeicolo'],
    queryFn: async () => {
      const response = await AxiosInstance.get('tipoveicolo/');
      const json = (response.data) as TipoVeicoloApiResponse;
    //  console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

function useGetVeicolo(id: number = 0) {
  return useQuery<Veicolo>({
    queryKey: ['fetchVeicolo'],
    queryFn: async () => {
      const response = await AxiosInstance.get('veicoli/'+id+'/');
      const json = (response.data.data.veicolo) as Veicolo;
     //console.log(json)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}



//UPDATE hook (put veicolo in api)
function useUpdateVeicolo() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (veicolo: Veicolo) => {
        return AxiosInstance.patch('veicoli/'+veicolo.id+'/',veicolo);
    },
    // //client side optimistic update
    // onMutate: (newVeicoloInfo: Veicolo) => {
    //   queryClient.setQueryData(
    //     ['veicoli'],
    //     (prevVeicoli: any) =>
    //       prevVeicoli?.map((prevVeicolo: Veicolo) =>
    //         prevVeicolo.id === newVeicoloInfo.id ? newVeicoloInfo : prevVeicolo,
    //       ),
    //   );
    // },
       onSuccess: () => queryClient.invalidateQueries({ queryKey: ['fetchVeicoli'] }), //refetch veicoli after mutation, disabled for demo
  });
}

interface VeicoliEditProps {
  onClose: () => void;
  id: number;
}

const VeicoliEdit:  React.FC<VeicoliEditProps> = ({ onClose, id}) => {

    //const id = Number(useParams().id);

    const validationSchema = Yup.object().shape({
                id :Yup.number(),
                camep: Yup.number().required('Camep è obbligatorio').typeError('Camep è obbligatorio e un numero'),
                tipo: Yup.string().required('Tipo è obbligatorio'),
                marca :Yup.string().typeError(''),
                 modello :Yup.string(),
                 anno :Yup.number(),
                 targa:Yup.string(),
                 ads :Yup.boolean(),
                 crs :Yup.boolean(),
                 datais :Yup.date(),
                 dataom :Yup.date(),
                 omasi :Yup.boolean(),
                //  username: Yup.string()
                //   .required('Username is required')
                //   .min(6, 'Username must be at least 6 characters')
                //   .max(20, 'Username must not exceed 20 characters'),
                // email: Yup.string()
                //   .required('Email is required')
                //   .email('Email is invalid'),
                // password: Yup.string()
                //   .required('Password is required')
                //   .min(6, 'Password must be at least 6 characters')
                //   .max(40, 'Password must not exceed 40 characters'),
                // confirmPassword: Yup.string()
                //   .required('Confirm Password is required')
                //   .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
                // acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required')
              });


  //const { open, handleClose } = props;

 const {  data:  veicolo,  isError: isLoadingVeicoloError, isFetching: isFetchingVeicolo, isLoading: isLoadingVeicolo, } = useGetVeicolo(id);

  const {  data: tipoVeicoloApiResponse ,isError: isLoadingTipoVeicoliError, isFetching: isFetchingTipoVeicoli, isLoading: isLoadingTipoVeicoli, } = useGetTipoVeicolo();

  const { mutateAsync: updateVeicolo, isPending: isUpdatingVeicolo } = useUpdateVeicolo();

  const { register,control, handleSubmit,reset, formState: { errors }} = useForm({resolver: yupResolver(validationSchema)});

  const [tipo, setTipo] = useState('');


    useEffect(() => {
      if (veicolo) {
                reset(veicolo)
              }
        }, [isLoadingVeicolo]);

 useEffect(() => {
       if (veicolo) {
             reset(veicolo);
             setTipo(veicolo?.tipo || '')
            };
        return () =>
        {
                  //    reset()
                        }
  }, [veicolo]);

  //const { control, handleSubmit, formState: { errors } } = useForm<VeicoloForm>({  mode: 'onChange' });
  //const { control, handleSubmit, formState: { errors } } = useForm<VeicoloForm>();

  //console.log({ ...register("camep") })

   const onSubmit = (data: Veicolo) => {
     // console.log(data);
      updateVeicolo(data);
       onClose();
   };

  //ATTENZIONE {...register("email")} https://www.freecodecamp.org/news/how-to-create-forms-in-react-using-react-hook-form/
 // Were using the spread operator so react-hook-form will spread out all
 // the required event handlers like onChange, onBlur, and other props for that input field.
 // If you add a console.log({ ...register("email") }); inside the component, you will see what it returns as can be seen below:

      const handleClose = () => {
            onClose();
          };


  return (

    // <Box sx={{mt: '100px' , ml:10, width: '75%'  }}>
   <Dialog onClose={onClose} open fullWidth={ true } maxWidth={"md"}>
      <DialogTitle>
        {" "}
        <Typography variant="h4">Modifica Veicolo</Typography>
      </DialogTitle>
      <DialogContent>
      </DialogContent>
      <DialogContent>
       <form onSubmit={handleSubmit(onSubmit)} >
           <Grid container direction="row"  spacing={1}>
                <Grid container direction="column"  sm={5} spacing={1}>
                     <Grid item >
                      <TextField
                        //required
                        id="id"
                       value={veicolo?.id}
                        label="Id"
                        fullWidth
                        margin="dense"
                        {...register('id')}
                        error={!!errors.id}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.id?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                        //required
                        id="camep"
                        //defaultValue={veicolo?.camep}
                       // name="camep"
                        label="Camep"
                      //   InputLabelProps={{ shrink: !!veicolo?.camep }} // conditionally shrink the label
                        fullWidth
                        margin="dense"
                        {...register('camep')}
                      //  {...register('camep', {onChange: (e) => e.target.value})}

                        error={!!errors.camep}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.camep?.message}
                      </Typography>
                    </Grid>
                     <Grid item >
                      <TextField
                          select
                          id="tipo"
                          label="Tipo"
                          fullWidth
                          value={tipo}
                          {...register('tipo')}
                          onChange={(event) => setTipo(event.target.value)}
                          error={!!errors.tipo}
                          >
                       {tipoVeicoloApiResponse?.results.map(({id, tipo}) => {
                                  return (
                                    <MenuItem key={tipo} value={tipo}>
                                     {tipo}
                                    </MenuItem>
                                  )
                        })}
                        </TextField>
                      <Typography variant="inherit" color="textSecondary">
                        {errors.tipo?.message}
                      </Typography>
                    </Grid>

                    <Grid item >
                      <TextField
                        required
                        id="marca"
                       // name="marca"
                        label="Marca"
                       fullWidth
                        margin="dense"
                        {...register('marca')}
                        // error={!!errors.marca}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.marca?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                        required
                        id="modello"
                       // name="modello"
                        label="Modello"
                        fullWidth
                        margin="dense"
                        {...register('modello')}
                        // error={!!errors.modello}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.modello?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                        required
                        id="anno"
                       // name="marca"
                        label="Anno"
                       fullWidth
                        margin="dense"
                        {...register('anno')}
                        // error={!!errors.anno}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.anno?.message}
                      </Typography>
                        </Grid>
                    <Grid item >
                      <TextField
                        required
                        id="targa"
                       // name="marca"
                        label="Targa"
                        fullWidth
                        margin="dense"
                        {...register('targa')}
                        // error={!!errors.targa}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.targa?.message}
                      </Typography>
                        </Grid>
                </Grid>
               <Grid container direction="column"  sm={1}  spacing={1} ></Grid>
               <Grid container direction="column"  sm={5}  spacing={1} >
                    <Grid item>
                      <TextField
                      // sx={{border: 'none',"& fieldset": { border: 'none' },}}
                        type="checkbox"
                       // required
                        id="ads"
                        label="Ads"
                        fullWidth
                        margin="dense"
                          {...register('ads')}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.ads?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                        type="checkbox"
                       // required
                        id="omasi"
                        label="Omasi"
                        fullWidth
                        margin="dense"
                          {...register('omasi')}
                        />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.omasi?.message}
                      </Typography>
                 </Grid>
                    <Grid item >
                       <TextField
                        type="checkbox"
                       // required
                        id="crs"
                        label="Crs"
                        fullWidth
                        margin="dense"
                          {...register('crs')}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.crs?.message}
                      </Typography>
                  </Grid>
                    <Grid item ></Grid>
                    <Grid item >
                      <TextField
                        type="date"
                        required
                        id="dataom"
                        label="Dataom"
                        fullWidth
                       // margin="dense"
                       // format="YYYY-MM-DD"
                        {...register('dataom')}
                      />
                      {/*<Typography variant="inherit" color="textSecondary">*/}
                      {/*  {errors.dataom?.message}*/}
                      {/*</Typography>*/}
                 </Grid>
                    <Grid item ></Grid>
                    <Grid item >
                     <TextField
                        type="date"
                        required
                        id="datais"
                        label="datais"
                        fullWidth
                       // format="dd/mm/yyyy"
                        {...register('datais')}

                      />
                      {/*<Typography variant="inherit" color="textSecondary">*/}
                      {/*  {errors.datais?.message}*/}
                      {/*</Typography>*/}
                 </Grid>

               </Grid>
           </Grid>
            <Grid container direction="row"  spacing={1}>
            {/*onClick={handleClose}*/}
                 <Grid item sm={6}/>
                 <Grid item sm={2}>
                    <Button color="primary" type="reset"  variant="outlined">
                      CANCELLA
                    </Button>
                 </Grid>
                  <Grid item sm={2}>
                        <Button variant="contained" onClick={handleClose} color="error" >CHIUDI</Button>
                 </Grid>
                 <Grid item sm={2}>
                    <Button  color="primary"   type="submit"  variant="contained"
                      disabled={isUpdatingVeicolo}
                      startIcon={
                        isUpdatingVeicolo ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : null
                      }
                    >
                    SALVA
                    </Button>
                 </Grid>

           </Grid>
      </form>
      </DialogContent>

      <DialogActions>
        {/*<Button variant="contained" onClick={handleClose}>Close</Button>*/}
        {/*<Button variant="contained" color="error">*/}
        {/*  Yes*/}
        {/*</Button>*/}
      </DialogActions>
    </Dialog>

 // </Box>
  );
};


// }
export default VeicoliEdit;