import { useState } from "react";
import {Box, Button, IconButton, Tooltip} from "@mui/material";
import RicevuteEdit from "./RicevuteEdit";

const RicevuteOpenDia  = ()  => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [isDialogEditOpen, setIsDialogEditOpen] = useState(false);

  const openCreateModal = () => {
        setIsDialogEditOpen(true);
  };

  console.log("**********RENDER DIA**********");



    return (
    <Box sx={{ mt: "100px", ml: 10, width: "75%" }}>
      <Button onClick={openCreateModal}>CLICCA</Button>
      {/*{isDialogOpen && <RicevuteDia onClose={()  => setIsDialogOpen(false)} />}*/}
       {isDialogEditOpen && <RicevuteEdit id={12010} onClose={()  => setIsDialogEditOpen(false)} />}
       {/*{isDialogOpen && <RicevuteEdit onClose={()  => setIsDialogOpen(false)} />}*/}
    </Box>
        );
};

export default RicevuteOpenDia;