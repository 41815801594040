import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
  Ricevuta,
  RicevutaForm,
  RicevuteApiResponse, RicevuteDTOApiResponse,
  RicevuteProgApiResponse, SociListaDTOApiResponse, TipoContributoApiResponse,
  TipoIscrizioneApiResponse,
  TipoPagApiResponse
} from "./types";
import AxiosInstance from "../../components/Axios";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import {ComuniApiResponse, SociApiResponse} from "../soci/types";
import {MRT_ColumnFiltersState, MRT_SortingState} from "material-react-table";

const moment = require('moment')

export function useGetRicevuta(id: number = 0) {
  return useQuery<Ricevuta>({
    queryKey: ['fetchRicevuta'],
    queryFn: async () => {
     // console.log("GETRICEVUTA ID:"+id)
      const response = await AxiosInstance.get('ricevute/'+id+'/');
      const json = (response.data.data.ricevuta) as Ricevuta;
     //console.log(json)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}


export function useUpdateRicevuta() {
  const queryClient = useQueryClient();
  return useMutation({


    mutationFn: async (ricevuta: Ricevuta) => {
      console.log("RICEVUTA:",ricevuta)
        ricevuta.dataricevuta = moment(ricevuta.dataricevuta).format('YYYY-MM-DD')
        // ricevuta.datainvioemail = moment(ricevuta.dataricevuta).format('YYYY-MM-DD HH:MM:SS')
        return AxiosInstance.patch('ricevute/'+ricevuta.id+'/',ricevuta);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['fetchRicevute'] }), //refetch ricevute after mutation, disabled for demo
  });
}





export function useCreateRicevuta() {
  const queryClient = useQueryClient();
  const moment = require('moment')

  return useMutation({
    mutationFn: async (ricevutaForm: RicevutaForm) => {
      ricevutaForm.dataricevuta = moment(ricevutaForm.dataricevuta).format('YYYY-MM-DD')
      // console.log(ricevutaForm)
      return AxiosInstance.post('ricevute/0/',ricevutaForm);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['fetchRicevute'] }),
     });
}

export function useGetRicevuteProg() {
  return useQuery<RicevuteProgApiResponse>({
    queryKey: ['fetchRicevuteProg'],
    queryFn: async () => {
      const response = await AxiosInstance.get('ricevuteprog/?limit=10&offset=0');
      const json = (response.data) as RicevuteProgApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetTipoPag() {
  return useQuery<TipoPagApiResponse>({
    queryKey: ['fetchTipoPag'],
    queryFn: async () => {
      const response = await AxiosInstance.get('tipopag/?limit=10&offset=0');
      const json = (response.data) as TipoPagApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetTipoIscrizione() {
  return useQuery<TipoIscrizioneApiResponse>({
    queryKey: ['fetchTipoIscrizione'],
    queryFn: async () => {
      const response = await AxiosInstance.get('tipoiscrizione/?limit=10&offset=0');
      const json = (response.data) as TipoIscrizioneApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetTipoContributo() {
  return useQuery<TipoContributoApiResponse>({
    queryKey: ['fetchTipoContributo'],
    queryFn: async () => {
      const response = await AxiosInstance.get('tipocontributo/?offset=0&limit=50');
      const json = (response.data) as TipoContributoApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetSociListaDTO() {
  return useQuery<SociListaDTOApiResponse>({
    queryKey: ['fetchSociListaDTO'],
    queryFn: async () => {
      const response = await AxiosInstance.get('socilistadto/?offset=0&limit=10000');
      const json = (response.data) as SociListaDTOApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}








export function useGetRicevuteDTOExp(columnFilters:  MRT_ColumnFiltersState,globalFilter: string,sorting: MRT_SortingState) {
  return useQuery<RicevuteDTOApiResponse>({
    queryKey: ['fetchRicevuteDTOExp',
       columnFilters, //refetch when columnFilters changes
       globalFilter, //refetch when globalFilter changes
       sorting, //refetch when sorting changes
    ],
    queryFn: async () => {

      const myURL = '?limit=100000&offset=0'+'&search='+(globalFilter ?? '')+columnFiltersParam(columnFilters)+'&ordering='+sortingParam(sorting);
      // console.log('FROMEXPORTER',myURL);
      const response = await AxiosInstance.get('ricevutedto/'+myURL);
      const json = (response.data) as SociApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

//mp conversione  filter parameters per django rest api
function columnFiltersParam(columnFilters: MRT_ColumnFiltersState) {
  var loopData = ''
  var i;
  for (i = 0; i < columnFilters.length; i++) {
    loopData += "&"
    loopData += `${columnFilters[i].id}`+"="+`${columnFilters[i].value}`
    //if (i < columnFilters.length-1)
    //  loopData += "&"
  }
  //console.log(loopData);
  //const columnFiltersParam = `${encodeURIComponent(loopData)}`;
  return loopData
}

function sortingParam(sorting: MRT_SortingState) {
  let ordering = '';
  for (let i = 0; i < sorting.length; i++) {
    const { id, desc } = sorting[i];
    ordering += desc ? `${id},` : `-${id},`;
  }
  // Remove the trailing comma
  ordering = ordering.slice(0, -1);
  return ordering;
}




