/* eslint-disable react/jsx-wrap-multilines */
import React, {useState} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Button,
    TextField,
    CircularProgress, DialogTitle, DialogActions, Dialog, DialogContent
} from '@mui/material';
import {Box, Grid, ListItemText, MenuItem, Typography} from "@mui/material";
import {TipoVeicoloApiResponse, VeicoloForm} from "./types"
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useCreateVeicolo, useGetTipoVeicolo} from "./Services";


interface VeicoliAddProps {
  onClose: () => void;
}

// const VeicoliAdd  = ()  => {
const VeicoliAdd: React.FC<VeicoliAddProps> = ({ onClose }) => {

  const validationSchema = Yup.object().shape({
                camep: Yup.number().required('Camep è obbligatorio').typeError('Camep è obbligatorio e un numero'),
                tipo: Yup.string().required('Tipo è obbligatorio'),
                marca :Yup.string().typeError(''),
                 modello :Yup.string(),
                 anno :Yup.number(),
                 targa:Yup.string(),
                 ads :Yup.boolean(),
                 crs :Yup.boolean(),
                 datais : Yup.date(),
                 dataom : Yup.date(),
                 omasi :Yup.boolean(),
                //  username: Yup.string()
                //   .required('Username is required')
                //   .min(6, 'Username must be at least 6 characters')
                //   .max(20, 'Username must not exceed 20 characters'),
                // email: Yup.string()
                //   .required('Email is required')
                //   .email('Email is invalid'),
                // password: Yup.string()
                //   .required('Password is required')
                //   .min(6, 'Password must be at least 6 characters')
                //   .max(40, 'Password must not exceed 40 characters'),
                // confirmPassword: Yup.string()
                //   .required('Confirm Password is required')
                //   .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
                // acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required')
              });

  const { mutateAsync: createVeicolo, isPending: isCreatingVeicolo } = useCreateVeicolo();

  const {  data: tipoVeicoloApiResponse ,isError: isLoadingVeicoliError, isFetching: isFetchingVeicoli, isLoading: isLoadingVeicoli, } = useGetTipoVeicolo();

  const { register,control, handleSubmit, formState: { errors }} = useForm({ resolver: yupResolver(validationSchema)})

   const onSubmit = (data: VeicoloForm) => {
      //console.log(data);
      createVeicolo(data);
      onClose();
   };

    const handleClose = () => {
            onClose();
          };

  return (


 // <Box sx={{mt: '100px' , ml:10, width: '75%'  }}>
    <Dialog onClose={onClose} open fullWidth={ true } maxWidth={"md"}>
      <DialogTitle>
        {" "}
        <Typography variant="h4">Nuovo Veicolo</Typography>
      </DialogTitle>
      <DialogContent>
      </DialogContent>
      <DialogContent>
       <form onSubmit={handleSubmit(onSubmit)} >
           <Grid container direction="row"  spacing={1}>
                <Grid container direction="column"  sm={5} spacing={1}>
                    <Grid item >
                      <TextField
                        //required
                        id="camep"
                       // name="camep"
                        label="Camep"
                        fullWidth
                        margin="dense"
                        {...register('camep')}
                        //error={!!errors.camep}

                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.camep?.message}
                      </Typography>
                    </Grid>
                     <Grid item >
                      <TextField
                          select
                          id="tipo"
                          label="Tipo"
                          fullWidth
                        // helperText="Seleziona"
                          {...register('tipo')}
                          error={!!errors.tipo}
                          >
                       {tipoVeicoloApiResponse?.results.map(({tipo}) => {
                                  return (
                                    <MenuItem key={tipo} value={tipo}>
                                     {tipo}
                                    </MenuItem>
                                  )
                        })}
                        </TextField>
                      <Typography variant="inherit" color="textSecondary">
                        {errors.tipo?.message}
                      </Typography>

                    </Grid>
                    <Grid item >
                      <TextField
                        required
                        id="marca"
                       // name="marca"
                        label="Marca"
                       fullWidth
                        margin="dense"
                        {...register('marca')}
                        // error={!!errors.marca}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.marca?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                        required
                        id="modello"
                       // name="modello"
                        label="Modello"
                        fullWidth
                        margin="dense"
                        {...register('modello')}
                        // error={!!errors.modello}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.modello?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                        required
                        id="anno"
                       // name="marca"
                        label="Anno"
                       fullWidth
                        margin="dense"
                        {...register('anno')}
                        // error={!!errors.anno}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.anno?.message}
                      </Typography>
                        </Grid>
                    <Grid item >
                      <TextField
                        required
                        id="targa"
                       // name="marca"
                        label="Targa"
                        fullWidth
                        margin="dense"
                        {...register('targa')}
                        // error={!!errors.targa}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.targa?.message}
                      </Typography>
                        </Grid>
                </Grid>
               <Grid container direction="column"  sm={1}  spacing={1} ></Grid>
               <Grid container direction="column"  sm={5}  spacing={1} >
                    <Grid item>
                      <TextField
                    //   sx={{border: 'none',"& fieldset": { border: 'none' },}}
                        type="checkbox"
                      //  required
                        id="ads"
                       // name="camep"
                        label="Ads"
                        fullWidth
                        margin="dense"
                          {...register('ads')}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.ads?.message}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <TextField
                        type="checkbox"
                    //    required
                        id="omasi"
                       // name="tipo"
                        label="Omasi"
                        fullWidth
                        margin="dense"
                          {...register('omasi')}
                        />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.omasi?.message}
                      </Typography>
                 </Grid>
                    <Grid item >
                      <TextField
                         type="checkbox"
                        //required
                        id="crs"
                       // name="crs"
                        label="Crs"
                        fullWidth
                        margin="dense"
                          {...register('crs')}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.crs?.message}
                      </Typography>
                  </Grid>
                    <Grid item ></Grid>
                    <Grid item >
                      <TextField
                        type="date"
                        required
                        id="dataom"
                       // name="tipo"
                        label="Dataom"
                        fullWidth
                       // margin="dense"
                        //format="dd/mm/yyyy"
                        {...register('dataom')}
                      />
                      {/*<Typography variant="inherit" color="textSecondary">*/}
                      {/*  {errors.dataom?.message}*/}
                      {/*</Typography>*/}
                 </Grid>
                    <Grid item ></Grid>
                    <Grid item >
                     <TextField
                         type="date"
                        required
                        id="datais"
                      // name="tipo"
                        label="datais"
                        fullWidth
                       // format="dd/mm/yyyy"
                         {...register('datais')}
                      />
                      {/*<Typography variant="inherit" color="textSecondary">*/}
                      {/*  {errors.datais?.message}*/}
                      {/*</Typography>*/}
                 </Grid>

               </Grid>
           </Grid>
            <DialogContent>
            </DialogContent>
           <Grid container direction="row"  spacing={1}>
            {/*onClick={handleClose}*/}
                 <Grid item sm={6}/>
                 <Grid item sm={2}>
                    <Button color="primary" type="reset"  variant="outlined">
                      CANCELLA
                    </Button>
                 </Grid>
                  <Grid item sm={2}>
                        <Button variant="contained" onClick={handleClose} color="error" >CHIUDI</Button>
                 </Grid>
                 <Grid item sm={2}>
                    <Button  color="primary"   type="submit"  variant="contained"
                      disabled={isCreatingVeicolo}
                      startIcon={
                        isCreatingVeicolo ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : null
                      }
                    >
                    SALVA
                    </Button>
                 </Grid>

           </Grid>
      </form>
      </DialogContent>

      <DialogActions>
        {/*<Button variant="contained" onClick={handleClose}>Close</Button>*/}
        {/*<Button variant="contained" color="error">*/}
        {/*  Yes*/}
        {/*</Button>*/}
      </DialogActions>
    </Dialog>

 // </Box>
  );
};


// }
export default VeicoliAdd;