import { useMemo, useState } from 'react';
import {
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  MRT_EditActionButtons,
  MaterialReactTable,
  MRT_EditCellTextField,
  createRow,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  useMaterialReactTable, MRT_SelectCheckbox,
} from 'material-react-table';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Dialog,
    DialogTitle, Grid,
    IconButton, TextField,
    Tooltip, Typography,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AxiosInstance from '../../components/Axios';
import {TipoGruppo} from "./types";
import {TipoGruppoApiResponse} from "./types";



const Gruppi = () => {
  const [validationErrors, setValidationErrors] = useState< Record<string, string | undefined>>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([],);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({pageIndex: 0, pageSize: 15, });

 //const navigate = useNavigate();

  const columns = useMemo<MRT_ColumnDef<TipoGruppo>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 10,
        enableEditing: false,

      },
      {
        accessorKey: 'tipo',
        header: 'Gruppo',
        size: 50,
        enableEditing: true,
        // muiEditTextFieldProps: { required: true},

      },
    ],
    [validationErrors],
  );

  //call CREATE hook
  const { mutateAsync: createTipoGruppo, isPending: isCreatingTipoGruppo } = useCreateTipoGruppo();
  //call READ hook
  const {  data: { results = [], count } = {} ,isError: isLoadingTipoGruppoError, isFetching: isFetchingTipoGruppo, isLoading: isLoadingTipoGruppo, } = useGetTipoGruppo(pagination,columnFilters,globalFilter,sorting);
  //call UPDATE hook
  const { mutateAsync: updateTipoGruppo, isPending: isUpdatingTipoGruppo } = useUpdateTipoGruppo();
  //call DELETE hook
  const { mutateAsync: deleteTipoGruppo, isPending: isDeletingTipoGruppo } = useDeleteTipoGruppo();


  //CREATE action
  const handleCreateTipoGruppo: MRT_TableOptions<TipoGruppo>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateTipoGruppo(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createTipoGruppo(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveTipoGruppo: MRT_TableOptions<TipoGruppo>['onEditingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateTipoGruppo(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateTipoGruppo(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  // const openDeleteConfirmModal = (row: MRT_Row<TipoGruppo>) => {
  //   if (window.confirm('ATTENZIONE !!! CANCELLAZIONE DEL TIPO CONTRIBUTO " '+row.original')) {
  //     deleteTipoGruppo(row.original.id ? row.original.id : 0 );
  //   }
  // };

  const openDeleteConfirmModal = (row: MRT_Row<TipoGruppo>) => {
    if (window.confirm('TTENZIONE !!! CANCELLAZIONE DEL TIPO CONTRIBUTO !!')) {
      deleteTipoGruppo(row.original.id ? row.original.id : 0);
    }
  };



  const table = useMaterialReactTable({
    columns,
    data: results,
    initialState: { density: 'compact' },

    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
    manualSorting: true, //turn off built-in client-side sorting

    createDisplayMode: 'row', //default ('row', and 'custom' 'modal' are also available)
    editDisplayMode: 'row', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,

    //getRowId: (row) => row.id.toString(),

    getRowId: (row) => (row.id ? row.id.toString() : ''),



    muiToolbarAlertBannerProps: isLoadingTipoGruppoError ? {
          color: 'error',
          children: 'Error loading data',
        } : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    // muiEditRowDialogProps: {
    //    fullWidth: true,
    //    maxWidth: 'md',
    //  },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    //onSortingChange: setSorting,
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateTipoGruppo,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveTipoGruppo,

     renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
         <>
        <DialogTitle variant="h3">Crea Nuovo Gruppo</DialogTitle>
        <DialogContent
           sx={{ width: '1000px'}}
        >
          {internalEditComponents} {/* or render custom edit components here */}

        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>

       </>
    ),


   renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Edit TipoGruppo</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),

    renderRowActions: ({ row, table }) => (
       <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),


    renderTopToolbarCustomActions: ({ table }) => (
      <>

        <Button
            variant="contained"
            onClick={() => {
              table.setCreatingRow(true); //simplest way to open the create row modal with no default values
              //or you can pass in a row object to set default values with the `createRow` helper function
              // table.setCreatingRow(
              //   createRow(table, {
              //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
              //   }),
              // );
            }}
         >
        Nuovo  Gruppo
      </Button>

    </>
     ),

    //rowCount: meta?.totalRowCount ?? 0,
    rowCount: count ?? 0,

    state: {
      isLoading: isLoadingTipoGruppo,
      isSaving: isCreatingTipoGruppo || isUpdatingTipoGruppo || isDeletingTipoGruppo,
      showAlertBanner: isLoadingTipoGruppoError,
      showProgressBars: isFetchingTipoGruppo,

      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },
  });



return    <Box sx={{mt: '60px' // , width: '1376px'
        }}><MaterialReactTable table={table} /> </Box>;
};




//CREATE hook (post new tipogruppo to api)
function useCreateTipoGruppo() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (tipogruppo: TipoGruppo) => {
      //send api update request here
      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
      return AxiosInstance.post('tipogruppo/0/',tipogruppo);
    },
    //client side optimistic update
    //  onMutate: (newTipoGruppoInfo: TipoGruppo) => {
    //    queryClient.setQueryData(
    //      ['tipogruppo'],
    //      (prevTipoGruppo: any) =>
    //        [
    //          ...prevTipoGruppo,
    //          {
    //            ...newTipoGruppoInfo,
    //            id: (Math.random() + 1).toString(36).substring(7),
    //          },
    //        ] as TipoGruppo[],
    //    );
    //   },
    //  onSettled: () => queryClient.invalidateQueries({ queryKey: ['tipogruppo'] }), //refetch tipogruppo after mutation, disabled for demo
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['fetchTipoGruppo'] }), //refetch tipogruppo after mutation, disabled for demo
     });
}

//READ hook (get tipogruppo from api)
function useGetTipoGruppo(pagination: MRT_PaginationState,columnFilters:  MRT_ColumnFiltersState,globalFilter: string,sorting: MRT_SortingState) {
  return useQuery<TipoGruppoApiResponse>({
    queryKey: ['fetchTipoGruppo',
       columnFilters, //refetch when columnFilters changes
       globalFilter, //refetch when globalFilter changes
       pagination.pageIndex, //refetch when pagination.pageIndex changes
       pagination.pageSize, //refetch when pagination.pageSize changes
       sorting, //refetch when sorting changes
    ],
    queryFn: async () => {

      const myOffset = (pagination.pageIndex==0) ? "0": `${pagination.pageSize*(pagination.pageIndex)}`;

      //const fetchURL = new URL("tipogruppo/","/");
      //fetchURL.searchParams.set('limit',`${pagination.pageSize}`,);
      //fetchURL.searchParams.set('offset', myOffset);
      //fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      // fetchURL.searchParams.set('',columnFiltersParam(columnFilters));
      //fetchURL.searchParams.set('search', globalFilter ?? '');
      //fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));
      const myURL = '?limit='+`${pagination.pageSize}`+'&offset='+myOffset+'&search='+(globalFilter ?? '')+columnFiltersParam(columnFilters)+'&ordering='+sortingParam(sorting);
      //console.log(myURL);
      const response = await AxiosInstance.get('tipogruppo/'+myURL);
      const json = (response.data) as TipoGruppoApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

function useGetTipoTipoGruppo() {
  return useQuery<TipoGruppoApiResponse>({
    queryKey: ['fetchTipoTipoGruppo'],
    queryFn: async () => {
      const response = await AxiosInstance.get('tipotipogruppo/');
      const json = (response.data) as TipoGruppoApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}


//mp conversione  filter parameters per django rest api
function columnFiltersParam(columnFilters: MRT_ColumnFiltersState) {
  var loopData = ''
  var i;
  for (i = 0; i < columnFilters.length; i++) {
    loopData += "&"
    loopData += `${columnFilters[i].id}`+"="+`${columnFilters[i].value}`
    //if (i < columnFilters.length-1)
    //  loopData += "&"
  }
  //console.log(loopData);
  //const columnFiltersParam = `${encodeURIComponent(loopData)}`;
  return loopData
}

function sortingParam(sorting: MRT_SortingState) {
  let ordering = '';
  for (let i = 0; i < sorting.length; i++) {
    const { id, desc } = sorting[i];
    ordering += desc ? `${id},` : `-${id},`;
  }
  // Remove the trailing comma
  ordering = ordering.slice(0, -1);
  return ordering;
}

//UPDATE hook (put tipogruppo in api)
function useUpdateTipoGruppo() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (tipogruppo: TipoGruppo) => {
      //send api update request here
      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return AxiosInstance.patch('tipogruppo/'+tipogruppo.id+'/',tipogruppo);
      // return Promise.resolve();
    },
    //client side optimistic update
    // onMutate: (newTipoGruppoInfo: TipoGruppo) => {
    //   queryClient.setQueryData(
    //     ['tipogruppo'],
    //     (prevTipoGruppo: any) =>
    //       prevTipoGruppo?.map((prevTipoGruppo: TipoGruppo) =>
    //         prevTipoGruppo.id === newTipoGruppoInfo.id ? newTipoGruppoInfo : prevTipoGruppo,
    //       ),
    //   );
    // },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['tipogruppo'] }), //refetch tipogruppo after mutation, disabled for demo
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['fetchTipoGruppo'] }), //refetch tipogruppo after mutation, disabled for demo

  });
}

//DELETE hook (delete tipogruppo in api)
function useDeleteTipoGruppo() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      //send api update resquest here
      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      // return Promise.resolve();
      const response = await AxiosInstance.delete('tipogruppo/'+id+'/');
     return  response
    },
    //client side optimistic update
    // onMutate: (id: string) => {
    //   queryClient.setQueryData(
    //     ['tipogruppo'],
    //     (prevTipoGruppo: any) =>
    //       prevTipoGruppo?.filter((tipogruppo: TipoGruppo) => tipogruppo.id !== id),
    // );
    //},
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['fetchTipoGruppo'] }), //refetch tipogruppo after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

// const TipoGruppoWithProviders = () => (
//   //Put this with your other react-query providers near root of your app
//   <QueryClientProvider client={queryClient}>
//     <TipoGruppo />
//   </QueryClientProvider>
// );

// export default TipoGruppoWithProviders;

export default Gruppi;


const validateRequired = (value: string) => !!value.length;
const validateRequiredNum = (value: number) => !!value.toString().length;
const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

function validateTipoGruppo(tipogruppo: TipoGruppo) {
  return {
  //  camep: !validateRequiredNum(tipogruppo.camep)  ? 'Camep is Required' : '',
  //   tipo: !validateRequired(tipogruppo.tipo) ? 'Tipo is Required' : '',

  };
}
