import { Veicolo } from "./types";
import {Checkbox, Button, FormControlLabel, Typography, Dialog, DialogTitle, DialogContent, Grid} from "@mui/material";
import * as XLSX from "xlsx";
import React, {useState} from "react";
import {MRT_ColumnFiltersState, MRT_SortingState} from "material-react-table";
import {useGetVeicoliExp} from "./Services";
import {download, generateCsv, mkConfig} from "export-to-csv";
import VeicoliAdd from "./VeicoliAdd";

interface VeicoliExpProps {
  columnFilters: MRT_ColumnFiltersState;
  globalFilter: string;
  sorting: MRT_SortingState;
  onClose: () => void;
}



const VeicoliExp: React.FC<VeicoliExpProps> = ({ columnFilters, globalFilter, sorting,onClose }) => {

  const veicoloFields = [
    "id",
    "camep",
    "tipo",
    "marca",
    "modello",
    "anno",
    "targa",
    "ads",
    "crs",
    "datais",
    "dataom",
    "omasi"
  ];

const {  data: { results = [], count } = {}  } = useGetVeicoliExp(columnFilters,globalFilter,sorting);


const [selectedFields, setSelectedFields] = useState<string[]>(veicoloFields);

 const generateExcelData = () => {
  const data = results.map((veicolo) => {
    const rowData: any = {};
    selectedFields.forEach((field) => {
      rowData[field] = veicolo[field as keyof Veicolo] || ''; // Assert the field as keyof Veicolo
    });
    return rowData;
  });
  return data;
};

 const handleFieldToggle = (field: string) => {
    if (selectedFields.includes(field)) {
      setSelectedFields(selectedFields.filter((item) => item !== field));
    } else {
      setSelectedFields([...selectedFields, field]);
    }
  };
const handleExportExcel = () => {
    const excelData = generateExcelData();
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "VeicoliExcel");
    XLSX.writeFile(workbook, "veicoli.xlsx");
  };

const csvConfig = mkConfig({
     filename: 'veicoli',
      fieldSeparator: ';',
      decimalSeparator: ',',
      useKeysAsHeaders: true,
      showColumnHeaders: true
    });

  const handleExportCSV = () => {
   const selectedData = results.map((veicolo) => {
    const rowData: any = {};
    selectedFields.forEach((field) => {
      rowData[field] = veicolo[field as keyof Veicolo] || ''; // Assert the field as keyof Veicolo
    });
    return rowData;
   });

  const csv = generateCsv(csvConfig)(selectedData);
  download(csvConfig)(csv);
};

 const handleSelectAll = () => {
    setSelectedFields(veicoloFields);
  };

 const handleDeSelectAll = () => {
    setSelectedFields([]);
  };



  const handleClose = () => {
            onClose();
          };

  return (
            <Dialog onClose={onClose} open fullWidth maxWidth="md">
              <DialogContent>
                <h4>Seleziona Campi da esportare:</h4>
                <form>
                  <Grid container direction="row" spacing={1}>
                    {veicoloFields.map((field) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={field}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedFields.includes(field)}
                              onChange={() => handleFieldToggle(field)}
                            />
                          }
                          label={field}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </form>
              </DialogContent>
              <DialogContent>

  <Grid container direction="row" spacing={1}>
  <Grid item xs={12} sm={4} md={2.4}>
    <Button
      fullWidth
      color="primary"
      variant="outlined"
      onClick={handleSelectAll}
    >
      SELEZIONA TUTTI
    </Button>
  </Grid>
  <Grid item xs={12} sm={4} md={2.4}>
    <Button
      fullWidth
      color="primary"
      variant="outlined"
      onClick={handleDeSelectAll}
    >
      DESELEZ. TUTTI
    </Button>
  </Grid>
  <Grid item xs={12} sm={4} md={2.4}>
    <Button
      fullWidth
      color="primary"
      type="submit"
      variant="contained"
      onClick={handleExportCSV}
    >
      EXPORT TO CSV
    </Button>
  </Grid>
  <Grid item xs={12} sm={4} md={2.4}>
    <Button
      fullWidth
      color="primary"
      type="submit"
      variant="contained"
      onClick={handleExportExcel}
    >
      EXPORT TO EXCEL
    </Button>
   </Grid>
   <Grid item xs={12} sm={4} md={2.4}>
    <Button fullWidth variant="contained" onClick={handleClose} color="error">
      CHIUDI
    </Button>
  </Grid>

</Grid>
  </DialogContent>
</Dialog>

  );
};

export default VeicoliExp;

