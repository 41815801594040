import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Login from './pages/auth/Login';
import {QueryClientProvider,QueryClient} from "@tanstack/react-query";
import {LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import it from 'date-fns/locale/it';
import {createTheme, ThemeProvider,} from "@mui/material";
import RicevuteOpenDia from "./pages/ricevute/RicevuteOpenDia";
import RicevutePdf from "./pages/ricevute/RicevutePdf";
import RicevutePivot from "./pages/ricevute/RicevutePivot";
import VeicoliExp from "./pages/veicoli/VeicoliExp";
import Home from "./pages/home/Home";
import Sidebar from "./components/Sidebar";
import {Logout} from "./pages/auth/Logout";



const myTheme = createTheme({
  components: {
    // MuiInputLabel: {
    //    defaultProps: { shrink: true },
    //   },
    MuiTextField: {
       defaultProps: { InputLabelProps: { shrink: true } },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& label': {
            fontSize: 16,
          },
        },
        input: {
          fontSize: 16,
        },
        listbox: {
           fontSize: 12,

        },
      },
    },
   },
  });


// const [isAuth, setIsAuth] = useState(false);
//
//   useEffect(() => {
//     if (localStorage.getItem('access_token') !== null) {
//       setIsAuth(true);
//     }
//   }, [isAuth]);
//
// //  {isAuth ?  <Nav.Link href="/">Home</Nav.Link>  : null}


const queryClient = new QueryClient()
function App() {


  return (
    <>
    <ThemeProvider theme={myTheme}>
     <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
      <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          {/* <Route
            path='/'
            element={
              isLoggedIn ? (hasSelectedCompany ? (<Home />) : (<Navigate to='/company' />)) : (<Login />)
            }
          /> */}
          {/*<Route path='*' element={isLoggedIn ? <Home /> : <Login />} />*/}
          <Route path="*" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/home" element={<Home />} />
          <Route path="/app/*" element={<Sidebar  open={open} />} />
          <Route path="/app/ricevuteopendiatest" element={<RicevuteOpenDia />} />
          <Route path="/app/ricevutepdftest" element={<RicevutePdf />} />
          {/*<Route path="/app/ricevutepivottest" element={<RicevutePivot />} />*/}
          {/*<Route path="/app/veicoli/exporter" element={<Exporter />} />*/}
          {/*<Route path="/app/ricevutejasper" element={<RicevuteJasper />} />*/}

          {/*<Route path="/app/veicoli" element={<Veicoli />} />*/}
          {/*<Route path="/app/soci" element={<Soci />} />*/}

            {/*<Route path='/login' element={<Login />} />*/}
          {/* <Route path="/" element={<Navigate to="/veicolo" replace />} /> */}
          {/* <Route
            path='/'
            element={isLoggedIn ? <Home /> : <Login />
            // <Navigate to='/login' />
          }
          >
          </Route> */}
        </Routes>
      </Router>
      </QueryClientProvider>
     </LocalizationProvider>
     </ThemeProvider>
    </>
  );
}

export default App;
