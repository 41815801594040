import VeicoliAdd from "./VeicoliAdd";
import VeicoliEdit from "./VeicoliEdit";
import { useMemo, useState } from 'react';
import {
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  MRT_EditActionButtons,
  MaterialReactTable,
  MRT_EditCellTextField,
  createRow,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  useMaterialReactTable, MRT_SelectCheckbox,
} from 'material-react-table';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Dialog,
    DialogTitle, Grid,
    IconButton, TextField,
    Tooltip, Typography,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AxiosInstance from '../../components/Axios';
import {useNavigate} from "react-router-dom";
import {TipoVeicoloApiResponse, VeicoliApiResponse, Veicolo} from "./types";
import {Ricevuta} from "../ricevute/types";
import RicevuteEdit from "../ricevute/RicevuteEdit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {download, generateCsv, mkConfig} from "export-to-csv";
import * as XLSX from "xlsx";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import VeicoliExp from "./VeicoliExp";


const Veicoli = () => {
  const [validationErrors, setValidationErrors] = useState< Record<string, string | undefined>>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([],);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({pageIndex: 0, pageSize: 15, });


 const generateExcelData = () => {
  const data = results.map((veicolo) => ({
     id:	        veicolo.id	|| '',
       camep:	        veicolo.id	|| '',
        tipo:	        veicolo.id	|| '',
        marca:	        veicolo.id	|| '',
        modello:	        veicolo.id	|| '',
        anno:	        veicolo.id	|| '',
        targa:	        veicolo.id	|| '',
        ads:	        veicolo.id	? 'X' : '',
        crs:	        veicolo.id	? 'X' : '',
        datais:	        veicolo.id	|| '',
      omasi:	        veicolo.id	? 'X' : '',
      dataom:	        veicolo.id	|| '',
      annois:	        veicolo.id	|| '',
   }));

    return data;
   };









 //const navigate = useNavigate();

  const columns = useMemo<MRT_ColumnDef<Veicolo>[]>(
    () => [
      // {
      //   accessorKey: 'id',
      //   header: 'Id',
      //   size: 20,
      //   enableEditing: false,
      //
      // },
      {
        accessorKey: 'camep',
        header: 'Camep',
        size: 50,
        enableEditing: true,
        muiEditTextFieldProps: { required: true, type: 'number'},

      },
      {
        accessorKey: 'tipo',
        header: 'Tipo',
        size: 50,
        enableEditing: true,
        muiEditTextFieldProps: { required: true},
      },
      {
        accessorKey: 'marca',
        header: 'Marca',
        enableEditing: true,
        size: 50,
     },
      {
        accessorKey: 'modello',
        header: 'Modello',
        enableEditing: true,
        size: 50,
    },
      {
        accessorKey: 'anno',
        header: 'Anno',
        enableEditing: true,
        size: 10,
       // editVariant: 'select',
       // editSelectOptions: useGetTipoVeicolo,
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.state,
          helperText: validationErrors?.state,
        },

    },
      {
        accessorKey: 'targa',
        header: 'Targa',
        enableEditing: true,
        size: 10,
    },
      {
        accessorKey: 'ads',
        header: 'Ads',
        enableEditing: false,
        size: 10,
        type: 'boolean',
        Cell: ({cell}) => (
                cell.getValue<boolean>() && (
                    <Box>
                        <CheckBoxOutlinedIcon />
                    </Box>

                )
            ),


    },
      {
        accessorKey: 'crs',
        header: 'Crs',
        enableEditing: false,
        size: 10,
        type: 'boolean',
        Cell: ({cell}) => (
                cell.getValue<boolean>() && (
                    <Box>
                        <CheckBoxOutlinedIcon />
                    </Box>
                )
            ),
    },
      {
        accessorKey: 'datais',
        header: 'datais',
        enableEditing: false,
        size: 20,
       // filterVariant: 'date',
         Cell: ({ cell }) => {
            const value = cell.getValue();
            if (typeof value === 'string' || typeof value === 'number') {
                const date = new Date(value);
                return date.toLocaleDateString('it-IT'); // Change 'it-IT' to your desired locale
            } else {
                // Handle unexpected value types
                return "Nessuna Data";
            }
            }
    },
      {
        accessorKey: 'omasi',
        header: 'Omasi',
        enableEditing: false,
        type: 'boolean',
        size: 10,
        Cell: ({cell}) => (
                cell.getValue<boolean>() && (
                    <Box>
                        <CheckBoxOutlinedIcon />
                    </Box>
                )
            ),
    },
      {
        accessorKey: 'dataom',
        header: 'Dataom',
        enableEditing: false,
        size: 20,
       // filterVariant: 'date',
         Cell: ({ cell }) => {
            const value = cell.getValue();
            if (typeof value === 'string' || typeof value === 'number') {
                const date = new Date(value);
                return date.toLocaleDateString('it-IT'); // Change 'it-IT' to your desired locale
            } else {
                // Handle unexpected value types
                return "Nessuna Data";
            }
            }

    },
    //   {
    //     accessorKey: 'createdAt',
    //     header: 'CreatedAt',
    //     enableEditing: false,
    //     size: 20,
    // },
    //   {
    //     accessorKey: 'updatedAt',
    //     header: 'UpdatedAt',
    //     enableEditing: false,
    //     size: 20,
    // },
    ],
    [validationErrors],
  );

  //call CREATE hook
  const { mutateAsync: createVeicolo, isPending: isCreatingVeicolo } = useCreateVeicolo();
  //call READ hook
  const {  data: { results = [], count } = {} ,isError: isLoadingVeicoliError, isFetching: isFetchingVeicoli, isLoading: isLoadingVeicoli, } = useGetVeicoli(pagination,columnFilters,globalFilter,sorting);
  //call UPDATE hook
  const { mutateAsync: updateVeicolo, isPending: isUpdatingVeicolo } = useUpdateVeicolo();
  //call DELETE hook
  const { mutateAsync: deleteVeicolo, isPending: isDeletingVeicolo } = useDeleteVeicolo();
  //new call Edit hook
  const [isDialogEditOpen, setIsDialogEditOpen] = useState(false);
  const [currentRowId, setCurrentRowId] = useState('0');
  //new call Add hook
  const [isDialogAddOpen, setIsDialogAddOpen] = useState(false);
  //new call Exporter
  const [isDialogExporterOpen, setIsDialogExporterOpen] = useState(false);


  //CREATE action
  const handleCreateVeicolo: MRT_TableOptions<Veicolo>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateVeicolo(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createVeicolo(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveVeicolo: MRT_TableOptions<Veicolo>['onEditingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateVeicolo(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateVeicolo(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row: MRT_Row<Veicolo>) => {
    if (window.confirm('ATTENZIONE !!! CANCELLAZIONE DEL VEICOLO " '+row.original.targa+' "')) {
      deleteVeicolo(row.original.id ? row.original.id : 0 );
    }
  };

  //NEW MOD action
   const openEditModal = (row: MRT_Row<Ricevuta>) => {
       setIsDialogEditOpen(true);
       setCurrentRowId(row.id);
   };

  //NEW ADD action hook and dialog
  const openAddModal = () => {
        setIsDialogAddOpen(true);
  };

  const openExporterModal = () => {
        setIsDialogExporterOpen(true);
  };


  const table = useMaterialReactTable({
    columns,
    data: results,
    initialState: { density: 'compact' },

    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
    manualSorting: true, //turn off built-in client-side sorting

    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,

    //getRowId: (row) => row.id.toString(),

    getRowId: (row) => (row.id ? row.id.toString() : ''),



    muiToolbarAlertBannerProps: isLoadingVeicoliError ? {
          color: 'error',
          children: 'Error loading data',
        } : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    // muiEditRowDialogProps: {
    //    fullWidth: true,
    //    maxWidth: 'md',
    //  },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    //onSortingChange: setSorting,
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateVeicolo,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveVeicolo,

     renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
         <>
        <DialogTitle variant="h3">Crea Nuovo Veicolo</DialogTitle>
        <DialogContent
           sx={{ width: '1000px'}}
        >
          {internalEditComponents} {/* or render custom edit components here */}

        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>

       </>
    ),


   renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Edit Veicolo</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),

    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        {/*<Tooltip title="Edit">*/}
        {/*  <IconButton onClick={() => table.setEditingRow(row)}>*/}
        {/*    <EditIcon />*/}
        {/*  </IconButton>*/}
        {/*</Tooltip>*/}

       <Tooltip title="Modifica">
       <IconButton onClick={() => {openEditModal(row)}}>
           <EditIcon />
       </IconButton>
       </Tooltip>
           {isDialogEditOpen && currentRowId === row.id && <VeicoliEdit id={(Number(currentRowId))} onClose={()  => setIsDialogEditOpen(false) } />}

       <Tooltip title="Rimuovi">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),


    renderTopToolbarCustomActions: ({ table }) => (
      <>

      {/*  <Button*/}
      {/*      variant="contained"*/}
      {/*      onClick={() => {*/}
      {/*        table.setCreatingRow(true); //simplest way to open the create row modal with no default values*/}
      {/*        //or you can pass in a row object to set default values with the `createRow` helper function*/}
      {/*        // table.setCreatingRow(*/}
      {/*        //   createRow(table, {*/}
      {/*        //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios*/}
      {/*        //   }),*/}
      {/*        // );*/}
      {/*      }}*/}
      {/*   >*/}
      {/*  Create New Veicolo*/}
      {/*</Button>*/}

      <Button variant="contained" onClick={openAddModal}>Nuovo Veicolo</Button>
       {isDialogAddOpen && <VeicoliAdd onClose={()  => setIsDialogAddOpen(false)} />}


      {/*<Button*/}
      {/*    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)*/}
      {/*    onClick={handleExportCSVData}*/}
      {/*    startIcon={<FileDownloadIcon />}*/}
      {/*  >*/}
      {/*    EXPORT DATI CSV*/}
      {/*  </Button>*/}

      {/*  <Button onClick={handleExportExcel} startIcon={<FileDownloadIcon />}>*/}
      {/*   EXPORT DATI EXCEL*/}
      {/*  </Button>*/}

        {/*<Button onClick={handleExportPDF} startIcon={<FileDownloadIcon />}>*/}
        {/*    EXPORT DATI PDF*/}
        {/*</Button>*/}

       <Button onClick={openExporterModal} startIcon={<FileDownloadIcon />}>EXPORT DATI </Button>

       {isDialogExporterOpen && <VeicoliExp columnFilters={columnFilters} globalFilter={globalFilter} sorting={sorting} onClose={()  => setIsDialogExporterOpen(false)} />}

    </>
     ),



    //rowCount: meta?.totalRowCount ?? 0,
    rowCount: count ?? 0,

    state: {
      isLoading: isLoadingVeicoli,
      isSaving: isCreatingVeicolo || isUpdatingVeicolo || isDeletingVeicolo,
      showAlertBanner: isLoadingVeicoliError,
      showProgressBars: isFetchingVeicoli,

      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },
  });



return    <Box sx={{mt: '60px' // , width: '1376px'
        }}><MaterialReactTable table={table} /> </Box>;
};







//CREATE hook (post new veicolo to api)
function useCreateVeicolo() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (veicolo: Veicolo) => {
      //send api update request here
      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
      return AxiosInstance.post('veicoli/0/',veicolo);
    },
    //client side optimistic update
    //  onMutate: (newVeicoloInfo: Veicolo) => {
    //    queryClient.setQueryData(
    //      ['veicoli'],
    //      (prevVeicoli: any) =>
    //        [
    //          ...prevVeicoli,
    //          {
    //            ...newVeicoloInfo,
    //            id: (Math.random() + 1).toString(36).substring(7),
    //          },
    //        ] as Veicolo[],
    //    );
    //   },
    //  onSettled: () => queryClient.invalidateQueries({ queryKey: ['veicoli'] }), //refetch veicoli after mutation, disabled for demo
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['fetchVeicoli'] }), //refetch veicoli after mutation, disabled for demo
     });
}

//READ hook (get veicoli from api)
function useGetVeicoli(pagination: MRT_PaginationState,columnFilters:  MRT_ColumnFiltersState,globalFilter: string,sorting: MRT_SortingState) {
  return useQuery<VeicoliApiResponse>({
    queryKey: ['fetchVeicoli',
       columnFilters, //refetch when columnFilters changes
       globalFilter, //refetch when globalFilter changes
       pagination.pageIndex, //refetch when pagination.pageIndex changes
       pagination.pageSize, //refetch when pagination.pageSize changes
       sorting, //refetch when sorting changes
    ],
    queryFn: async () => {

      const myOffset = (pagination.pageIndex==0) ? "0": `${pagination.pageSize*(pagination.pageIndex)}`;

      //const fetchURL = new URL("veicoli/","/");
      //fetchURL.searchParams.set('limit',`${pagination.pageSize}`,);
      //fetchURL.searchParams.set('offset', myOffset);
      //fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      // fetchURL.searchParams.set('',columnFiltersParam(columnFilters));
      //fetchURL.searchParams.set('search', globalFilter ?? '');
      //fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));
      const myURL = '?limit='+`${pagination.pageSize}`+'&offset='+myOffset+'&search='+(globalFilter ?? '')+columnFiltersParam(columnFilters)+'&ordering='+sortingParam(sorting);
      // console.log('URLveicoli',myURL);
      const response = await AxiosInstance.get('veicoli/'+myURL);
      const json = (response.data) as VeicoliApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}

function useGetTipoVeicolo() {
  return useQuery<TipoVeicoloApiResponse>({
    queryKey: ['fetchTipoVeicoli'],
    queryFn: async () => {
      const response = await AxiosInstance.get('tipoveicolo/');
      const json = (response.data) as TipoVeicoloApiResponse;
      //console.log(response.data)
      return json;
    },
    refetchOnWindowFocus: false,
  });
}


//mp conversione  filter parameters per django rest api
function columnFiltersParam(columnFilters: MRT_ColumnFiltersState) {
  var loopData = ''
  var i;
  for (i = 0; i < columnFilters.length; i++) {
    loopData += "&"
    loopData += `${columnFilters[i].id}`+"="+`${columnFilters[i].value}`
    //if (i < columnFilters.length-1)
    //  loopData += "&"
  }
  //console.log(loopData);
  //const columnFiltersParam = `${encodeURIComponent(loopData)}`;
  return loopData
}

function sortingParam(sorting: MRT_SortingState) {
  let ordering = '';
  for (let i = 0; i < sorting.length; i++) {
    const { id, desc } = sorting[i];
    ordering += desc ? `${id},` : `-${id},`;
  }
  // Remove the trailing comma
  ordering = ordering.slice(0, -1);
  return ordering;
}


//UPDATE hook (put veicolo in api)
function useUpdateVeicolo() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (veicolo: Veicolo) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newVeicoloInfo: Veicolo) => {
      queryClient.setQueryData(
        ['veicoli'],
        (prevVeicoli: any) =>
          prevVeicoli?.map((prevVeicolo: Veicolo) =>
            prevVeicolo.id === newVeicoloInfo.id ? newVeicoloInfo : prevVeicolo,
          ),
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['veicoli'] }), //refetch veicoli after mutation, disabled for demo
  });
}

//DELETE hook (delete veicolo in api)
function useDeleteVeicolo() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      //send api update resquest here
      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      // return Promise.resolve();
      const response = await AxiosInstance.delete('veicoli/'+id+'/');
     return  response
    },
    //client side optimistic update
    // onMutate: (id: string) => {
    //   queryClient.setQueryData(
    //     ['veicoli'],
    //     (prevVeicoli: any) =>
    //       prevVeicoli?.filter((veicolo: Veicolo) => veicolo.id !== id),
    // );
    //},
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['fetchVeicoli'] }), //refetch veicoli after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

// const VeicoliWithProviders = () => (
//   //Put this with your other react-query providers near root of your app
//   <QueryClientProvider client={queryClient}>
//     <Veicoli />
//   </QueryClientProvider>
// );

// export default VeicoliWithProviders;

export default Veicoli;


const validateRequired = (value: string) => !!value.length;
const validateRequiredNum = (value: number) => !!value.toString().length;
const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

function validateVeicolo(veicolo: Veicolo) {
  return {
  //  camep: !validateRequiredNum(veicolo.camep)  ? 'Camep is Required' : '',
  //   tipo: !validateRequired(veicolo.tipo) ? 'Tipo is Required' : '',

  };
}
