// authurl
export const SERVER = "http://127.0.0.1:8000/api/"
export const SERVERAPI = "http://127.0.0.1:8000/api/"
export const LoginUrl = 'auth/login'
export const RegisterUrl = 'auth/register'
export const ForgotPasswordUrl = 'auth/forgot-password'

export const AuthUrl = 'auth/google'
// org
export const VeicoliUrl = 'veicoli'
export const VeicoloUrl = 'veicolo'
// USERS
export const UsersUrl = 'users'
export const UserUrl = 'user'
// PROFILE
export const ProfileUrl = 'profile'
// 