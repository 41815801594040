import {EmailForm} from "../ricevute/types";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import AxiosInstance from "../../components/Axios";
import AxiosInstanceUpload from "../../components/AxiosUpload";
import * as Yup from "yup";
import {Buffer} from "buffer";
import {format} from "date-fns";
import {useState} from "react";


export function useSendEmail() {
  return useMutation({
    mutationFn: async (emailForm: EmailForm) => {
      return AxiosInstance.post('sendemail/', emailForm);
    },
  });
}

export function useUploadReport() {
  return useMutation({
    mutationFn: async (formData: FormData) => {
      return AxiosInstanceUpload.post('uploadreport/', formData);
    },
  });
}

export const validationSchema = Yup.object().shape({
                      fromDate: Yup.date().required('Selezione Data Inizio'),
                      toDate: Yup.date().test('is-after-or-equal', 'La data di fine deve essere successiva alla data di inizio', function (value) {
                                         const { fromDate } = this.parent; // Access fromDate from parent object
                                         if (!fromDate || !value) {
                                         return true; // Skip validation if fromDate or toDate is not set
                                      }
                                      return fromDate <= value; // Validate if toDate is after or equal to fromDate
                                    }),
                      emailTo: Yup.string().email('Email non è valida'),
                    });


export async function jasperIncassiBlob(fromDate: Date, toDate: Date,formattedTipopagamento: String, formato: String) {
  try {
    const username = 'jasperadmin';
    const password = 'Tibco2024@';
    const base64encodedData = Buffer.from(`${username}:${password}`).toString('base64');
    const fromDateString = fromDate ? format(fromDate, 'yyyy-MM-dd') : '';
    const toDateString = toDate ? format(toDate, 'yyyy-MM-dd') : '';
    // const formattedTipopagamento = ''; // You need to define formattedTipopagamento

         let pag = '';
             if (formato === 'xlsx') {
                pag = 'ignorePagination=true&'
            }


    const response = await fetch(`${BASE_URL_JASPERSERVER}/rest_v2/reports/reports/repincassi${formato}.${formato}?${pag}tipopag=${formattedTipopagamento}&datainizio=${fromDateString}&datafine=${toDateString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/'+formato,
        'Authorization': `Basic ${base64encodedData}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch the report');
    }

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error generating report:', error);
    throw error; // Rethrow the error to handle it in the calling code if needed
  }
}


export async function jasperNuoviRinnoviSociBlob(fromDate: Date, toDate: Date, formato: String) {
  try {
    const username = 'jasperadmin';
    const password = 'Tibco2024@';
    const base64encodedData = Buffer.from(`${username}:${password}`).toString('base64');
    const fromDateString = fromDate ? format(fromDate, 'yyyy-MM-dd') : '';
    const toDateString = toDate ? format(toDate, 'yyyy-MM-dd') : '';
    // const formattedTipopagamento = ''; // You need to define formattedTipopagamento

           let pag = '';
             if (formato === 'xlsx') {
                pag = 'ignorePagination=true&'
            }



    const response = await fetch(`${BASE_URL_JASPERSERVER}/rest_v2/reports/reports/repnuovirinnovisoci${formato}.${formato}?${pag}datainizio=${fromDateString}&datafine=${toDateString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/'+formato,
        'Authorization': `Basic ${base64encodedData}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch the report');
    }

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error generating report:', error);
    throw error; // Rethrow the error to handle it in the calling code if needed
  }
}


export async function jasperNuoviSociBlob (fromDate: Date, toDate: Date, formato: String)  {
  try {
   const username = 'jasperadmin';
      const password = 'Tibco2024@';
      const base64encodedData = Buffer.from(`${username}:${password}`).toString('base64');
      const fromDateString = fromDate ? format(fromDate, 'yyyy-MM-dd') : '';
      const toDateString = toDate ? format(toDate, 'yyyy-MM-dd') : '';

             let pag = '';
             if (formato === 'xlsx') {
                pag = 'ignorePagination=true&'
            }


      const response = await fetch(`${BASE_URL_JASPERSERVER}/rest_v2/reports/reports/repnuovisoci${formato}.${formato}?${pag}datainizio=${fromDateString}&datafine=${toDateString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/'+formato,
          'Authorization': `Basic ${base64encodedData}`,
        },
      });


    const blob = await response.blob();
    return blob;

  } catch (error) {
    console.error('Error generating report:', error);
  }
};