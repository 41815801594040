import {Checkbox, Button, FormControlLabel, Typography, Dialog, DialogTitle, DialogContent, Grid} from "@mui/material";
import * as XLSX from "xlsx";
import React, {useState} from "react";
import {MRT_ColumnFiltersState, MRT_SortingState} from "material-react-table";
import {useGetRicevuteDTOExp} from "./Services";
import {download, generateCsv, mkConfig} from "export-to-csv";
import {RicevutaDTO, RicevuteDTOApiResponse} from "./types";



interface RicevuteExpProps {
  columnFilters: MRT_ColumnFiltersState;
  globalFilter: string;
  sorting: MRT_SortingState;
  onClose: () => void;
}



const RicevuteExp: React.FC<RicevuteExpProps> = ({ columnFilters, globalFilter, sorting,onClose }) => {

  const ricevutaDTOFields = [
                "progressivo",
                "cognome",
                "nome",
                "camep",
                "anno",
                "rinnovo",
                "onorario",
                "tipopagamento",
                "cc",
                "dataricevuta",
                "iscrizioneasi",
                "iscrizioneclub",
                "contributoauto",
                "contributomoto",
                "contributoomol",
                "contributodup",
                "raccomandata",
                "nuovo",
                "tipoiscrizione",
                "tipocontributoauto",
                "tipocontributomoto",
                "tipocontributoomol",
                "tipocontributodup",
                "spesesegreteria",
                "datainvioemail",
                "id"
  ];

const {  data: { results = [], count } = {}  } = useGetRicevuteDTOExp(columnFilters,globalFilter,sorting);


const [selectedFields, setSelectedFields] = useState<string[]>(ricevutaDTOFields);

 const generateExcelData = () => {
  const data = results.map((ricevutadto: RicevutaDTO) => {
    const rowData: any = {};
    selectedFields.forEach((field) => {
      rowData[field] = ricevutadto[field as keyof RicevutaDTO] || ''; // Assert the field as keyof RicevutaDTO
    });
    return rowData;
  });
  return data;
};

 const handleFieldToggle = (field: string) => {
    if (selectedFields.includes(field)) {
      setSelectedFields(selectedFields.filter((item) => item !== field));
    } else {
      setSelectedFields([...selectedFields, field]);
    }
  };
const handleExportExcel = () => {
    const excelData = generateExcelData();
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "RicevuteExcel");
    XLSX.writeFile(workbook, "ricevute.xlsx");
  };

 const csvConfig = mkConfig({
     filename: 'ricevute',
      fieldSeparator: ';',
      decimalSeparator: ',',
      useKeysAsHeaders: true,
      showColumnHeaders: true
    });

  const handleExportCSV = () => {
   const selectedData = results.map((ricevutadto: RicevutaDTO) => {
    const rowData: any = {};
    selectedFields.forEach((field) => {
      rowData[field] = ricevutadto[field as keyof RicevutaDTO] || '';
    });
    return rowData;
   });

  const csv = generateCsv(csvConfig)(selectedData);
  download(csvConfig)(csv);
};

 const handleSelectAll = () => {
    setSelectedFields(ricevutaDTOFields);
  };

 const handleDeSelectAll = () => {
    setSelectedFields([]);
  };



  const handleClose = () => {
            onClose();
          };

  return (
            <Dialog onClose={onClose} open fullWidth maxWidth="lg">
              <DialogContent>
                <h4>Seleziona Campi da esportare:</h4>
                <form>
                  <Grid container direction="row" spacing={1}>
                    {ricevutaDTOFields.map((field) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={field}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedFields.includes(field)}
                              onChange={() => handleFieldToggle(field)}
                            />
                          }
                          label={field}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </form>
              </DialogContent>
              <DialogContent>

  <Grid container direction="row" spacing={1}>
  <Grid item xs={12} sm={4} md={2.4}>
    <Button
      fullWidth
      color="primary"
      variant="outlined"
      onClick={handleSelectAll}
    >
      SELEZIONA TUTTI
    </Button>
  </Grid>
  <Grid item xs={12} sm={4} md={2.4}>
    <Button
      fullWidth
      color="primary"
      variant="outlined"
      onClick={handleDeSelectAll}
    >
      DESELEZ. TUTTI
    </Button>
  </Grid>
  <Grid item xs={12} sm={4} md={2.4}>
    <Button
      fullWidth
      color="primary"
      type="submit"
      variant="contained"
      onClick={handleExportCSV}
    >
      EXPORT TO CSV
    </Button>
  </Grid>
  <Grid item xs={12} sm={4} md={2.4}>
    <Button
      fullWidth
      color="primary"
      type="submit"
      variant="contained"
      onClick={handleExportExcel}
    >
      EXPORT TO EXCEL
    </Button>
   </Grid>
   <Grid item xs={12} sm={4} md={2.4}>
    <Button fullWidth variant="contained" onClick={handleClose} color="error">
      CHIUDI
    </Button>
  </Grid>

</Grid>
  </DialogContent>
</Dialog>

  );
};

export default RicevuteExp;

